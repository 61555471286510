<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="7.57617" cy="8.0293" r="7.57617" fill="#0BB34B" />
    <path
      d="M10.9848 6.14606L6.64313 10.4877C6.60532 10.5257 6.56039 10.5558 6.51091 10.5763C6.46144 10.5969 6.40839 10.6074 6.35482 10.6074C6.30125 10.6074 6.24821 10.5969 6.19873 10.5763C6.14926 10.5558 6.10432 10.5257 6.06651 10.4877L4.16703 8.58824C4.12917 8.55038 4.09914 8.50543 4.07864 8.45596C4.05815 8.40649 4.04761 8.35347 4.04761 8.29993C4.04761 8.24638 4.05815 8.19336 4.07864 8.14389C4.09914 8.09442 4.12917 8.04947 4.16703 8.01161C4.20489 7.97375 4.24984 7.94372 4.29931 7.92323C4.34878 7.90274 4.4018 7.89219 4.45534 7.89219C4.50889 7.89219 4.56191 7.90274 4.61138 7.92323C4.66085 7.94372 4.7058 7.97375 4.74366 8.01161L6.35516 9.62311L10.4088 5.57011C10.4853 5.49364 10.589 5.45068 10.6972 5.45068C10.8053 5.45068 10.909 5.49364 10.9855 5.57011C11.0619 5.64657 11.1049 5.75028 11.1049 5.85842C11.1049 5.96656 11.0619 6.07027 10.9855 6.14673L10.9848 6.14606Z"
      fill="white"
    />
  </svg>
</template>
