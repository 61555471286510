<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="36"
    viewBox="0 0 35 36"
    fill="none"
  >
    <path
      d="M24.0134 14.4531L15.6025 22.864C15.5292 22.9376 15.4421 22.9959 15.3463 23.0357C15.2505 23.0755 15.1477 23.096 15.0439 23.096C14.9401 23.096 14.8374 23.0755 14.7415 23.0357C14.6457 22.9959 14.5586 22.9376 14.4854 22.864L10.8056 19.1842C10.7322 19.1109 10.674 19.0238 10.6343 18.928C10.5946 18.8322 10.5742 18.7294 10.5742 18.6257C10.5742 18.522 10.5946 18.4193 10.6343 18.3234C10.674 18.2276 10.7322 18.1405 10.8056 18.0672C10.8789 17.9938 10.966 17.9356 11.0618 17.8959C11.1577 17.8562 11.2604 17.8358 11.3641 17.8358C11.4678 17.8358 11.5706 17.8562 11.6664 17.8959C11.7622 17.9356 11.8493 17.9938 11.9227 18.0672L15.0446 21.1891L22.8977 13.3373C23.0458 13.1892 23.2467 13.106 23.4562 13.106C23.6657 13.106 23.8666 13.1892 24.0147 13.3373C24.1629 13.4854 24.2461 13.6864 24.2461 13.8959C24.2461 14.1053 24.1629 14.3063 24.0147 14.4544L24.0134 14.4531Z"
      fill="white"
    />
  </svg>
</template>
