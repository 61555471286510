<template>
  <div
    class="w-full text-sm md:text-base flex flex-col md:flex-row justify-center md:justify-between items-center min-h-[50px] my-5 gap-y-3"
  >
    <p class="text-text-primary">
      Showing
      {{ showingFirst }}-{{ showingLast }}
      of
      <span class="text-primary font-bold">{{ totalItems }} results</span>
    </p>
    <ul class="flex h-full items-center justify-center gap-x-6">
      <li class="w-fit flex justify-center items-center h-fit p-0 m-0">
        <button
          type="button"
          :disabled="isFirstPage"
          class="disabled:cursor-not-allowed"
          aria-label="Previous"
          @click="prev"
        >
          <circle-arrow-left-icon />
        </button>
      </li>
      <li
        v-for="item in pageRange"
        :key="item"
        class="w-fit h-fit flex justify-center items-center p-0 m-0"
      >
        <button
          type="button"
          :disabled="currentPage === item"
          :class="`w-fit h-fit ${
            currentPage === item
              ? 'text-primary font-bold'
              : 'text-text-primary'
          }`"
          @click="currentPage = item"
        >
          {{ item }}
        </button>
      </li>
      <li class="w-fit h-fit flex justify-center items-center p-0 m-0">
        <button
          type="button"
          :disabled="isLastPage"
          class="disabled:cursor-not-allowed"
          aria-label="Next"
          @click="next"
        >
          <circle-arrow-right-icon />
        </button>
      </li>
    </ul>
    <div class="flex items-center gap-x-2">
      <div class="text-text-primary">Items per page</div>
      <select
        class="h-10 w-[70px] px-2 py-2 border border-[#E2E2E2] rounded-[5px] focus:outline-primary appearance-none bg-select-chevron bg-chevron-position bg-no-repeat bg-auto placeholder:text-s"
        @change="handlePerPageChange"
      >
        <option :selected="perPage === 10" :value="10">10</option>

        <option :selected="perPage === 20" :value="20">20</option>
      </select>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { PaginateProps, PaginateChangeParams } from "./table.props";
import { useOffsetPagination } from "@vueuse/core";

interface Props extends PaginateProps {}

interface ChangeParams extends PaginateChangeParams {}

const emit = defineEmits<{
  (e: "change", params: ChangeParams): void;
}>();
const props = defineProps<Props>();

const {
  currentPage,
  currentPageSize,
  pageCount,
  isFirstPage,
  isLastPage,
  prev,
  next,
} = useOffsetPagination({
  total: props.totalItems,
  page: props.currentPage,
  pageSize: props.perPage,
  onPageChange: (params) =>
    emit("change", {
      perPage: params.currentPageSize,
      currentPage: params.currentPage,
    }),
  onPageSizeChange: (params) =>
    emit("change", {
      perPage: params.currentPageSize,
      currentPage: params.currentPage,
    }),
});

const showingFirst = computed(() => {
  return isFirstPage.value
    ? 1
    : (currentPage.value - 1) * currentPageSize.value + 1;
});

const showingLast = computed(() => {
  return isLastPage.value
    ? props.totalItems
    : currentPage.value * currentPageSize.value;
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function handlePerPageChange(e: any) {
  currentPageSize.value = e.target.value;
}

const maxVisiblePages = 5;

const pageRange = computed(() => {
  const list: number[] = [];

  if (pageCount.value <= maxVisiblePages) {
    for (let i = 1; i <= pageCount.value; i++) list.push(i);
  } else {
    list.push(1);
    const minValue = Math.min(currentPage.value, maxVisiblePages);
    const maxValue = minValue + (maxVisiblePages - 2);

    for (let i = minValue; i <= maxValue; i++) {
      if (i < pageCount.value && i !== 1) {
        list.push(i);
      }
    }
    list.push(pageCount.value);
  }
  return list;
});
</script>
