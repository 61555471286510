<template>
  <svg
    width="40px"
    height="40px"
    viewBox="0 0 1.2 1.2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.2 0.35A0.05 0.05 0 0 1 0.25 0.3h0.7a0.05 0.05 0 1 1 0 0.1H0.25A0.05 0.05 0 0 1 0.2 0.35m0 0.25A0.05 0.05 0 0 1 0.25 0.55h0.7a0.05 0.05 0 1 1 0 0.1H0.25A0.05 0.05 0 0 1 0.2 0.6m0 0.25A0.05 0.05 0 0 1 0.25 0.8h0.7a0.05 0.05 0 1 1 0 0.1H0.25a0.05 0.05 0 0 1 -0.05 -0.05"
      fill="#2f2f2f"
    />
  </svg>
</template>
