<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9"
    height="12"
    viewBox="0 0 9 12"
    fill="none"
  >
    <path
      d="M3.95771 0.852082C4.25749 0.552297 4.74435 0.552297 5.04413 0.852082L8.11393 3.92188C8.33458 4.14253 8.39933 4.47109 8.27941 4.75889C8.1595 5.04668 7.8813 5.23375 7.56952 5.23375H1.42992C1.12054 5.23375 0.839941 5.04668 0.720027 4.75889C0.600113 4.47109 0.667264 4.14253 0.885508 3.92188L3.95531 0.852082H3.95771ZM3.95771 11.1479L0.887907 8.07811C0.667264 7.85747 0.602511 7.5289 0.722425 7.24111C0.842339 6.95331 1.12054 6.76625 1.43232 6.76625H7.56952C7.8789 6.76625 8.1595 6.95331 8.27941 7.24111C8.39933 7.5289 8.33218 7.85747 8.11393 8.07811L5.04413 11.1479C4.74435 11.4477 4.25749 11.4477 3.95771 11.1479Z"
      fill="#9291A5"
    />
  </svg>
</template>
