<template>
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.2617 20.0093C15.7846 20.0093 20.2617 15.5321 20.2617 10.0093C20.2617 4.48643 15.7846 0.00927734 10.2617 0.00927734C4.73887 0.00927734 0.261719 4.48643 0.261719 10.0093C0.261719 15.5321 4.73887 20.0093 10.2617 20.0093Z"
      fill="#2F2F2F"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.463 10.8739V10.8726C11.3942 10.8776 11.0398 10.8989 10.2492 10.8989C9.61797 10.8989 9.17359 10.8801 9.01734 10.8726V10.8745C6.58734 10.7676 4.77359 10.3445 4.77359 9.83824C4.77359 9.33261 6.58734 8.90949 9.01734 8.80074V10.4532C9.17609 10.4645 9.63109 10.4914 10.2598 10.4914C11.0142 10.4914 11.3923 10.4601 11.463 10.4539V8.80199C13.888 8.91011 15.6973 9.33324 15.6973 9.83824C15.6973 10.3445 13.888 10.7664 11.463 10.8739ZM11.463 8.63011V7.15136H14.8467V4.89636H5.63359V7.15136H9.01734V8.62949C6.26734 8.75574 4.19922 9.30074 4.19922 9.95324C4.19922 10.6057 6.26734 11.1501 9.01734 11.277V16.0157H11.463V11.2757C14.2086 11.1495 16.2717 10.6051 16.2717 9.95324C16.2717 9.30136 14.2086 8.75699 11.463 8.63011Z"
      fill="white"
    />
  </svg>
</template>
