<template>
  <div
    class="hidden md:block w-full max-w-[230px] lg:max-w-[300px] h-fit py-4 bg-primary/90 rounded-[10px]"
  >
    <div class="w-full px-8 py-3 border-b border-white/60 border-dashed">
      <div class="text-white/80 mb-1 text-sm">You send</div>
      <div class="text-white text-lg">
        {{ quote?.sending_amount.currency }}
        {{
          formatAmountToMajor(
            quote?.sending_amount.amount || 0,
            quote?.sending_amount.currency || "",
          )
        }}
      </div>
    </div>
    <div class="w-full px-8 py-3 border-b border-white/60 border-dashed">
      <div class="text-white/80 mb-1 text-sm">Transaction fees</div>
      <div class="text-white text-lg">
        {{ quote?.total_fees.currency }}
        {{
          formatAmountToMajor(
            quote?.total_fees.amount || 0,
            quote?.total_fees.currency || "",
          )
        }}
      </div>
    </div>

    <div class="w-full px-8 py-3 border-b border-white/60 border-dashed">
      <div class="text-white/80 mb-1 text-sm">You will be debited</div>
      <div class="text-white text-lg">
        {{ quote?.billing_amount.currency }}
        {{
          formatAmountToMajor(
            quote?.billing_amount.amount || 0,
            quote?.billing_amount.currency || "",
          )
        }}
      </div>
    </div>
    <div
      v-if="quote?.exchange_rate"
      class="w-full px-8 py-3 border-b border-white/60 border-dashed"
    >
      <div v-if="isExchangeRateMajor">
        <div class="text-white/80 mb-1 text-sm">Exchange Rate</div>
        <div class="text-white text-lg">
          {{ quote.exchange_rate }}
          {{ quote?.billing_amount.currency }} ≈ 1
          {{ quote?.destination_amount.currency }}
        </div>
      </div>
      <div v-else>
        <div class="text-white/80 mb-1 text-sm">Exchange Rate</div>
        <div class="text-white text-lg">
          {{ (1 / Number(quote.exchange_rate)).toFixed(0) }}
          {{ quote?.billing_amount.currency }} ≈ 1
          {{ quote?.destination_amount.currency }}
        </div>
      </div>
    </div>
    <div class="w-full px-8 py-3 border-white/60 border-dashed">
      <div class="text-white/80 mb-1 text-sm">Beneficiary receives</div>
      <div class="text-secondary text-xl font-bold">
        {{ quote?.destination_amount.currency }}
        {{
          formatAmountToMajor(
            quote?.destination_amount.amount || 0,
            quote?.destination_amount.currency || "",
          )
        }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { formatAmountToMajor } from "@/helpers";
import { BankingQuoteResponse } from "@/types";
import { computed } from "vue";

const props = defineProps<{
  quote?: BankingQuoteResponse;
  accountBalance?: number;
  accountCurrency?: string;
}>();

const isExchangeRateMajor = computed(() => {
  if (props.quote && props.quote.exchange_rate) {
    return Number(props.quote.exchange_rate) * 100 >= 1;
  }

  return false;
});
</script>
