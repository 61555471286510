<template>
  <div class="relative w-full h-full bg-white overflow-y-auto">
    <div class="w-full px-5 pb-4 pt-8">
      <h2 class="text-lg mb-5 font-bold">User Details</h2>

      <button
        class="border w-10 h-10 rounded-full flex items-center justify-center absolute right-5 top-5"
        @click="closeModal"
      >
        <close-icon />
      </button>
    </div>

    <div class="w-full px-5 py-5 bg-greyscale-2">
      <p
        v-if="user.user"
        class="text-base mb-1 text-primary font-bold capitalize"
      >
        {{ user.user.first_name }} {{ user.user.last_name }}
      </p>
      <p class="text-sm text-primary/60 flex gap-x-2">
        {{ user.email }}
      </p>
    </div>

    <div>
      <update-user-detail-role
        v-if="showUpdateUserRole"
        :business-user-id="user.id"
        :roles="data || []"
        :refetch="refetch"
        :close-modal="closeModal"
      />

      <div v-else-if="roles.length" class="px-5 py-5 relative">
        <h3 class="text-sm font-bold mb-3">Role</h3>

        <div class="w-full flex flex-col gap-y-4">
          <single-role v-for="it in roles" :key="it.id" :role="it" />
        </div>
        <button
          v-if="canChangeUserRole"
          class="bg-tertiary/5 text-tertiary font-medium text-xs px-3 py-1 rounded-[5px] absolute right-5 top-5"
          @click="toggleUpdateUserRole"
        >
          Change role
        </button>
      </div>

      <div class="w-full px-5 py-8">
        <div class="w-full flex flex-col gap-y-3 mt-5">
          <app-button
            v-if="
              user.state === 'INVITATION_PENDING' &&
              hasPermission(LyncPermissions.users_write)
            "
            size="lg"
            variant="danger"
            :loading="canceling"
            :disabled="canceling"
            @click="cancelInvite"
            >Cancel invitation</app-button
          >
          <app-button
            v-if="
              user.state === 'INVITATION_PENDING' &&
              hasPermission(LyncPermissions.users_write)
            "
            size="lg"
            variant="primary"
            :loading="resending"
            :disabled="resending"
            @click="resendInvite"
            >Resend email</app-button
          >
          <app-button
            v-if="
              user.state === 'INVITATION_CANCELLED' &&
              hasPermission(LyncPermissions.users_write)
            "
            size="lg"
            variant="primary"
            :loading="reinviting"
            :disabled="reinviting"
            @click="reinviteUser"
            >Reinvite user</app-button
          >
          <app-button
            v-if="
              user.state === 'ACTIVE' &&
              hasPermission(LyncPermissions.users_write) &&
              !isOwner
            "
            size="lg"
            variant="danger"
            @click="handleToggleUserStatus('deactivate')"
            >Deactivate User</app-button
          >
          <app-button
            v-if="
              user.state === 'INACTIVE' &&
              hasPermission(LyncPermissions.users_write)
            "
            size="lg"
            variant="primary"
            @click="handleToggleUserStatus('activate')"
            >Activate User</app-button
          >
        </div>
      </div>
    </div>
  </div>
  <app-modal
    :is-open="isConfirmModalOpen"
    :handle-close="toggleConfirmModal"
    size="md"
  >
    <div class="relative w-full bg-white px-5 py-12 rounded-[10px]">
      <p class="w-full mb-8">
        Are you sure you want to {{ action }} this user?
      </p>

      <div class="flex justify-between items-center gap-x-5">
        <app-button size="lg" variant="outlined" @click="toggleConfirmModal">
          Cancel
        </app-button>
        <app-button
          v-if="action === 'deactivate'"
          size="lg"
          variant="danger"
          :loading="submitting"
          :disabled="submitting"
          @click="execute({ resource: `/${user.id}/deactivate` })"
        >
          Deactivate
        </app-button>

        <app-button
          v-else-if="action === 'activate'"
          size="lg"
          variant="primary"
          :loading="submitting"
          :disabled="submitting"
          @click="execute({ resource: `/${user.id}/activate` })"
        >
          Activate
        </app-button>
      </div>
    </div>
  </app-modal>
</template>

<script lang="ts" setup>
import { BusinessUsersResponse } from "@/types";
import { useWriteResource } from "@/composables/use-resource";
import { useGetRoles } from "@/data-access/users";
import { errorMessage } from "@/helpers/error";
import { useAppToast } from "@/composables";
import { ref, computed } from "vue";
import { LyncPermissions } from "@/security/permissions";
import { usePermission } from "@/composables/use-permission";

const props = defineProps<{
  closeModal: () => void;
  user: BusinessUsersResponse;
  refetch: () => void;
}>();

type TAction = "deactivate" | "activate";

const { data } = useGetRoles();

const isConfirmModalOpen = ref(false);
const showUpdateUserRole = ref(false);
const { hasPermission } = usePermission();

const action = ref<TAction>();

const isOwner = computed(() => {
  return props.user.roles.some((role) => role.id === "OWNER");
});

const canChangeUserRole = computed(() => {
  return (
    ["INVITATION_PENDING", "ACTIVE"].includes(props.user.state) &&
    hasPermission(LyncPermissions.users_write) &&
    !isOwner.value
  );
});

const toggleConfirmModal = () => {
  isConfirmModalOpen.value = !isConfirmModalOpen.value;
};

const toggleUpdateUserRole = () => {
  showUpdateUserRole.value = !showUpdateUserRole.value;
};

const roles = computed(() => {
  const businessRoles = data?.value || [];

  const userRoles = businessRoles.filter((role) => {
    return props.user.roles.find((userRole) => userRole.id === role.id);
  });

  return userRoles;
});

const toast = useAppToast();

const handleToggleUserStatus = (actionType: TAction) => {
  action.value = actionType;
  toggleConfirmModal();
};

const { submitting: resending, execute: resendInvite } = useWriteResource(
  `/users/invites/${props.user.id}/resend-email`,
  "post",
  {
    successTitle: "Invite email resent",
    onError: (err) => {
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  },
);

const { submitting: reinviting, execute: reinviteUser } = useWriteResource(
  `/users/invites/${props.user.id}/re-invite`,
  "post",
  {
    successTitle: "Invite email sent",
    onSuccess: () => {
      props.refetch();
      props.closeModal();
    },
    onError: (err) => {
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  },
);

const { submitting: canceling, execute: cancelInvite } = useWriteResource(
  `/users/invites/${props.user.id}/cancel`,
  "patch",
  {
    successTitle: "Invite has been canceled",
    onSuccess: () => {
      props.refetch();
      props.closeModal();
    },
    onError: (err) => {
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  },
);

const { submitting, execute } = useWriteResource(`/users`, "patch", {
  successTitle: `Action successful`,
  onSuccess: () => {
    props.refetch();
    toggleConfirmModal();
    props.closeModal();
  },
  onError: (err) => {
    toast.error(errorMessage(err), {
      position: "top-right",
    });
  },
});
</script>
