<template>
  <div class="md:hidden w-full h-fit py-2 bg-primary/90 rounded-[10px]">
    <div class="w-full px-3 py-1 border-b border-white/60 border-dashed">
      <div class="text-white/80 mb-1 text-sm">You send</div>
      <div class="text-white text-sm">
        {{ quote?.sending_amount.currency }}
        {{
          formatAmountToMajor(
            quote?.sending_amount.amount || 0,
            quote?.sending_amount.currency || "",
          )
        }}
      </div>
    </div>
    <div class="w-full px-3 py-1 border-b border-white/60 border-dashed">
      <div class="text-white/80 mb-1 text-sm">Transaction Fees</div>
      <div class="text-white text-sm">
        {{ quote?.total_fees.currency }}
        {{
          formatAmountToMajor(
            quote?.total_fees.amount || 0,
            quote?.total_fees.currency || "",
          )
        }}
      </div>
    </div>

    <div class="w-full px-3 py-1 border-b border-white/60 border-dashed">
      <div class="text-white/80 mb-1 text-sm">You are billed</div>
      <div class="text-white text-sm">
        {{ quote?.billing_amount.currency }}
        {{
          formatAmountToMajor(
            quote?.billing_amount.amount || 0,
            quote?.billing_amount.currency || "",
          )
        }}
      </div>
    </div>
    <div
      v-if="quote?.exchange_rate"
      class="w-full px-3 py-1 border-b border-white/60 border-dashed"
    >
      <div v-if="isExchangeRateMajor">
        <div class="text-white/80 mb-1 text-sm">Exchange Rate</div>
        <div class="text-white text-sm">
          {{ quote.exchange_rate }}
          {{ quote?.billing_amount.currency }} ≈ 1
          {{ quote?.destination_amount.currency }}
        </div>
      </div>
      <div v-else>
        <div class="text-white/80 mb-1 text-sm">Exchange Rate</div>
        <div class="text-white text-sm">
          {{ (1 / Number(quote.exchange_rate)).toFixed(0) }}
          {{ quote?.billing_amount.currency }} ≈ 1
          {{ quote?.destination_amount.currency }}
        </div>
      </div>
    </div>
    <div class="w-full px-3 py-1 border-white/60 border-dashed">
      <div class="text-white/80 mb-1 text-sm">Beneficiary receives</div>
      <div class="text-secondary text-xl font-bold">
        {{ quote?.destination_amount.currency }}
        {{
          formatAmountToMajor(
            quote?.destination_amount.amount || 0,
            quote?.destination_amount.currency || "",
          )
        }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { formatAmountToMajor } from "@/helpers";
import { BankingQuoteResponse } from "@/types";
import { computed } from "vue";

const props = defineProps<{
  quote?: BankingQuoteResponse;
  accountBalance?: number;
  accountCurrency?: string;
}>();

const isExchangeRateMajor = computed(() => {
  if (props.quote && props.quote.exchange_rate) {
    return Number(props.quote.exchange_rate) * 100 >= 1;
  }

  return false;
});
</script>
