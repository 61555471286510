<template>
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.0406 7.36103C10.3828 7.80056 9.60948 8.03516 8.81836 8.03516C7.75782 8.0341 6.74102 7.61233 5.9911 6.86241C5.24119 6.1125 4.81942 5.0957 4.81836 4.03516C4.81836 3.24403 5.05296 2.47067 5.49248 1.81288C5.93201 1.15508 6.55672 0.64239 7.28763 0.33964C8.01853 0.0368894 8.8228 -0.0423239 9.59872 0.112017C10.3746 0.266358 11.0874 0.647321 11.6468 1.20673C12.2062 1.76614 12.5872 2.47887 12.7415 3.2548C12.8958 4.03072 12.8166 4.83499 12.5139 5.56589C12.2111 6.29679 11.6984 6.92151 11.0406 7.36103Z"
    />
    <path
      d="M13.3612 15.5208H4.25915C3.61107 15.5208 3.13139 14.9105 3.344 14.2983C5.2198 8.897 9.32145 9.72881 9.48999 9.73966C12.7293 9.94818 13.8038 12.4113 14.3301 14.2738C14.5077 14.9021 14.014 15.5208 13.3612 15.5208Z"
      stroke="white"
    />
  </svg>
</template>
