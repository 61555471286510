<template>
  <app-layout title="Accounts" description="Manage your accounts">
    <section v-if="business" class="w-full px-4 md:px-8 lg:pl-14 lg:pr-8">
      <template v-if="canAccessHelios">
        <skeleton-loader v-if="isLoading" :count="2" />
        <request-account-banner
          v-else-if="hasPermission(LyncPermissions.accounts_write)"
          :handle-account-request="toggleCreateAccount"
          :requests="data?.items || []"
        />
      </template>
      <!-- <account-balance v-if="canAccessCenturion" /> -->
      <accounts-list v-if="canAccessHelios || canAccessCenturion" />

      <empty-state v-else feature="accounts" />

      <app-modal
        :is-open="showCreateAccount"
        :handle-close="toggleCreateAccount"
        size="md"
      >
        <create-bank-account
          :close-modal="toggleCreateAccount"
          :create-account="handleAccountRequest"
          :submitting="requesting || onboarding"
          :supported-currencies="supportedCurrencies || []"
        />
      </app-modal>
    </section>
  </app-layout>
</template>

<script setup lang="ts">
import { useLoggedInBusiness } from "@/composables/states";
import { useFeature } from "@/composables/use-feature";
import { useWriteResource } from "@/composables/use-resource";
import {
  useListAccountRequests,
  useListSupportedCurrencies,
} from "@/data-access/accounts";
import { bankingUrl } from "@/helpers/apiClient";
import { errorMessage } from "@/helpers/error";
import { QueryKeys } from "@/types";
import { useAppToast } from "@/composables";
import { ref } from "vue";
import { useQueryClient } from "@tanstack/vue-query";
import { LyncPermissions } from "@/security/permissions";
import { usePermission } from "@/composables/use-permission";

const { business } = useLoggedInBusiness();

const { canAccessHelios, canAccessCenturion } = useFeature();
const { data, isLoading } = useListAccountRequests(canAccessHelios.value);

const { hasPermission } = usePermission();

const { data: supportedCurrencies } = useListSupportedCurrencies(
  canAccessHelios.value,
);

const showCreateAccount = ref(false);

const toggleCreateAccount = () => {
  showCreateAccount.value = !showCreateAccount.value;
  queryClient.invalidateQueries({
    queryKey: [QueryKeys.ACCOUNT_REQUEST],
  });
};

const queryClient = useQueryClient();

const toast = useAppToast();

const { execute: onboardHelios, submitting: onboarding } = useWriteResource(
  "businesses/features",
  "post",
  {
    onError: (err) => {
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  },
);

const { execute: requestAccount, submitting: requesting } = useWriteResource(
  bankingUrl("account-requests/corporate"),
  "post",
  {
    onError: (err) => {
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.ACCOUNTS],
      });
      showCreateAccount.value = false;
    },
  },
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleAccountRequest = async (request: Record<string, any>) => {
  if (business.value && !business.value.features.includes("HELIOS")) {
    await onboardHelios({
      body: {
        feature: "HELIOS",
      },
    });
  }

  await requestAccount({
    body: request,
  });
};
</script>
