<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.05026 3.94807L11.0718 12.2361M5.05026 3.94807L12.2051 5.08128M5.05026 3.94807L3.91705 11.1029"
      stroke="#2F2F2F"
      stroke-width="1.46351"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <rect
      x="3.54004"
      y="15.5798"
      width="9.38631"
      height="1.14479"
      rx="0.572397"
      fill="#2F2F2F"
    />
  </svg>
</template>
