<template>
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="21.9853" cy="22.291" r="21.4638" fill="white" />
    <path
      d="M32.1055 23.2449L23.9696 31.3808L23.9696 8.97921L26.1643 8.97921V26.0827L30.5538 21.6933L32.1055 23.2449ZM20.6775 30.9265H18.4828V13.823L14.0933 18.2124L12.5417 16.6608L20.6775 8.5249V30.9265Z"
      fill="#2F2F2F"
    />
  </svg>
</template>
