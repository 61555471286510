<template>
  <div class="w-full">
    <header
      class="flex flex-wrap md:flex-nowrap justify-between gap-y-3 gap-x-8 mb-8"
    >
      <input
        v-model="transactionKeywordModel"
        class="w-full h-10 border border-greyscale-7 text-sm text-primary rounded-[5px] focus:outline-primary placeholder:text-sm px-4 py-1 placeholder:text-primary-primary bg-gray-50"
        placeholder="Search transactions by description or id"
        type="text"
      />
    </header>
    <div
      class="w-full flex flex-wrap xl:flex-nowrap gap-y-5 justify-between items-start gap-x-8 mb-4"
    >
      <div
        class="w-full grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-x-5 gap-y-4"
      >
        <app-input
          label="Asset"
          type="select"
          align-row
          grey-text
          v-bind="filterFields.asset_ids"
        >
          <option value="" selected>All Assets</option>
          <option
            v-for="asset in assets?.items || []"
            :key="asset.id"
            :value="asset.id"
          >
            {{ asset.asset.currency }} -
            {{ asset.asset.blockchain }}
          </option>
        </app-input>
        <app-input
          label="Type"
          type="select"
          align-row
          grey-text
          v-bind="filterFields.types"
        >
          <option value="" selected>All Types</option>
          <option value="DEPOSIT">DEPOSIT</option>
          <option value="WITHDRAWAL">WITHDRAWAL</option>
          <option value="SWAP">SWAP</option>
        </app-input>
        <app-input
          label="Date from"
          placeholder="Select date"
          type="date"
          v-bind="filterFields.created_date_from"
          align-row
          grey-text
        />
        <app-input
          label="Date to"
          placeholder="Select date"
          v-bind="filterFields.created_date_to"
          type="date"
          align-row
          grey-text
        />
        <app-input
          label="Status"
          type="select"
          align-row
          grey-text
          v-bind="filterFields.states"
        >
          <option value="" selected>Select status</option>
          <option value="PENDING">PENDING</option>
          <option value="PROCESSING">PROCESSING</option>
          <option value="COMPLETED">COMPLETED</option>
          <option value="FAILED">FAILED</option>
          <option value="CANCELLED">CANCELLED</option>
        </app-input>
      </div>
      <button
        class="bg-[#f6f6f6] text-primary px-4 py-1 h-[35px] flex gap-x-2 items-center text-sm rounded-[5px]"
        type="button"
        @click="resetFilters"
      >
        <reset-icon />
        Reset
      </button>
    </div>
    <div class="w-full mt-8">
      <app-table
        v-if="!isError"
        :data="transactions?.data.items"
        :columns="columns"
        :loading="isLoading"
        :pagination="{
          currentPage,
          perPage,
          totalItems: transactions?.data.total_items || 0,
        }"
        @mobile-row-click="handleMobileRowClicked"
        @change="handleTableChange"
      >
        <template #column-account="props">
          <div v-if="props.row.direction === 'INBOUND'">
            <div class="flex gap-x-1 items-center">
              <asset-type :asset="props.row.destination?.asset?.currency" />
              <span v-if="props.row.destination.asset?.blockchain"
                >({{ props.row.destination.asset?.blockchain }})</span
              >
            </div>
            <div
              v-if="props.row.destination.address"
              class="mt-1 text-text-secondary ml-6 text-xs"
            >
              {{ maskAddress(props.row.destination.address) }}
            </div>
          </div>
          <div v-if="props.row.direction === 'OUTBOUND'">
            <div class="flex gap-x-1 items-center">
              <asset-type :asset="props.row.asset" />
              <span v-if="props.row.source.asset?.blockchain"
                >({{ props.row.source.asset?.blockchain }})</span
              >
            </div>
            <div
              v-if="props.row.source.address"
              class="mt-1 text-text-secondary ml-6 text-xs"
            >
              {{ maskAddress(props.row.source.address) }}
            </div>
          </div>
        </template>

        <template #column-status="props">
          <transaction-status :status="props.row.state" />
        </template>
        <template #column-amount="props">
          <div v-if="props.row.direction === 'INBOUND'" class="font-bold">
            {{
              `${props.row.destination_amount.currency} ${formatAmount(
                props.row.destination_amount.value,
              )}`
            }}
          </div>
          <div v-else class="font-bold">
            {{
              `${props.row.source_amount.currency} ${formatAmount(
                props.row.source_amount.value,
              )}`
            }}
          </div>
        </template>
        <template #column-type="props">
          <div class="flex gap-x-1">
            <debit-icon
              v-if="props.row.direction.toLowerCase() === 'outbound'"
            />
            <credit-icon
              v-else-if="props.row.direction.toLowerCase() === 'inbound'"
            />
            <span
              :class="
                props.row.direction.toLowerCase() === 'outbound'
                  ? 'text-[#B96B6B]'
                  : 'text-[#297FB0]'
              "
              >{{
                props.row.direction.toLowerCase() === "outbound"
                  ? "Debit"
                  : "Credit"
              }}</span
            >
          </div>
        </template>
        <template #column-beneficiary="props">
          <div
            v-if="
              props.row.destination.type === 'COUNTERPARTY' &&
              props.row.destination.counterparty
            "
          >
            <div class="mb-1 flex gap-x-2 items-start">
              <institution-icon
                v-if="props.row.destination.counterparty.type === 'FIAT'"
              />
              <wallet-icon
                v-if="props.row.destination.counterparty.type === 'CRYPTO'"
              />
              <div class="-mt-1">
                <div>
                  {{ props.row.destination.counterparty.name.toLowerCase() }}
                </div>
                <div
                  v-if="props.row.destination.counterparty.type === 'CRYPTO'"
                  class="break-all text-xs text-text-secondary"
                >
                  {{
                    maskAddress(
                      props.row.destination.counterparty.crypto_details.address,
                    )
                  }}
                </div>
                <div
                  v-if="props.row.destination.counterparty.type === 'FIAT'"
                  class="break-all text-xs text-text-secondary"
                >
                  {{
                    props.row.destination.counterparty.fiat_details.details
                      .accountNumber
                  }}
                </div>
              </div>
            </div>
          </div>
          <div
            v-else-if="
              props.row.destination.type === 'ASSET' &&
              props.row.destination.asset
            "
          >
            <div
              v-if="
                props.row.source.type === 'COUNTERPARTY' &&
                props.row.source.counterparty
              "
            >
              <div
                v-if="props.row.source.counterparty.type === 'CRYPTO'"
                class="flex gap-x-2 items-center"
              >
                <wallet-icon />
                <div
                  v-if="props.row.source.counterparty.crypto_details"
                  class="break-all"
                >
                  {{
                    maskAddress(
                      props.row.source.counterparty.crypto_details.address,
                    )
                  }}
                </div>
              </div>
              <div
                v-if="props.row.source.counterparty.type === 'FIAT'"
                class="flex gap-x-2 items-start"
              >
                <institution-icon />
                <div>
                  <div v-if="props.row.source.counterparty.name">
                    {{ props.row.source.counterparty.name.toLowerCase() }}
                  </div>
                  <div
                    v-if="props.row.source.counterparty.fiat_details"
                    class="break-all text-xs text-text-secondary"
                  >
                    {{
                      maskAddress(
                        props.row.source.counterparty.crypto_details.address,
                      )
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #column-action="props">
          <router-link
            class="underline underline-offset-2"
            :to="`/transactions/crypto/${props.row.id}`"
          >
            View
          </router-link>
        </template>

        <!-- mobile columns -->

        <template #column-mobile-account="props">
          <div class="flex justify-between items-start gap-x-3">
            <div class="">
              <debit-icon
                v-if="props.row.direction.toLowerCase() === 'outbound'"
              />
              <credit-icon
                v-else-if="props.row.direction.toLowerCase() === 'inbound'"
              />
            </div>

            <div>
              <div v-if="props.row.direction === 'INBOUND'">
                <div class="flex items-center gap-x-1">
                  <asset-type :asset="props.row.destination?.asset?.currency" />
                  <span v-if="props.row.destination.asset?.blockchain"
                    >({{ props.row.destination.asset?.blockchain }})</span
                  >
                </div>
                <div
                  v-if="props.row.destination.address"
                  class="mt-1 text-text-secondary ml-6 text-xs"
                >
                  {{ maskAddress(props.row.destination.address) }}
                </div>
              </div>
              <div v-if="props.row.direction === 'OUTBOUND'">
                <div class="flex items-center gap-x-1">
                  <asset-type :asset="props.row.asset" />
                  <span v-if="props.row.source.asset?.blockchain"
                    >({{ props.row.source.asset?.blockchain }})</span
                  >
                </div>
                <div
                  v-if="props.row.source.address"
                  class="mt-1 text-text-secondary ml-6 text-xs"
                >
                  {{ maskAddress(props.row.source.address) }}
                </div>
              </div>
            </div>
          </div>
        </template>

        <template #column-mobile-amount="props">
          <div v-if="props.row.direction === 'INBOUND'">
            <div class="font-bold">
              {{
                `${props.row.destination_amount.currency} ${formatAmount(
                  props.row.destination_amount.value,
                )}`
              }}
            </div>
            <div class="mt-1 text-xs text-right">
              {{ formatDate(props.row.created_date) }}
            </div>
          </div>
          <div v-else>
            <div class="font-bold">
              {{
                `${props.row.source_amount.currency} ${formatAmount(
                  props.row.source_amount.value,
                )}`
              }}
            </div>
            <div class="mt-1 text-xs text-right">
              {{ formatDate(props.row.created_date) }}
            </div>
          </div>
        </template>

        <template #empty-state>
          <div
            class="w-full max-w-[300px] mx-auto flex flex-col gap-y-3 justify-center items-center"
          >
            <empty-data />
            <h4 class="font-bold text-base lg:text-lg">No transactions</h4>
            <p class="text-base text-center text-text-primary">
              Make your first payment to a beneficiary, or swap your stableicon
              the fastest way
            </p>
          </div>
        </template>
      </app-table>
      <error-component
        v-else-if="isError"
        message="Error fetching transactions"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  TableChangeParams,
  TableColumn,
} from "@/components/shared/table/table.props";
import { ServiceType, CryptoTransactionResponse } from "@/types";
import { ref, watch, reactive } from "vue";
import { useGetTransactions } from "@/data-access/transactions";
import { formatAmount, formatDate, maskAddress } from "@/helpers";
import { useForm } from "vee-validate";
import { useGetAssets } from "@/data-access/assets";
import { useRouter } from "vue-router";
import { useAppToast } from "@/composables";
import { debounce } from "lodash";

const currentPage = ref(1);
const perPage = ref(10);
const router = useRouter();
const transactionKeywordModel = ref("");
const transactionKeyword = ref("");

interface FilterFields {
  states?: string;
  asset_ids?: string;
  types?: string;
  created_date_from?: string;
  created_date_to?: string;
}

const statesFilter = ref<string[]>([]);
const typesFilter = ref<string[]>([]);
const asset_idsFilter = ref<string[]>([]);
const dateFromFilter = ref<string>("");
const dateToFilter = ref<string>("");

const { defineInputBinds, values, setValues } = useForm<FilterFields>();

const {
  isLoading,
  data: transactions,
  isError,
} = useGetTransactions(
  {
    page: currentPage,
    limit: perPage,
    filters: {
      keyword: transactionKeyword,
      states: statesFilter,
      types: typesFilter,
      asset_ids: asset_idsFilter,
      created_date_from: dateFromFilter,
      created_date_to: dateToFilter,
    },
  },
  ServiceType.CRYPTO,
);

const { data: assets } = useGetAssets();
const toast = useAppToast();

watch(
  transactionKeywordModel,
  debounce((val) => {
    transactionKeyword.value = val;
  }, 1000),
);

const columns: TableColumn<
  CryptoTransactionResponse & {
    action: string;
  }
>[] = [
  {
    label: "Date",
    selector: (row) => formatDate(row.created_date),
    dataIndex: "date",
    showOnMobile: false,
  },
  {
    label: "Amount",
    selector: () => {},
    dataIndex: "amount",
    showOnMobile: false,
  },
  {
    label: "Account",
    selector: () => {},
    dataIndex: "account",
    showOnMobile: false,
  },
  {
    label: "Type",
    selector: (row) =>
      `${row.direction.toLowerCase() === "outbound" ? "Debit" : "Credit"}`,
    dataIndex: "type",
    showOnMobile: false,
  },
  {
    label: "Counterparty",
    selector: () => {},
    dataIndex: "beneficiary",
    showOnMobile: false,
  },

  {
    label: "Status",
    selector: (row) => row.state,
    dataIndex: "status",
    showOnMobile: false,
  },
  {
    label: "",
    selector: () => {},
    dataIndex: "action",
    showOnMobile: false,
  },
  {
    label: "",
    selector: () => {},
    dataIndex: "mobile-account",
    showOnMobile: true,
  },
  {
    label: "",
    selector: () => {},
    dataIndex: "mobile-amount",
    showOnMobile: true,
  },
];

const handleMobileRowClicked = (row: CryptoTransactionResponse) => {
  router.push(`/transactions/crypto/${row.id}`);
};

const handleTableChange = (params: TableChangeParams) => {
  currentPage.value = params.currentPage;
  perPage.value = params.perPage;
};

const resetFilters = () => {
  statesFilter.value = [];
  dateFromFilter.value = "";
  dateToFilter.value = "";
  asset_idsFilter.value = [];
  typesFilter.value = [];
  setValues({
    states: "",
    asset_ids: "",
    types: "",
    created_date_from: "",
    created_date_to: "",
  });
};

watch(
  () => values.states,
  (val) => {
    if (val) {
      statesFilter.value = [val];
    } else {
      statesFilter.value = [];
    }
  },
);

watch(
  () => values.created_date_from,
  (val) => {
    if (val) {
      dateFromFilter.value = val;
    } else {
      dateFromFilter.value = "";
    }
  },
);

watch(
  () => values.created_date_to,
  (val) => {
    if (val) {
      dateToFilter.value = val;
    } else {
      dateToFilter.value = "";
    }
  },
);

watch(
  () => values.asset_ids,
  (val) => {
    if (val) {
      asset_idsFilter.value = [val];
    } else {
      asset_idsFilter.value = [];
    }
  },
);

watch(
  () => values.types,
  (val) => {
    if (val) {
      typesFilter.value = [val];
    } else {
      typesFilter.value = [];
    }
  },
);

watch(values, (val) => {
  if (val.created_date_from && val.created_date_to) {
    if (new Date(val.created_date_from) > new Date(val.created_date_to)) {
      toast.error("Date from cannot be greater than date to", {
        position: "top-right",
      });
      return;
    }
  }
});

const filterFields = reactive({
  states: defineInputBinds("states"),
  asset_ids: defineInputBinds("asset_ids"),
  types: defineInputBinds("types"),
  created_date_from: defineInputBinds("created_date_from"),
  created_date_to: defineInputBinds("created_date_to"),
});
</script>
