<template>
  <div
    class="relative w-full h-auto md:h-[45px] flex items-center rounded-[10px] bg-primary-linear-gradient p-4 mb-5"
  >
    <div class="w-full flex items-center gap-x-2">
      <span class="">
        <caution-icon class="fill-tertiary w-[24px] h-[24px]" />
      </span>

      <p class="text-sm text-tertiary">
        You have {{ requests.length }} pending payment approval.
        <button
          class="font-bold underline underline-offset-2"
          @click="toggleShowRequests"
        >
          Review payment
        </button>
      </p>
    </div>

    <approval-requests-icon class="absolute top-0 right-0 h-full" />
  </div>
  <app-modal
    :is-open="showRequests"
    :handle-close="toggleShowRequests"
    size="xl"
    align-right
  >
    <approval-requests-modal
      :close-modal="toggleShowRequests"
      :refetch="refetch"
      :requests="requests"
    />
  </app-modal>
</template>

<script lang="ts" setup>
import { ApprovalRequestResponse } from "@/types";
import { ref } from "vue";

defineProps<{
  requests: ApprovalRequestResponse[];
  refetch: () => void;
}>();

const showRequests = ref(false);

const toggleShowRequests = () => {
  showRequests.value = !showRequests.value;
};
</script>
