<template>
  <svg
    width="6"
    height="14"
    viewBox="0 0 6 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.146528 7.3738L5.07815 13.2737C5.16044 13.3721 5.26976 13.4271 5.38345 13.4271C5.49713 13.4271 5.60646 13.3721 5.68874 13.2737L5.69405 13.267C5.73408 13.2192 5.76596 13.1618 5.78774 13.0981C5.80952 13.0344 5.82075 12.9658 5.82075 12.8964C5.82075 12.8271 5.80952 12.7585 5.78774 12.6948C5.76596 12.6311 5.73408 12.5736 5.69405 12.5259L1.05002 6.97048L5.69405 1.41728C5.73408 1.36954 5.76596 1.31206 5.78774 1.24836C5.80952 1.18466 5.82075 1.11606 5.82075 1.04674C5.82075 0.977412 5.80952 0.908813 5.78774 0.845111C5.76596 0.781409 5.73408 0.723937 5.69405 0.67619L5.68874 0.669523C5.60646 0.571032 5.49713 0.516087 5.38345 0.516087C5.26976 0.516087 5.16044 0.571032 5.07815 0.669523L0.146528 6.56938C0.103156 6.62126 0.0686275 6.68366 0.0450351 6.7528C0.0214426 6.82194 0.00927734 6.89637 0.00927734 6.97159C0.00927734 7.04681 0.0214426 7.12124 0.0450351 7.19038C0.0686275 7.25952 0.103156 7.32192 0.146528 7.3738Z"
      fill="#2F2F2F"
    />
  </svg>
</template>
