<template>
  <svg
    fill="#2f2f2f"
    width="40px"
    height="40px"
    viewBox="-0.05 0 0.95 0.95"
    xmlns="http://www.w3.org/2000/svg"
    class="cf-icon-svg"
  >
    <path
      d="M0.821 0.479A0.396 0.396 0 1 1 0.425 0.083a0.396 0.396 0 0 1 0.396 0.396M0.694 0.634a0.016 0.016 0 0 0 -0.016 -0.016H0.66V0.599a0.016 0.016 0 0 0 -0.016 -0.016H0.617V0.361h0.043a0.02 0.02 0 0 0 0.008 -0.038L0.433 0.221a0.024 0.024 0 0 0 -0.016 0L0.182 0.322a0.02 0.02 0 0 0 0.008 0.038h0.043V0.584H0.206a0.016 0.016 0 0 0 -0.016 0.016v0.019H0.172a0.016 0.016 0 0 0 -0.016 0.016v0.019h0.539zM0.273 0.398h0.075v0.186H0.273zM0.565 0.321H0.286L0.425 0.261zM0.387 0.398h0.075v0.186H0.387zm0.114 0h0.075v0.186H0.502z"
    />
  </svg>
</template>
