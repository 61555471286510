<template>
  <form
    class="w-full flex items-center flex-col gap-y-8"
    @submit.prevent="handleSubmit"
  >
    <otp-input :count="6" :error="error" @complete="handlePinComplete" />
    <div class="w-full flex flex-col gap-y-4">
      <app-button variant="primary" :loading="loading" type="submit" size="lg">
        Verify
      </app-button>
      <app-button
        :disabled="resending || !!timer"
        :loading="resending"
        type="button"
        size="lg"
        variant="outlined"
        @click="handleResendOtp"
      >
        <span
          >Resend verification code
          <span v-if="timer">{{ "in " + timer + "s" }}</span>
        </span>
      </app-button>
    </div>
  </form>
</template>
<script lang="ts" setup>
import { ref, watch } from "vue";
import { useAppToast } from "@/composables";

const props = defineProps<{
  loading: boolean;
  resending: boolean;
  handleOtpLogin: (value: string) => void;
  resendOtp: () => void;
}>();

const otp = ref("");
const error = ref(false);
const timer = ref(60);
const toast = useAppToast();

watch(
  timer,
  (value) => {
    if (value >= 1) {
      setTimeout(() => {
        timer.value--;
      }, 1000);
    }
  },
  {
    immediate: true,
  },
);

const handlePinComplete = (value: string) => {
  otp.value = value;
};

const handleResendOtp = async () => {
  await props.resendOtp();
  timer.value = 60;
};

const handleSubmit = async () => {
  if (!otp.value && otp.value.length !== 6) {
    error.value = true;
    toast.error("Incomplete code", {
      position: "top-right",
    });

    setTimeout(() => {
      error.value = false;
    }, 5000);
  }

  try {
    await props.handleOtpLogin(otp.value);
  } catch (e) {
    error.value = true;

    setTimeout(() => {
      error.value = false;
    }, 5000);
  }
};
</script>
