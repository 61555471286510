<template>
  <div
    class="w-full flex justify-between border border-greyscale-1 px-4 py-3 rounded-[10px]"
  >
    <div class="flex flex-col gap-y-1">
      <div class="text-primary font-medium">
        {{ currencyOf(request.amount.currency).symbol }}
        {{ formatAmountToMajor(request.amount.value, request.amount.currency) }}
      </div>
      <div class="text-sm text-primary/50">
        Requested by {{ request.requester.name }}
      </div>
    </div>
    <div class="flex items-center gap-x-1">
      <router-link
        v-if="request.scope === 'helios'"
        :to="`/transactions/fiat/${request.entity_id}`"
      >
        <app-button size="sm" variant="outlined">
          <eyes-icon class="w-[16px]" />
        </app-button>
      </router-link>
      <router-link
        v-else-if="request.scope === 'centurion'"
        :to="`/transactions/crypto/${request.entity_id}`"
      >
        <app-button size="sm" variant="outlined">
          <eyes-icon class="w-[16px]" />
        </app-button>
      </router-link>
      <app-button
        size="sm"
        variant="outlined"
        :loading="approving"
        :disabled="approving"
        @click="approveRequest"
      >
        <approve-icon class="w-[16px]" />
      </app-button>
      <app-button
        size="sm"
        variant="outlined"
        :loading="rejecting"
        :disabled="rejecting"
        @click="rejectRequest"
      >
        <reject-icon class="w-[16px]" />
      </app-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ApprovalRequestResponse, QueryKeys } from "@/types";
import { useWriteResource } from "@/composables/use-resource";
import { errorMessage } from "@/helpers/error";
import { useAppToast } from "@/composables";
import { formatAmountToMajor } from "@/helpers";
import { currencyOf } from "@/helpers/currencies";
import { useQueryClient } from "@tanstack/vue-query";

const props = defineProps<{
  request: ApprovalRequestResponse;
  closeModal: () => void;
}>();

const toast = useAppToast();
const queryClient = useQueryClient();

const { submitting: approving, execute: approveRequest } = useWriteResource(
  `/approval-requests/${props.request.entity_id}/approve`,
  "patch",
  {
    successTitle: `Request Approval successful`,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.APPROVAL_REQUESTS],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.BANKING_TRANSACTIONS],
      });
      props.closeModal();
    },
    onError: (err) => {
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  },
);

const { submitting: rejecting, execute: rejectRequest } = useWriteResource(
  `/approval-requests/${props.request.entity_id}/reject`,
  "patch",
  {
    successTitle: `Request Rejection successful`,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.APPROVAL_REQUESTS],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.BANKING_TRANSACTIONS],
      });
      props.closeModal();
    },
    onError: (err) => {
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  },
);
</script>
