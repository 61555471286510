<template>
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_5550_27016)">
      <path
        d="M15.7421 6.0464L10.6855 0.989066C10.0596 0.365059 9.21191 0.0146484 8.32813 0.0146484C7.44436 0.0146484 6.59664 0.365059 5.9708 0.989066L0.914133 6.0464C0.72775 6.23159 0.57998 6.45193 0.479388 6.69466C0.378796 6.93738 0.327385 7.19766 0.328133 7.4604V14.0051C0.328133 14.5355 0.538847 15.0442 0.91392 15.4193C1.28899 15.7944 1.7977 16.0051 2.32813 16.0051H14.3281C14.8586 16.0051 15.3673 15.7944 15.7423 15.4193C16.1174 15.0442 16.3281 14.5355 16.3281 14.0051V7.4604C16.3289 7.19766 16.2775 6.93738 16.1769 6.69466C16.0763 6.45193 15.9285 6.23159 15.7421 6.0464ZM10.3281 14.6717H6.32813V12.0491C6.32813 11.5186 6.53885 11.0099 6.91392 10.6349C7.28899 10.2598 7.7977 10.0491 8.32813 10.0491C8.85857 10.0491 9.36727 10.2598 9.74235 10.6349C10.1174 11.0099 10.3281 11.5186 10.3281 12.0491V14.6717ZM14.9948 14.0051C14.9948 14.1819 14.9246 14.3514 14.7995 14.4765C14.6745 14.6015 14.5049 14.6717 14.3281 14.6717H11.6615V12.0491C11.6615 11.165 11.3103 10.3172 10.6852 9.69204C10.06 9.06692 9.21219 8.71573 8.32813 8.71573C7.44408 8.71573 6.59623 9.06692 5.97111 9.69204C5.34599 10.3172 4.9948 11.165 4.9948 12.0491V14.6717H2.32813C2.15132 14.6717 1.98175 14.6015 1.85673 14.4765C1.7317 14.3514 1.66147 14.1819 1.66147 14.0051V7.4604C1.66208 7.28372 1.73226 7.11439 1.8568 6.98907L6.91347 1.93373C7.28927 1.55969 7.79791 1.34969 8.32813 1.34969C8.85836 1.34969 9.367 1.55969 9.7428 1.93373L14.7995 6.99107C14.9235 7.1159 14.9937 7.28441 14.9948 7.4604V14.0051Z"
        fill="#2F2F2F"
        fill-opacity="0.8"
      />
    </g>
    <defs>
      <clipPath id="clip0_5550_27016">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0.328125)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
