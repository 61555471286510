<template>
  <svg
    width="13"
    height="10"
    viewBox="0 0 13 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.74472 8.15272L1.25935 5.66735C1.12543 5.53343 0.943788 5.45819 0.754394 5.45819C0.565 5.45819 0.383363 5.53343 0.249442 5.66735C0.11552 5.80127 0.0402832 5.98291 0.0402832 6.1723C0.0402832 6.26608 0.0587544 6.35894 0.0946418 6.44558C0.130529 6.53222 0.18313 6.61094 0.249442 6.67725L3.24335 9.67116C3.52268 9.9505 3.97392 9.9505 4.25325 9.67116L11.8311 2.09329C11.965 1.95936 12.0403 1.77773 12.0403 1.58833C12.0403 1.39894 11.965 1.2173 11.8311 1.08338C11.6972 0.949458 11.5156 0.874222 11.3262 0.874222C11.1368 0.874222 10.9551 0.949458 10.8212 1.08338L3.74472 8.15272Z"
      fill="#2F2F2F"
    />
  </svg>
</template>
