<template>
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 0.48 0.48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.24 0.465c0.124 0 0.225 -0.101 0.225 -0.225S0.364 0.015 0.24 0.015 0.015 0.116 0.015 0.24 0.116 0.465 0.24 0.465Z"
      stroke="#2f2f2f"
      stroke-width="0.03"
      stroke-miterlimit="10"
    />
    <path
      d="M0.18 0.17h0.16"
      stroke="#2f2f2f"
      stroke-width="0.03"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M0.32 0.31H0.16"
      stroke="#2f2f2f"
      stroke-width="0.03"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="m0.3 0.12 0.04 0.05 -0.04 0.05"
      stroke="#2f2f2f"
      stroke-width="0.03"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="m0.2 0.36 -0.04 -0.05 0.04 -0.05"
      stroke="#2f2f2f"
      stroke-width="0.03"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
