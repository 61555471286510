<template>
  <svg
    width="18px"
    height="18px"
    viewBox="0 0 0.54 0.54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.045 0.284h0.383"
      stroke="#a5a5a5"
      stroke-width="0.03375"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M0.427 0.232v0.161C0.427 0.457 0.41 0.473 0.342 0.473H0.131C0.062 0.473 0.045 0.455 0.045 0.388V0.23C0.045 0.17 0.059 0.151 0.113 0.148h0.23c0.068 0 0.086 0.017 0.086 0.085"
      stroke="#a5a5a5"
      stroke-width="0.03375"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M0.495 0.152v0.158C0.495 0.37 0.481 0.389 0.427 0.392V0.232C0.427 0.164 0.41 0.148 0.342 0.148H0.113C0.113 0.083 0.131 0.068 0.198 0.068h0.212C0.478 0.068 0.495 0.085 0.495 0.152"
      stroke="#a5a5a5"
      stroke-width="0.03375"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M0.118 0.401h0.039"
      stroke="#a5a5a5"
      stroke-width="0.03375"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M0.204 0.401h0.077"
      stroke="#a5a5a5"
      stroke-width="0.03375"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
