<template>
  <div class="w-full">
    <div
      v-if="countriesError || methodsError"
      class="flex gap-x-1 items-center w-full text-sm text-red-800 bg-red-100 py-2 px-6 mt-5"
    >
      <span>
        <caution-icon class="w-6 h-6 fill-red-800" />
      </span>
      <span>Error fetching available countries and payment methods</span>
    </div>
    <add-beneficiary-info-form
      v-if="view === 'beneficiary-info'"
      :form-data="formData"
      :supported-countries="supportedCountries || []"
      :update-form-data="updateFormData"
      :close-modal="closeModal"
      :switch-form="switchForm"
      :service-type="ServiceType.CRYPTO"
    />
    <add-beneficiary-address
      v-else-if="view === 'beneficiary-address'"
      :form-data="formData"
      :update-form-data="updateFormData"
      :switch-form="switchForm"
    />
    <add-beneficiary-bank-form
      v-else
      :form-data="formData"
      :update-form-data="updateFormData"
      :supported-payment-methods="supportedPaymentMethods || []"
      :submitting="submitting"
      :handle-create="handleCreateFiatBeneficiary"
      :switch-form="switchForm"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { CreateFiatBeneficiary, FiatBeneficiaryView } from "./type";
import { useWriteResource } from "@/composables/use-resource";
import { cryptoUrl } from "@/helpers/apiClient";
import { useAppToast } from "@/composables";
import { errorMessage } from "@/helpers/error";
import { useQueryClient } from "@tanstack/vue-query";
import { QueryKeys, ServiceType } from "@/types";
import {
  useGetSupportedCountries,
  useGetSupportedPaymentMethods,
} from "@/data-access/beneficiary";

const props = defineProps<{
  closeModal: () => void;
}>();

const view = ref<FiatBeneficiaryView>("beneficiary-info");
const toast = useAppToast();
const { data: supportedCountries, error: countriesError } =
  useGetSupportedCountries(ServiceType.CRYPTO);
const { data: supportedPaymentMethods, error: methodsError } =
  useGetSupportedPaymentMethods(ServiceType.CRYPTO);

const queryClient = useQueryClient();

const formData = ref<CreateFiatBeneficiary>({
  country: "",
  beneficiary_name: "",
  currency: "",
  method: {
    type: "",
    details: {
      routingNumber: "",
      accountNumber: "",
      bankName: "",
      bankAddress: "",
      accountType: "",
    },
  },
  beneficiary_address: {
    line_1: "",
    line_2: "",
    city: "",
    state: "",
    postal_code: "",
  },
});

const switchForm = (params: FiatBeneficiaryView) => {
  view.value = params;
};

const updateFormData = (data: CreateFiatBeneficiary) => {
  formData.value = data;
};

const { submitting, execute } = useWriteResource(
  cryptoUrl("beneficiaries/corporate/fiat"),
  "post",
  {
    successTitle: "Beneficiary created",
    onError: (err) => {
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  },
);

const handleCreateFiatBeneficiary = async (data: CreateFiatBeneficiary) => {
  await execute({
    body: data,
  });
  setTimeout(() => {
    submitting.value = false;
  }, 10000);
  queryClient.invalidateQueries({
    queryKey: [QueryKeys.BANKING_BENEFICIARIES],
  });
  props.closeModal();
};
</script>
