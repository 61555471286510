<template>
  <div class="w-full h-fit py-3 bg-primary/90 rounded-[10px]">
    <div
      v-if="exchangeRate"
      class="w-full px-8 py-3 border-b border-white/60 border-dashed"
    >
      <div v-if="isExchangeRateMajor">
        <div class="text-white/80 mb-1 text-sm">Exchange Rate</div>
        <div class="text-white text-base">
          {{ exchangeRate }}
          {{ billingAmount?.currency }} ≈ 1 {{ destinationAmount?.currency }}
        </div>
      </div>
      <div v-else>
        <div class="text-white/80 mb-1 text-sm">Exchange Rate</div>
        <div class="text-white text-base">
          {{ (1 / Number(exchangeRate)).toFixed(0) }}
          {{ billingAmount?.currency }} ≈ 1 {{ destinationAmount?.currency }}
        </div>
      </div>
    </div>

    <div
      v-if="billingAmount"
      class="w-full px-8 py-3 border-b border-white/60 border-dashed"
    >
      <div class="text-white/80 mb-1 text-sm">
        Your {{ billingAmount.currency }} account will be debited
      </div>
      <div class="text-white text-base">
        {{ billingAmount.currency }}
        {{
          formatAmountToMajor(
            billingAmount.amount || 0,
            billingAmount.currency || "",
          )
        }}
      </div>
    </div>

    <div
      v-if="destinationAmount"
      class="w-full px-8 py-3 border-white/60 border-dashed"
    >
      <div class="text-white/80 mb-1 text-sm">
        Your {{ destinationAmount.currency }} account will be credited
      </div>
      <div class="text-secondary text-lg font-bold">
        {{ destinationAmount.currency }}
        {{
          formatAmountToMajor(
            destinationAmount.amount || 0,
            destinationAmount.currency || "",
          )
        }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { formatAmountToMajor } from "@/helpers";
import { Money } from "@/types";
import { computed } from "vue";

const props = defineProps<{
  billingAmount?: Money<number>;
  destinationAmount?: Money<number>;
  exchangeRate: string;
}>();

const isExchangeRateMajor = computed(() => {
  if (props.exchangeRate) {
    return Number(props.exchangeRate) * 100 >= 1;
  }

  return false;
});
</script>
