<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9636 4.25C7.71038 4.25 4.25 7.72665 4.25 12C4.25 16.2733 7.72665 19.75 12 19.75C16.2733 19.75 19.75 16.2733 19.75 12C19.75 7.72665 16.2571 4.25 11.9636 4.25ZM12 18.2C8.58148 18.2 5.8 15.4185 5.8 12C5.8 8.58148 8.56442 5.8 11.9636 5.8C15.403 5.8 18.2 8.58148 18.2 12C18.2 15.4185 15.4185 18.2 12 18.2Z"
    />
    <path
      d="M11.2246 8.125H12.7746V13.55H11.2246V8.125ZM11.2246 14.325H12.7746V15.875H11.2246V14.325Z"
    />
  </svg>
</template>
