<template>
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.7693 9.83498H20.3193M20.3193 9.83498L17.153 6.03498M20.3193 9.83498L17.153 13.635M12.7193 5.08498V3.35028C12.7193 3.08749 12.6648 2.82755 12.5592 2.58691C12.4536 2.34626 12.2992 2.13014 12.1058 1.9522C11.9125 1.77426 11.6843 1.63837 11.4357 1.55312C11.1871 1.46788 10.9235 1.43512 10.6616 1.45693L3.06164 2.08963C2.58668 2.12919 2.14394 2.3458 1.82122 2.6965C1.4985 3.04721 1.31936 3.50639 1.31934 3.98298V15.687C1.31936 16.1636 1.4985 16.6228 1.82122 16.9735C2.14394 17.3242 2.58668 17.5408 3.06164 17.5803L10.6616 18.214C10.9236 18.2358 11.1872 18.203 11.4359 18.1177C11.6845 18.0324 11.9128 17.8964 12.1062 17.7184C12.2996 17.5404 12.4539 17.3241 12.5595 17.0834C12.665 16.8426 12.7195 16.5826 12.7193 16.3197V14.585"
      stroke="#989796"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
