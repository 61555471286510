<template>
  <div v-if="exchangeRateQuote.exchange_rate">
    <div
      v-if="isExchangeRateMajor"
      class="text-sm bg-[#B47E300D] text-[#B47E30] px-3 py-1 w-fit font-medium rounded-[5px] -my-3"
    >
      {{ exchangeRateQuote.exchange_rate }}
      {{ exchangeRateQuote.sending_amount.currency }} ≈ 1
      {{ exchangeRateQuote.destination_amount.currency }}
    </div>
    <div
      v-else
      class="text-sm bg-[#B47E300D] text-[#B47E30] px-3 py-1 w-fit font-medium rounded-[5px] -my-3"
    >
      {{ (1 / Number(exchangeRateQuote.exchange_rate)).toFixed(0) }}
      {{ exchangeRateQuote.sending_amount.currency }} ≈ 1
      {{ exchangeRateQuote.destination_amount.currency }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { BankingQuoteResponse } from "@/types";
import { computed } from "vue";

const props = defineProps<{
  exchangeRateQuote: BankingQuoteResponse;
}>();

const isExchangeRateMajor = computed(() => {
  if (props.exchangeRateQuote && props.exchangeRateQuote.exchange_rate) {
    return Number(props.exchangeRateQuote.exchange_rate) * 100 >= 1;
  }

  return false;
});
</script>
