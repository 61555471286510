<template>
  <svg
    id="Layer_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="60px"
    height="60px"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <polygon
      style="fill: #dcdcdc"
      points="471.149,482.043 503.83,482.043 503.83,95.319 449.362,62.638 "
    />
    <polygon
      style="fill: #eeeeee"
      points="8.17,95.319 8.17,482.043 471.149,482.043 471.149,62.638 "
    />
    <polygon
      style="fill: #fe5f1a"
      points="471.149,29.957 449.362,62.638 471.149,95.319 503.83,95.319 503.83,29.957 "
    />
    <polygon
      style="fill: #d91a1a"
      points="422.128,29.957 389.447,62.638 422.128,95.319 471.149,95.319 471.149,29.957 "
    />
    <polygon
      style="fill: #969696"
      points="389.447,29.957 356.766,62.638 389.447,95.319 422.128,95.319 422.128,29.957 "
    />
    <rect
      x="8.17"
      y="29.957"
      style="fill: #b9b9b9"
      width="381.277"
      height="65.362"
    />
    <polygon
      style="fill: #d5f6f5"
      points="438.468,128 416.681,343.776 438.468,449.362 471.149,449.362 471.149,128 "
    />
    <rect
      x="40.851"
      y="128"
      style="fill: #ffffff"
      width="397.617"
      height="321.362"
    />
    <path
      style="fill: #fe5f1a"
      d="M256,181.379v214.604c59.261,0,107.302-48.041,107.302-107.302S315.261,181.379,256,181.379z"
    />
    <path
      style="fill: #d91a1a"
      d="M330.621,288.681c0-59.261-33.41-107.302-74.621-107.302c-59.261,0-107.302,48.041-107.302,107.302
	S196.739,395.983,256,395.983C297.212,395.983,330.621,347.942,330.621,288.681z"
    />
    <path
      d="M503.83,21.787H8.17c-4.512,0-8.17,3.657-8.17,8.17v452.085c0,4.513,3.658,8.17,8.17,8.17h495.66
	c4.513,0,8.17-3.657,8.17-8.17V29.957C512,25.444,508.343,21.787,503.83,21.787z M495.66,87.149h-65.362V38.128h65.362V87.149z
	 M413.957,38.128v49.021H16.34V38.128H413.957z M16.34,473.872V103.489H495.66v370.383H16.34z"
    />
    <path
      d="M471.149,119.83H40.851c-4.512,0-8.17,3.657-8.17,8.17v256c0,4.513,3.658,8.17,8.17,8.17s8.17-3.657,8.17-8.17V136.17
	h413.957v305.021H49.021v-24.511c0-4.513-3.658-8.17-8.17-8.17s-8.17,3.657-8.17,8.17v32.681c0,4.513,3.658,8.17,8.17,8.17h430.298
	c4.513,0,8.17-3.657,8.17-8.17V128C479.319,123.487,475.662,119.83,471.149,119.83z"
    />
    <circle cx="462.979" cy="62.638" r="8.17" />
    <path
      d="M174.292,218.66c-3.637-2.668-8.751-1.887-11.421,1.751c-14.618,19.907-22.344,43.515-22.344,68.27
	c0,63.671,51.8,115.472,115.472,115.472c63.671,0,115.472-51.801,115.472-115.472S319.671,173.209,256,173.209
	c-24.755,0-48.362,7.727-68.27,22.343c-3.637,2.671-4.422,7.785-1.751,11.421c2.671,3.638,7.786,4.421,11.421,1.751
	c17.084-12.544,37.349-19.174,58.6-19.174c54.662,0,99.132,44.47,99.132,99.132s-44.47,99.132-99.132,99.132
	s-99.132-44.47-99.132-99.132c0-21.252,6.63-41.516,19.174-58.6C178.713,226.443,177.929,221.331,174.292,218.66z"
    />
    <path
      d="M205.161,339.521c1.595,1.595,3.686,2.392,5.777,2.392c2.09,0,4.182-0.797,5.777-2.392L256,300.236l39.285,39.286
	c1.596,1.595,3.686,2.392,5.778,2.392c2.09,0,4.182-0.797,5.778-2.392c3.191-3.192,3.191-8.364,0-11.555l-39.286-39.286
	l39.286-39.286c3.191-3.192,3.191-8.364,0-11.555c-3.192-3.19-8.364-3.19-11.555,0L256,277.126l-39.286-39.286
	c-3.191-3.19-8.364-3.19-11.554,0c-3.191,3.192-3.191,8.364,0,11.555l39.285,39.286l-39.285,39.286
	C201.97,331.157,201.97,336.329,205.161,339.521z"
    />
  </svg>
</template>
