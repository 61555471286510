<template>
  <PinInputRoot
    v-model="digits"
    class="w-fit grid grid-flow-col gap-x-2"
    placeholder="○"
    @complete="handleComplete"
  >
    <PinInputInput
      v-for="(el, ind) in count"
      :key="el + ind"
      :index="ind"
      class="bg-[#E8EAE280] w-full max-w-[48px] h-12 rounded-[5px] focus:outline-[#e8eae2] focus:outline-1 p-1 text-center"
      :class="error ? 'border border-red-500' : ''"
      required
    />
  </PinInputRoot>
</template>
<script setup lang="ts">
import { ref } from "vue";
import { PinInputInput, PinInputRoot } from "radix-vue";

interface OtpProps {
  count: number;
  error?: boolean;
  defaultValue?: string[];
}

const props = withDefaults(defineProps<OtpProps>(), {
  defaultValue: () => [],
});

const digits = ref<string[]>(props.defaultValue);

const emit = defineEmits<{
  (e: "complete", payload: string): void;
}>();

function handleComplete(e: string[]) {
  emit("complete", e.join(""));
}
</script>
