<template>
  <svg
    width="29"
    height="29"
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.0911865"
      y="0.414795"
      width="28"
      height="28"
      rx="14"
      fill="#E6E6E6"
    />
    <path
      d="M20.4594 6.52956C20.38 6.5393 20.3034 6.56511 20.234 6.60546L5.80943 14.499C5.70717 14.556 5.6238 14.6422 5.56971 14.7468C5.51562 14.8514 5.4932 14.9698 5.50525 15.0872C5.5173 15.2045 5.56328 15.3157 5.63747 15.4069C5.71165 15.4981 5.81075 15.5652 5.92242 15.5998L9.75396 16.7571C9.84351 17.473 10.2426 20.5733 10.3171 21.1781C10.3916 21.7804 10.7967 21.8848 11.2 21.3299C11.4729 20.954 13.0782 18.6734 13.0782 18.6734L16.4969 22.1271C16.5705 22.2017 16.6622 22.2554 16.7629 22.2829C16.8635 22.3104 16.9696 22.3107 17.0704 22.2838C17.1712 22.2569 17.2632 22.2036 17.3372 22.1295C17.4112 22.0553 17.4647 21.9628 17.4922 21.8612L21.0983 7.28855C21.1245 7.19382 21.1272 7.09404 21.1063 6.99798C21.0855 6.90192 21.0415 6.8125 20.9785 6.73758C20.9154 6.66265 20.8351 6.60451 20.7446 6.56824C20.6541 6.53197 20.5562 6.51869 20.4594 6.52956ZM19.5964 8.31349L16.572 20.5332L13.1726 17.0983L18.1118 10.1727L10.4301 15.7133L7.63174 14.8785L19.5952 8.31288L19.5964 8.31349Z"
      fill="#2F2F2F"
      fill-opacity="0.8"
    />
  </svg>
</template>
