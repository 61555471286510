<template>
  <svg
    width="13"
    height="8"
    viewBox="0 0 13 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.65599 0.899462C1.59738 0.844073 1.52843 0.800772 1.45308 0.77203C1.37773 0.743288 1.29746 0.729668 1.21685 0.731948C1.13624 0.734229 1.05686 0.752365 0.98326 0.78532C0.909657 0.818276 0.843265 0.865406 0.787877 0.92402C0.732488 0.982634 0.689186 1.05158 0.660444 1.12693C0.631702 1.20228 0.618082 1.28255 0.620363 1.36316C0.622643 1.44378 0.640779 1.52315 0.673735 1.59675C0.70669 1.67036 0.75382 1.73675 0.812434 1.79214L6.33792 7.01065C6.45191 7.11842 6.60283 7.17847 6.7597 7.17847C6.91657 7.17847 7.06749 7.11842 7.18148 7.01065L12.7076 1.79214C12.7675 1.73711 12.8158 1.67074 12.8499 1.59686C12.8839 1.52299 12.9029 1.44309 12.9058 1.36181C12.9087 1.28052 12.8954 1.19948 12.8667 1.12338C12.838 1.04727 12.7945 0.977632 12.7386 0.918496C12.6828 0.859361 12.6157 0.81191 12.5414 0.778901C12.4671 0.745892 12.3869 0.727982 12.3056 0.726211C12.2243 0.72444 12.1434 0.738843 12.0677 0.768585C11.992 0.798327 11.923 0.842814 11.8646 0.899462L6.7597 5.72014L1.65599 0.899462Z"
      fill="black"
    />
  </svg>
</template>
