<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="142"
    height="45"
    viewBox="0 0 142 45"
    fill="none"
  >
    <g filter="url(#filter0_i_11207_36929)">
      <path
        d="M84.1481 -0.813937C97.6623 5.07719 103.838 20.8155 97.9426 34.3397C92.0471 47.864 76.3134 54.0515 62.7992 48.1603C49.285 42.2692 43.1092 26.5309 49.0047 13.0067C54.9002 -0.517579 70.6339 -6.70506 84.1481 -0.813937ZM82.0132 4.08349C76.8216 1.82034 70.9429 1.71379 65.6703 3.78728C60.3978 5.86077 56.1634 9.94446 53.8985 15.14C51.6337 20.3355 51.524 26.2172 53.5935 31.4913C55.6631 36.7654 59.7424 40.9998 64.9341 43.2629C70.1257 45.5261 76.0044 45.6326 81.277 43.5591C86.5495 41.4856 90.784 37.4019 93.0488 32.2064C95.3136 27.0109 95.4233 21.1292 93.3538 15.8551C91.2842 10.581 87.2049 6.34664 82.0132 4.08349ZM70.2713 31.0193C70.9203 31.3022 71.4302 31.8315 71.6889 32.4908C71.9476 33.15 71.9339 33.8853 71.6508 34.5347C71.3677 35.1841 70.8383 35.6946 70.1793 35.9538C69.5202 36.213 68.7854 36.1997 68.1364 35.9168C67.4875 35.6339 66.9775 35.1046 66.7189 34.4453C66.4602 33.7861 66.4739 33.0508 66.757 32.4014C67.0401 31.752 67.5694 31.2415 68.2285 30.9823C68.8875 30.7231 69.6224 30.7364 70.2713 31.0193ZM79.8783 8.98092C80.5273 9.26381 81.0372 9.79311 81.2959 10.4524C81.5546 11.1116 81.5409 11.8468 81.2578 12.4963L74.8531 27.1886C74.57 27.838 74.0407 28.3485 73.3816 28.6076C72.7226 28.8668 71.9877 28.8535 71.3388 28.5706C70.6898 28.2877 70.1799 27.7584 69.9212 27.0992C69.6625 26.4399 69.6762 25.7047 69.9593 25.0553L76.364 10.363C76.6471 9.71354 77.1764 9.20308 77.8355 8.94389C78.4945 8.6847 79.2294 8.69802 79.8783 8.98092Z"
        fill="url(#paint0_linear_11207_36929)"
        fill-opacity="0.49"
      />
    </g>
    <g filter="url(#filter1_i_11207_36929)">
      <circle
        cx="73.4741"
        cy="24.0707"
        r="37.0469"
        stroke="url(#paint1_linear_11207_36929)"
        stroke-opacity="0.19"
        stroke-width="7.83277"
      />
    </g>
    <g filter="url(#filter2_di_11207_36929)">
      <circle
        cx="73.4739"
        cy="24.0725"
        r="53.9472"
        stroke="url(#paint2_linear_11207_36929)"
        stroke-opacity="0.1"
        stroke-width="9.12569"
        shape-rendering="crispEdges"
      />
    </g>
    <defs>
      <filter
        id="filter0_i_11207_36929"
        x="46.7708"
        y="-3.04297"
        width="53.4056"
        height="57.4261"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3.99344" />
        <feGaussianBlur stdDeviation="4.9918" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_11207_36929"
        />
      </filter>
      <filter
        id="filter1_i_11207_36929"
        x="32.5107"
        y="-16.8926"
        width="81.9266"
        height="86.0514"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4.1246" />
        <feGaussianBlur stdDeviation="4.1246" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.09 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_11207_36929"
        />
      </filter>
      <filter
        id="filter2_di_11207_36929"
        x="0.527754"
        y="-44.749"
        width="145.892"
        height="145.892"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4.1246" />
        <feGaussianBlur stdDeviation="7.21806" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.705882 0 0 0 0 0.494118 0 0 0 0 0.188235 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_11207_36929"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_11207_36929"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5.89138" />
        <feGaussianBlur stdDeviation="5.89138" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.09 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_11207_36929"
        />
      </filter>
      <linearGradient
        id="paint0_linear_11207_36929"
        x1="115.47"
        y1="19.5075"
        x2="8.27616"
        y2="22.6527"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#B47E30" />
        <stop offset="1" stop-color="#4E3715" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_11207_36929"
        x1="73.4741"
        y1="-16.8926"
        x2="19.3273"
        y2="62.2078"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#B47E30" />
        <stop offset="1" stop-color="#666666" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_11207_36929"
        x1="73.4739"
        y1="-34.4375"
        x2="-3.86669"
        y2="78.5456"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#B47E30" />
        <stop offset="1" stop-color="#666666" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
</template>
