<template>
  <div class="w-full px-5 py-6">
    <div
      class="flex gap-x-1 items-center w-full text-sm text-[#B47E30] bg-[#FFF8ED] rounded-[5px] py-2 px-6"
    >
      <span>
        <caution-icon class="w-6 h-6 fill-[#B47E30]" />
      </span>
      <span>We can only send to business accounts</span>
    </div>

    <form class="w-full mt-5 flex flex-col gap-y-4" @submit="onSubmit">
      <select-input
        label="Country"
        :data="countries"
        :default-value="values.country"
        :error-message="errors.country"
        @select="handleSelectedCountry"
      />
      <app-input
        v-bind="formFields.currency"
        :error-message="errors.currency"
        type="select"
        label="Currency"
        name="currency"
        required
      >
        <option value="" disabled selected>Select a currency</option>
        <option
          v-for="it in currencyList"
          :key="it.currency"
          :value="it.currency"
        >
          {{ it.currency }}
        </option>
      </app-input>
      <app-input
        v-bind="formFields.method"
        :error-message="errors.method"
        label="Payment Method"
        type="select"
        name="method"
        required
      >
        <option value="" selected disabled>Select payment method</option>
        <option v-for="it in methodsList" :key="it" :value="it">
          {{ it === "FED_WIRE" ? "US Domestic Wire" : it }}
        </option>
      </app-input>

      <app-input
        v-bind="formFields.beneficiary_name"
        :error-message="errors.beneficiary_name"
        label="Beneficiary Name"
        type="text"
        name="beneficiary_name"
        placeholder="Enter business name"
        required
      />

      <app-input
        v-bind="formFields.email"
        :error-message="errors.email"
        label="Beneficiary Email"
        type="text"
        name="email"
        placeholder="Enter business email"
        :required="serviceType === ServiceType.BANKING"
      />

      <div class="flex justify-between gap-x-3">
        <app-button
          variant="outlined"
          size="lg"
          type="button"
          @click="closeModal"
          >Cancel</app-button
        >
        <app-button variant="primary" size="lg" type="submit">Next</app-button>
      </div>
    </form>
  </div>
</template>

<script lang="ts" setup>
import { useForm } from "vee-validate";
import { computed, reactive } from "vue";
import * as yup from "yup";

import { CreateFiatBeneficiary, FiatBeneficiaryView } from "./type";
import { ServiceType, SupportedCountriesData } from "@/types";
import { countriesList } from "@/helpers/countries";
import { SelectInputData } from "@/components/shared/type";

const props = defineProps<{
  closeModal: () => void;
  switchForm: (view: FiatBeneficiaryView) => void;
  updateFormData: (data: CreateFiatBeneficiary) => void;
  formData: CreateFiatBeneficiary;
  supportedCountries: SupportedCountriesData[];
  serviceType: ServiceType;
}>();

interface FormFields {
  country: string;
  beneficiary_name: string;
  method: string;
  currency: string;
  email?: string;
}

const countries = computed(() => {
  return countriesList.map((it) => ({
    label: it.name,
    value: it.code,
  }));
});

const { errors, handleSubmit, defineInputBinds, values, setFieldValue } =
  useForm<FormFields>({
    validationSchema: yup.object({
      beneficiary_name: yup.string().required().label("Beneficiary name"),
      email:
        props.serviceType === ServiceType.BANKING
          ? yup.string().email().required().label("Email")
          : yup.string().label("Email"),
      country: yup.string().required().label("Country"),
      method: yup.string().required().label("Method"),
      currency: yup.string().required().label("Currency"),
    }),
    initialValues: {
      country: props.formData.country,
      beneficiary_name: props.formData.beneficiary_name,
      method: props.formData.method.type,
      currency: props.formData.currency,
    },
  });

const handleSelectedCountry = (val: SelectInputData) => {
  setFieldValue("country", val.value);
};

const onSubmit = handleSubmit((formValues) => {
  props.updateFormData({
    ...props.formData,
    country: formValues.country,
    beneficiary_name: formValues.beneficiary_name,
    email: formValues.email,
    currency: formValues.currency,
    method: {
      type: formValues.method,
      details: props.formData.method.details,
    },
  });
  props.switchForm("beneficiary-address");
});

const formFields = reactive({
  country: defineInputBinds("country"),
  currency: defineInputBinds("currency"),
  beneficiary_name: defineInputBinds("beneficiary_name"),
  method: defineInputBinds("method"),
  email: defineInputBinds("email"),
});

const currencyList = computed(() => {
  if (values.country) {
    return props.supportedCountries
      .filter((item) => item.code === values.country)
      .map((item) => item.methods)[0];
  }
  return [];
});

const methodsList = computed(() => {
  if (values.currency) {
    return currencyList.value
      .filter((item) => item.currency === values.currency)
      .map((item) => item.payment_methods)[0];
  }
  return [];
});
</script>
