<template>
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="10"
      cy="10"
      r="10"
      transform="matrix(0 -1 -1 0 20.3496 20.7529)"
      fill="#EAEAEA"
    />
    <path
      d="M8.10215 11.0123L12.1058 14.8269C12.1726 14.8906 12.2613 14.9261 12.3536 14.9261C12.4459 14.9261 12.5347 14.8906 12.6015 14.8269L12.6058 14.8226C12.6383 14.7918 12.6641 14.7546 12.6818 14.7134C12.6995 14.6722 12.7086 14.6279 12.7086 14.583C12.7086 14.5382 12.6995 14.4939 12.6818 14.4527C12.6641 14.4115 12.6383 14.3743 12.6058 14.3435L8.83563 10.7515L12.6058 7.16094C12.6383 7.13007 12.6641 7.09291 12.6818 7.05173C12.6995 7.01054 12.7086 6.96618 12.7086 6.92136C12.7086 6.87654 12.6995 6.83218 12.6818 6.791C12.6641 6.74981 12.6383 6.71265 12.6058 6.68178L12.6015 6.67746C12.5347 6.61378 12.4459 6.57826 12.3536 6.57826C12.2613 6.57826 12.1726 6.61378 12.1058 6.67746L8.10215 10.4921C8.06694 10.5257 8.0389 10.566 8.01975 10.6107C8.0006 10.6554 7.99072 10.7036 7.99072 10.7522C7.99072 10.8008 8.0006 10.849 8.01975 10.8937C8.0389 10.9384 8.06694 10.9787 8.10215 11.0123Z"
      fill="#2F2F2F"
    />
  </svg>
</template>
