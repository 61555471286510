import { createRouter, createWebHistory } from "vue-router";
import {
  AccountView,
  BeneficiaryView,
  CreateAccountView,
  DashboardView,
  SendAssetView,
  EmailVerficationView,
  ForgotPasswordView,
  LoginView,
  ResetPasswordView,
  SettingsView,
  TransactionView,
  WithdrawUsdView,
  AccountDetailsView,
  AcceptInvitationView,
} from "./views";
import TransactionDetails from "@/components/transactions/TransactionDetails.vue";
import BeneficiaryDetails from "@/components/beneficiaries/BeneficiaryDetails.vue";
import { useLoggedInBusiness } from "./composables/states";
import { ServiceType } from "./types";
import SendFiatView from "./views/SendFiatView.vue";
import { useFeature } from "./composables/use-feature";
import { usePermission } from "./composables/use-permission";
import { LyncPermissions } from "./security/permissions";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "Dashboard",
      component: DashboardView,
      meta: { requiresAuth: true },
    },
    {
      path: "/beneficiaries",
      name: "Beneficiaries",
      children: [
        {
          path: "",
          name: "Beneficiaries",
          component: BeneficiaryView,
          meta: { requiresAuth: true },

          children: [
            {
              path: ":id",
              name: "Beneficiary Details",
              component: BeneficiaryDetails,
              meta: { requiresAuth: true },
            },
          ],
        },
      ],
    },
    {
      path: "/transactions",
      name: "Transactions",
      children: [
        {
          path: "",
          name: "Transactions",
          component: TransactionView,
          meta: { requiresAuth: true },
          children: [
            {
              path: "fiat/:id",
              name: "Fiat Transaction Details",
              component: TransactionDetails,
              meta: { requiresAuth: true },
              props: (route) => ({
                type: ServiceType.BANKING,
                id: route.params.id,
              }),
            },
            {
              path: "crypto/:id",
              name: "Crypto Transaction Details",
              component: TransactionDetails,
              meta: { requiresAuth: true },
              props: (route) => ({
                type: ServiceType.CRYPTO,
                id: route.params.id,
              }),
            },
          ],
        },
      ],
    },
    {
      path: "/settings",
      name: "Settings",
      component: SettingsView,
      meta: { requiresAuth: true },
    },
    {
      path: "/accounts",
      name: "Accounts",
      children: [
        {
          path: "",
          name: "Accounts",
          component: AccountView,
          meta: { requiresAuth: true },
        },
        {
          path: "crypto/:id",
          name: "Crypto Account Details",
          component: AccountDetailsView,
          props: { type: ServiceType.CRYPTO },
          meta: { requiresAuth: true },
        },
        {
          path: "fiat/:id",
          name: "Fiat Account Details",
          component: AccountDetailsView,
          props: { type: ServiceType.BANKING },
          meta: { requiresAuth: true },
        },
      ],
    },
    {
      path: "/payments",
      name: "Payments",
      meta: { requiresAuth: true },
      children: [
        {
          path: "/payments/withdraw-usd",
          name: "Withdraw USD",
          component: WithdrawUsdView,
          meta: {
            requiresAuth: true,
            requiresFeature: "CENTURION",
            requiresPermission: LyncPermissions.transactions_write,
          },
        },
        {
          path: "/payments/send-stablecoins",
          name: "Send stablecoin",
          component: SendAssetView,
          meta: {
            requiresAuth: true,
            requiresFeature: "CENTURION",
            requiresPermission: LyncPermissions.transactions_write,
          },
        },
        {
          path: "/payments/send-money",
          name: "Make Payment",
          component: SendFiatView,
          meta: {
            requiresAuth: true,
            requiresFeature: "HELIOS",
            requiresPermission: LyncPermissions.transactions_write,
          },
        },
      ],
    },
    {
      path: "/login",
      name: "Login",
      component: LoginView,
      meta: { requiresAuth: false },
    },
    {
      path: "/create-account",
      name: "Create Account",
      component: CreateAccountView,
      meta: { requiresAuth: false },
    },
    {
      path: "/forgot-password",
      name: "Forgot Password",
      component: ForgotPasswordView,
      meta: { requiresAuth: false },
    },
    {
      path: "/reset-password",
      name: "Reset Password",
      meta: { requiresAuth: false },
      children: [
        {
          path: "/reset-password/:token",
          name: "Reset Password",
          component: ResetPasswordView,
          meta: { requiresAuth: false },
        },
      ],
    },
    {
      path: "/email-verification",
      name: "Email Verification",
      meta: { requiresAuth: false },
      children: [
        {
          path: "/email-verification/:token",
          name: "Email Verification",
          component: EmailVerficationView,
          meta: { requiresAuth: false },
        },
      ],
    },
    {
      path: "/invitation",
      name: "Accept Invitation",
      meta: { requiresAuth: false },
      children: [
        {
          path: "/invitation/:token",
          name: "Accept Invitation",
          component: AcceptInvitationView,
          meta: { requiresAuth: false },
        },
      ],
    },

    // {
    //   path: "/about",
    //   name: "About",
    //   // route level code-splitting
    //   // this generates a separate chunk (About.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import("../../views/AboutView.vue"),
    // },
  ],
});

router.beforeEach(async (to) => {
  const { refreshBusiness, business } = useLoggedInBusiness();
  const { canAccess } = useFeature();
  const { hasPermission } = usePermission();
  // redirect to login page if not logged in and trying to access a restricted page

  if (!business.value) {
    await refreshBusiness();
  }

  if (to.meta.requiresAuth && !business.value) {
    if (to.query?.action === "verified") {
      return "/login?action=verified";
    } else {
      return "/login";
    }
  }

  if (!to.meta.requiresAuth && business.value) {
    return "/";
  }

  if (
    to.meta.requiresFeature &&
    !canAccess(to.meta.requiresFeature as string)
  ) {
    return "/";
  }

  if (
    to.meta.requiresPermission &&
    !hasPermission(to.meta.requiresPermission as string)
  ) {
    return "/";
  }
});

export default router;
