import { computed } from "vue";
import { useLoggedInBusiness } from "./states";

export function useFeature() {
  const { business } = useLoggedInBusiness();

  const canAccess = (feature: string) => {
    return business.value?.features.includes(feature) || false;
  };

  const canAccessHelios = computed(() => canAccess("HELIOS"));

  const canAccessCenturion = computed(() => canAccess("CENTURION"));

  return { canAccess, canAccessHelios, canAccessCenturion };
}
