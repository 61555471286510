<template>
  <svg
    width="15"
    height="17"
    viewBox="0 0 15 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.3599 12.2358L10.3815 3.94777M4.3599 12.2358L3.22669 5.08098M4.3599 12.2358L11.5147 11.1026"
      stroke="#297FB0"
      stroke-width="1.46351"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <rect
      x="2.84961"
      y="15.5797"
      width="9.38631"
      height="1.14479"
      rx="0.572397"
      fill="#297FB0"
    />
  </svg>
</template>
