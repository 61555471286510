export enum LyncPermissions {
  accounts_read = "lync.accounts.read",
  accounts_write = "lync.accounts.write",

  transactions_write = "lync.transactions.write",
  transactions_read = "lync.transactions.read",
  transactions_approve = "lync.transactions.approve",

  statements_read = "lync.statements.read",
  statements_write = "lync.statements.write",

  counterparties_read = "lync.counterparties.read",
  counterparties_write = "lync.counterparties.write",

  approval_requests_read = "lync.approval-requests.read",

  users_read = "lync.users.read",
  users_write = "lync.users.write",
}
