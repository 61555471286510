<template>
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 1.95232L14.6 0.5L8 7.34664L1.4 0.5L0 1.95232L6.6 8.79896L0 15.6456L1.4 17.0979L8 10.2513L14.6 17.0979L16 15.6456L9.4 8.79896L16 1.95232Z"
      fill="#05051E"
      fill-opacity="0.6"
    />
  </svg>
</template>
