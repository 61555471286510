<template>
  <app-modal
    :is-open="isModalOpen"
    :handle-close="closeModal"
    size="xl"
    align-right
  >
    <div class="w-full min-h-full h-full bg-white">
      <page-loader v-if="isLoading" />
      <error-component
        v-else-if="isError"
        message="Error fetching this transaction"
      />

      <crypto-transaction-details
        v-else-if="type === ServiceType.CRYPTO && data"
        :close-modal="closeModal"
        :refetch="refetch"
        :transaction="data as CryptoTransactionResponse"
      />
      <banking-transaction-details
        v-else-if="type === ServiceType.BANKING && data"
        :close-modal="closeModal"
        :refetch="refetch"
        :transaction="data as BankingTransactionResponse"
      />
    </div>
  </app-modal>
</template>

<script lang="ts" setup>
import { useGetTransaction } from "@/data-access/transactions";
import {
  CryptoTransactionResponse,
  BankingTransactionResponse,
  ServiceType,
  QueryKeys,
} from "@/types";
import { onMounted } from "vue";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useQueryClient } from "@tanstack/vue-query";

const props = defineProps<{
  id: string;
  type: ServiceType;
  extCloseModal?: () => void;
}>();

const isModalOpen = ref(false);
const { data, isLoading, isError, refetch } = useGetTransaction(
  props.id,
  props.type,
);

const router = useRouter();
const queryClient = useQueryClient();

const closeModal = () => {
  isModalOpen.value = false;
  queryClient.invalidateQueries({
    queryKey: [QueryKeys.BANKING_TRANSACTIONS],
  });
  queryClient.invalidateQueries({
    queryKey: [QueryKeys.CRYPTO_TRANSACTIONS],
  });
  if (props.extCloseModal) {
    props.extCloseModal();
  } else {
    router.back();
  }
};

onMounted(() => {
  isModalOpen.value = true;
});
</script>
