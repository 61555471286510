<template>
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_5630_3343)">
      <path
        d="M8.42188 10.33C7.31731 10.33 6.42188 11.2254 6.42188 12.33V16.33H10.4219V12.33C10.4219 11.2254 9.52644 10.33 8.42188 10.33Z"
      />
      <path
        d="M11.7552 12.3302V16.3302H14.4219C15.5264 16.3302 16.4219 15.4348 16.4219 14.3302V8.24959C16.422 7.90325 16.2874 7.57044 16.0465 7.32159L10.3812 1.1969C9.38159 0.115341 7.69447 0.0489349 6.61291 1.04856C6.56153 1.09606 6.51203 1.14553 6.46456 1.1969L0.809219 7.31959C0.561031 7.56947 0.421781 7.9074 0.421875 8.25959V14.3302C0.421875 15.4348 1.31731 16.3302 2.42188 16.3302H5.08853V12.3302C5.101 10.5124 6.56872 9.02787 8.34081 8.98512C10.1722 8.94094 11.7413 10.4507 11.7552 12.3302Z"
      />
      <path
        d="M8.42188 10.33C7.31731 10.33 6.42188 11.2254 6.42188 12.33V16.33H10.4219V12.33C10.4219 11.2254 9.52644 10.33 8.42188 10.33Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_5630_3343">
        <rect width="16" height="16" transform="translate(0.421875 0.335449)" />
      </clipPath>
    </defs>
  </svg>
</template>
