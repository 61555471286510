<template>
  <section
    class="relative w-full xl:w-[35%] border border-greyscale-1 px-6 py-4 after:absolute after:top-0 after:left-0 after:w-full after:h-full after:bg-white/40 after:cursor-not-allowed rounded-[10px]"
  >
    <h2 class="text-primary text-base font-[800]">Swap your assets</h2>
    <div class="w-full rounded-[6px] border border-greyscale-3 py-5 px-5 my-4">
      <swap-input title="You give" :assets="[]" :default-value="defaultUsdt" />
      <div class="w-full flex justify-between items-center my-1">
        <div class="h-[1px] bg-greyscale-1 w-[45%]"></div>
        <swap-icon />
        <div class="h-[1px] bg-greyscale-1 w-[45%]"></div>
      </div>
      <swap-input
        title="You receive"
        :assets="[]"
        :default-value="defaultUsdc"
      />
    </div>
    <div class="my-4 text-sm text-text-primary font-[800]">
      <p>1 USDT = 1.00 USDC</p>
      <p>Fee $0</p>
    </div>
    <app-button bold variant="gradient" size="lg">Swap</app-button>
  </section>
</template>

<script lang="ts" setup>
const defaultUsdt = {
  id: "",
  state: "",
  created_date: "",
  updated_date: "",
  name: "",
  type: "",
  asset: {
    currency: "USDT",
    blockchain: "Ethereum",
  },
  wallet_id: "",
  deposit_address: {
    address: "",
    type: "",
    qr_code_link: "",
  },
  precision: 2,
  balance: "",
  balance_in_usd: "",
};

const defaultUsdc = {
  id: "",
  state: "",
  created_date: "",
  updated_date: "",
  name: "",
  type: "",
  asset: {
    currency: "USDC",
    blockchain: "Ethereum",
  },
  wallet_id: "",
  deposit_address: {
    address: "",
    type: "",
    qr_code_link: "",
  },
  precision: 2,
  balance: "",
  balance_in_usd: "",
};
</script>
