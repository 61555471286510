import apiClient, { useRequestOptions } from "@/helpers/apiClient";
import {
  PaginatedResponse,
  QueryKeys,
  RequestOptions,
  RolesResponse,
  UserInvitesResponse,
  BusinessUsersResponse,
} from "@/types";
import { useQuery } from "@tanstack/vue-query";

export const useListUserInvites = (
  options: RequestOptions,
  enabled: boolean = true,
) => {
  return useQuery<PaginatedResponse<UserInvitesResponse>>({
    queryKey: [QueryKeys.USER_INVITES, options.page, options.limit],
    queryFn: () =>
      apiClient
        .get(`users/invites?${useRequestOptions(options)}`)
        .then((res) => res.data.data),
    enabled,
  });
};

export const useGetUserInvite = (id: string, enabled: boolean = true) => {
  return useQuery<UserInvitesResponse>({
    queryKey: [QueryKeys.USER_INVITES, id],
    queryFn: () =>
      apiClient.get(`users/invites/${id}`).then((res) => res.data.data),
    enabled,
  });
};

export const useListUsers = (
  options: RequestOptions,
  enabled: boolean = true,
) => {
  return useQuery<PaginatedResponse<BusinessUsersResponse>>({
    queryKey: [
      QueryKeys.USERS,
      options.page,
      options.limit,
      ...Object.values(options.filters || {}),
    ],
    queryFn: () =>
      apiClient
        .get(`users?${useRequestOptions(options)}`)
        .then((res) => res.data.data),
    enabled,
  });
};

export const useGetUser = (id: string, enabled: boolean = true) => {
  return useQuery<BusinessUsersResponse>({
    queryKey: [QueryKeys.USERS, id],
    queryFn: () => apiClient.get(`users/${id}`).then((res) => res.data.data),
    enabled,
  });
};

export const useGetRoles = (enabled: boolean = true) => {
  return useQuery<RolesResponse[]>({
    queryKey: [QueryKeys.ROLES],
    queryFn: () =>
      apiClient.get(`businesses/roles`).then((res) => res.data.data),
    enabled,
  });
};
