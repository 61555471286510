<template>
  <svg
    id="Capa_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 50 50"
    style="enable-background: new 0 0 50 50"
    xml:space="preserve"
  >
    <circle style="fill: #d75a4a" cx="25" cy="25" r="25" />
    <polyline
      style="
        fill: none;
        stroke: #ffffff;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-miterlimit: 10;
      "
      points="16,34 25,25 34,16 
	"
    />
    <polyline
      style="
        fill: none;
        stroke: #ffffff;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-miterlimit: 10;
      "
      points="16,16 25,25 34,34 
	"
    />
  </svg>
</template>
