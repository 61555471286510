<template>
  <page-loader v-if="loading" />
  <auth-layout
    v-else-if="userInvite"
    :title="`Invitation to join ${userInvite.business.name}`"
    :description="`You have been invited to collaborate with ${userInvite.business.name} on lync. Complete the onboarding process below to get started`"
  >
    <app-button
      v-if="userInvite.user_id"
      :loading="submitting"
      :disabled="submitting"
      type="button"
      variant="primary"
      size="lg"
      @click="handleExistingUserInvitation"
      >Accept Invitation</app-button
    >
    <form v-else class="w-full flex flex-col gap-y-2" @submit="onSubmit">
      <div class="w-full flex flex-col md:flex-row gap-x-3 gap-y-2">
        <app-input
          type="text"
          name="first_name"
          v-bind="formFields.first_name"
          :error-message="errors.first_name"
          placeholder="Enter first name"
          label="First name"
          required
        />
        <app-input
          type="text"
          name="last_name"
          v-bind="formFields.last_name"
          :error-message="errors.last_name"
          placeholder="Enter last name"
          label="Last name"
          required
        />
      </div>
      <phone-input
        label="Phone number"
        :error-message="phoneNumberError"
        :default-phone-code="defaultPhoneCode"
        @change="handlePhoneChange"
      />

      <app-input
        type="password"
        name="password"
        v-bind="formFields.password"
        :error-message="errors.password"
        placeholder="Enter your password"
        label="Password"
        required
      />
      <app-button
        :loading="submitting"
        :disabled="submitting"
        type="submit"
        variant="primary"
        size="lg"
        >Accept Invitation</app-button
      >
    </form>
  </auth-layout>
</template>

<script lang="ts" setup>
import { useForm } from "vee-validate";
import { reactive, ref, onMounted } from "vue";
import * as yup from "yup";
import { useWriteResource } from "@/composables/use-resource";
import { errorMessage } from "@/helpers/error";
import { withoutAuthClient } from "@/helpers/apiClient";
import { VerifyUserInviteResponse } from "@/types";
import { useAppToast } from "@/composables";
import { useRoute, useRouter } from "vue-router";

interface AcceptInvitationFields {
  password: string;
  first_name: string;
  last_name: string;
}

const toast = useAppToast();
const phoneNumber = ref("");
const phoneNumberError = ref("");
const defaultPhoneCode = ref("+234");
const route = useRoute();
const router = useRouter();

const token = route.params.token as string;

const userInvite = ref<null | VerifyUserInviteResponse>(null);
const loading = ref(false);

const { submitting, execute: handleAcceptInvitation } = useWriteResource(
  "/onboarding/invitation/accept",
  "post",
  {
    successTitle: "Invite accepted, you can now login",
    onError: (err) => {
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  },
);

const phoneNumberRegex = /^\+\d{1,3}\d{3,}$/;

const handlePhoneChange = (value: string) => {
  if (value.length < 8 || value.length > 15) {
    phoneNumberError.value = "Phone number must be between 8 and 15 characters";
    return;
  }
  if (!phoneNumberRegex.test(value)) {
    phoneNumberError.value = "Please enter a valid phone number";
    return;
  }
  phoneNumber.value = value;
};

const { errors, handleSubmit, defineInputBinds } =
  useForm<AcceptInvitationFields>({
    validationSchema: yup.object({
      password: yup
        .string()
        .min(8)
        .required()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
          "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 number, and 1 symbol",
        )
        .label("Password"),
      first_name: yup.string().required().label("Contact first name"),
      last_name: yup.string().required().label("Contact last name"),
    }),
  });

const onSubmit = handleSubmit(async (values) => {
  if (!phoneNumber.value) {
    phoneNumberError.value = "Phone number is required";
    return;
  }
  await handleAcceptInvitation({
    body: {
      token,
      user_info: {
        first_name: values.first_name,
        last_name: values.last_name,
        password: values.password,
        phone: phoneNumber.value,
      },
    },
  });

  router.push("/login");
});

const handleExistingUserInvitation = async () => {
  await handleAcceptInvitation({
    body: {
      token,
    },
  });
  router.push("/login");
};

const verifyInvitation = async () => {
  try {
    loading.value = true;
    const response = await withoutAuthClient.get(
      `/onboarding/invitation/${token}`,
    );
    userInvite.value = response.data.data;
  } catch (error) {
    toast.error(errorMessage(error), {
      position: "top-right",
    });
    setTimeout(() => {
      router.push("/login");
    }, 1000);
  } finally {
    loading.value = false;
  }
};

onMounted(() => {
  verifyInvitation();
});

const formFields = reactive({
  password: defineInputBinds("password"),
  first_name: defineInputBinds("first_name"),
  last_name: defineInputBinds("last_name"),
});
</script>
