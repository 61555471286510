<template>
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6807 20.7299C16.2035 20.7299 20.6807 16.2528 20.6807 10.7299C20.6807 5.20707 16.2035 0.729919 10.6807 0.729919C5.15782 0.729919 0.680664 5.20707 0.680664 10.7299C0.680664 16.2528 5.15782 20.7299 10.6807 20.7299Z"
      fill="#2F2F2F"
    />
    <path
      d="M13.1946 12.0573C13.1946 10.7298 12.3946 10.2748 10.7946 10.0848C9.65213 9.93296 9.42401 9.62983 9.42401 9.09858C9.42401 8.56733 9.80526 8.22608 10.5665 8.22608C11.2521 8.22608 11.6334 8.45358 11.8234 9.02296C11.8432 9.07801 11.8794 9.12568 11.9271 9.15954C11.9748 9.1934 12.0318 9.21182 12.0903 9.21233H12.6996C12.7348 9.21327 12.7699 9.20705 12.8026 9.19405C12.8353 9.18105 12.865 9.16154 12.89 9.1367C12.9149 9.11187 12.9346 9.08222 12.9477 9.04956C12.9609 9.0169 12.9673 8.98191 12.9665 8.94671V8.90921C12.892 8.49727 12.6836 8.12146 12.3736 7.84015C12.0636 7.55885 11.6693 7.3878 11.2521 7.35358V6.44358C11.2521 6.29171 11.1378 6.17796 10.9478 6.13983H10.3759C10.224 6.13983 10.1096 6.25358 10.0715 6.44358V7.31608C8.92838 7.46733 8.20526 8.22608 8.20526 9.17483C8.20526 10.4261 8.96651 10.9192 10.5665 11.1092C11.6334 11.2986 11.9759 11.5267 11.9759 12.1336C11.9759 12.7398 11.4428 13.1573 10.719 13.1573C9.72838 13.1573 9.38588 12.7405 9.27151 12.1711C9.23401 12.0198 9.11901 11.9436 9.00463 11.9436H8.35713C8.32199 11.9427 8.28704 11.949 8.25439 11.962C8.22174 11.9751 8.19208 11.9946 8.16719 12.0194C8.1423 12.0443 8.12271 12.0739 8.10959 12.1065C8.09648 12.1391 8.09011 12.1741 8.09088 12.2092V12.2467C8.24276 13.1955 8.85276 13.878 10.1096 14.068V14.9786C10.1096 15.1298 10.224 15.2442 10.414 15.2817H10.9859C11.1378 15.2817 11.2521 15.168 11.2903 14.9786V14.0673C12.4334 13.878 13.1946 13.0811 13.1946 12.0567V12.0573Z"
      fill="white"
    />
    <path
      d="M8.73822 16.0405C5.76697 14.978 4.24322 11.678 5.34822 8.75736C5.91947 7.16361 7.17635 5.95048 8.73822 5.38111C8.89072 5.30548 8.96635 5.19173 8.96635 5.00173V4.47048C8.96635 4.31923 8.89072 4.20548 8.73822 4.16736C8.7001 4.16736 8.62385 4.16736 8.58572 4.20486C7.72849 4.47256 6.93268 4.90719 6.24411 5.48372C5.55554 6.06024 4.98782 6.76728 4.57362 7.56412C4.15943 8.36097 3.90695 9.23187 3.83073 10.1267C3.7545 11.0215 3.85603 11.9226 4.12947 12.778C4.81447 14.903 6.4526 16.5342 8.58572 17.2167C8.73822 17.2924 8.89072 17.2167 8.92822 17.0649C8.96635 17.0274 8.96635 16.9886 8.96635 16.913V16.3817C8.96635 16.268 8.8526 16.1167 8.73822 16.0405ZM12.7757 4.20548C12.6232 4.12923 12.4707 4.20548 12.4332 4.35673C12.3951 4.39486 12.3951 4.43298 12.3951 4.50861V5.03986C12.3951 5.19173 12.5088 5.34298 12.6232 5.41923C15.5945 6.48173 17.1182 9.78173 16.0132 12.7024C15.442 14.2961 14.1851 15.5092 12.6232 16.0786C12.4707 16.1542 12.3951 16.268 12.3951 16.458V16.9892C12.3951 17.1405 12.4707 17.2542 12.6232 17.2924C12.6613 17.2924 12.7376 17.2924 12.7757 17.2549C13.633 16.9872 14.4288 16.5525 15.1173 15.976C15.8059 15.3995 16.3736 14.6924 16.7878 13.8956C17.202 13.0988 17.4545 12.2278 17.5307 11.333C17.6069 10.4382 17.5054 9.53715 17.232 8.68173C16.547 6.51923 14.8707 4.88798 12.7757 4.20548Z"
      fill="white"
    />
  </svg>
</template>
