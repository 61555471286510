<template>
  <svg
    id="Layer_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    height="20"
    viewBox="0 0 32 32"
    enable-background="new 0 0 32 32"
    xml:space="preserve"
  >
    <line
      fill="none"
      stroke="#ffffff"
      stroke-width="2"
      stroke-miterlimit="10"
      x1="25"
      y1="28"
      x2="7"
      y2="28"
    />
    <line
      fill="none"
      stroke="#ffffff"
      stroke-width="2"
      stroke-miterlimit="10"
      x1="16"
      y1="23"
      x2="16"
      y2="4"
    />
    <polyline
      fill="none"
      stroke="#ffffff"
      stroke-width="2"
      stroke-miterlimit="10"
      points="9,16 16,23 23,16 "
    />
  </svg>
</template>
