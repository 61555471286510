<template>
  <svg
    width="22"
    height="28"
    viewBox="0 0 22 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.9946 9.00573C16.0761 9.08701 16.1408 9.18357 16.1849 9.28987C16.229 9.39617 16.2517 9.51014 16.2517 9.62523C16.2517 9.74032 16.229 9.85428 16.1849 9.96059C16.1408 10.0669 16.0761 10.1634 15.9946 10.2447L10.7446 15.4947C10.6633 15.5762 10.5668 15.6409 10.4605 15.685C10.3542 15.7291 10.2402 15.7518 10.1251 15.7518C10.01 15.7518 9.89607 15.7291 9.78977 15.685C9.68347 15.6409 9.58691 15.5762 9.50563 15.4947L6.88063 12.8697C6.79928 12.7884 6.73474 12.6918 6.69071 12.5855C6.64668 12.4792 6.62402 12.3653 6.62402 12.2502C6.62402 12.1352 6.64668 12.0213 6.69071 11.915C6.73474 11.8087 6.79928 11.7121 6.88063 11.6307C6.96198 11.5494 7.05856 11.4848 7.16486 11.4408C7.27115 11.3968 7.38508 11.3741 7.50013 11.3741C7.61518 11.3741 7.72911 11.3968 7.8354 11.4408C7.94169 11.4848 8.03828 11.5494 8.11963 11.6307L10.1251 13.638L14.7556 9.00573C14.8369 8.92424 14.9335 8.85959 15.0398 8.81548C15.1461 8.77137 15.26 8.74866 15.3751 8.74866C15.4902 8.74866 15.6042 8.77137 15.7105 8.81548C15.8168 8.85959 15.9133 8.92424 15.9946 9.00573Z"
      fill="#FFA41B"
    />
    <path
      d="M0.5 3.50023C0.5 2.57197 0.868749 1.68173 1.52513 1.02536C2.1815 0.368978 3.07174 0.000228882 4 0.000228882L18 0.000228882C18.9283 0.000228882 19.8185 0.368978 20.4749 1.02536C21.1313 1.68173 21.5 2.57197 21.5 3.50023V27.1252C21.4999 27.2835 21.4569 27.4388 21.3756 27.5746C21.2942 27.7103 21.1775 27.8215 21.038 27.8962C20.8985 27.9709 20.7413 28.0063 20.5832 27.9988C20.4251 27.9912 20.272 27.9409 20.1402 27.8532L11 22.927L1.85975 27.8532C1.72798 27.9409 1.5749 27.9912 1.41681 27.9988C1.25872 28.0063 1.10153 27.9709 0.961999 27.8962C0.822465 27.8215 0.705807 27.7103 0.62445 27.5746C0.543092 27.4388 0.500082 27.2835 0.5 27.1252V3.50023ZM4 1.75023C3.53587 1.75023 3.09075 1.9346 2.76256 2.26279C2.43437 2.59098 2.25 3.0361 2.25 3.50023V25.4907L10.5153 21.1472C10.6589 21.0517 10.8275 21.0007 11 21.0007C11.1725 21.0007 11.3411 21.0517 11.4848 21.1472L19.75 25.4907V3.50023C19.75 3.0361 19.5656 2.59098 19.2374 2.26279C18.9092 1.9346 18.4641 1.75023 18 1.75023H4Z"
      fill="#FFA41B"
    />
  </svg>
</template>
