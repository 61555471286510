import { useLoggedInBusiness } from "./states";

export function usePermission() {
  const { business } = useLoggedInBusiness();

  const hasPermission = (permission: string) => {
    return business.value?.business.permissions.includes(permission) || false;
  };

  return { hasPermission };
}
