<template>
  <main class="w-full flex h-screen bg-white">
    <side-bar
      :routes="routes"
      :handle-signout="toggleLogoutModal"
      :show-sidebar="showSideBarOnMobile"
      :toggle-sidebar="toggleSideBar"
    />
    <section class="w-full h-full overflow-y-auto pb-10">
      <app-header
        :title="title"
        :handle-signout="toggleLogoutModal"
        :description="description"
        :toggle-sidebar="toggleSideBar"
      />
      <div class="w-full mt-0 mb-14 lg:mb-0">
        <slot />
      </div>
    </section>
    <app-modal
      size="md"
      :is-open="showLogoutModal"
      :handle-close="toggleLogoutModal"
    >
      <div class="w-full bg-white rounded-[10px] py-8 px-5">
        <p class="text-primary font-medium mb-10 text-center">
          Are you sure you want to signout?
        </p>

        <div class="flex justify-between items-center gap-x-3">
          <app-button size="lg" variant="outlined" @click="toggleLogoutModal"
            >Cancel</app-button
          >

          <app-button
            size="lg"
            variant="primary"
            :loading="submitting"
            @click="signout"
            >Signout</app-button
          >
        </div>
      </div>
    </app-modal>
  </main>
</template>

<script lang="ts" setup>
import {
  ActiveAccountIcon,
  ActiveBeneficiaryIcon,
  ActiveHomeIcon,
  ActiveTransactionIcon,
  InactiveAccountIcon,
  InactiveBeneficiaryIcon,
  InactiveHomeIcon,
  InactiveTransactionIcon,
  ActivePaymentIcon,
  InactivePaymentIcon,
} from "../../icons";
import { RouteProps } from "../props";
import { useWriteResource } from "@/composables/use-resource";
import { errorMessage } from "@/helpers/error";
import { useAppToast } from "@/composables";
import { ref, onMounted, shallowRef } from "vue";
import { useRouter } from "vue-router";
import { useLoggedInBusiness } from "@/composables/states";
import { useFeature } from "@/composables/use-feature";
import { useIntervalFn } from "@vueuse/core";

interface LayoutProps {
  title: string;
  description: string;
}

defineProps<LayoutProps>();

const showLogoutModal = ref(false);
const router = useRouter();

const toggleLogoutModal = () => {
  showLogoutModal.value = !showLogoutModal.value;
};

const routes = shallowRef<RouteProps[]>([
  {
    name: "Dashboard",
    activeIcon: ActiveHomeIcon,
    inActiveIcon: InactiveHomeIcon,
    route: "/",
  },
  {
    name: "Beneficiaries",
    activeIcon: ActiveBeneficiaryIcon,
    inActiveIcon: InactiveBeneficiaryIcon,
    route: "/beneficiaries",
  },
  {
    name: "Transactions",
    activeIcon: ActiveTransactionIcon,
    inActiveIcon: InactiveTransactionIcon,
    route: "/transactions",
  },
  {
    name: "Accounts",
    activeIcon: ActiveAccountIcon,
    inActiveIcon: InactiveAccountIcon,
    route: "/accounts",
  },
]);

const toast = useAppToast();
const { refreshBusiness } = useLoggedInBusiness();
const { canAccessCenturion, canAccessHelios } = useFeature();

const showSideBarOnMobile = ref(false);

const toggleSideBar = () => {
  showSideBarOnMobile.value = !showSideBarOnMobile.value;
};

useIntervalFn(
  async () => {
    await refreshBusiness();
  },
  1000 * 60 * 5,
  { immediate: true },
);

const { execute: handleSignout, submitting } = useWriteResource(
  "/onboarding/logout",
  "post",
  {
    onError: (err) => {
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  },
);

const signout = async () => {
  await handleSignout();
  await refreshBusiness();
  router.push("/login");
};

onMounted(() => {
  if (!canAccessCenturion.value && !canAccessHelios.value) {
    return;
  } else if (canAccessHelios.value) {
    routes.value = [
      {
        name: "Dashboard",
        activeIcon: ActiveHomeIcon,
        inActiveIcon: InactiveHomeIcon,
        route: "/",
      },
      {
        name: "Make payment",
        activeIcon: ActivePaymentIcon,
        inActiveIcon: InactivePaymentIcon,
        route: "/payments/send-money",
      },
      {
        name: "Beneficiaries",
        activeIcon: ActiveBeneficiaryIcon,
        inActiveIcon: InactiveBeneficiaryIcon,
        route: "/beneficiaries",
      },
      {
        name: "Transactions",
        activeIcon: ActiveTransactionIcon,
        inActiveIcon: InactiveTransactionIcon,
        route: "/transactions",
      },
      {
        name: "Accounts",
        activeIcon: ActiveAccountIcon,
        inActiveIcon: InactiveAccountIcon,
        route: "/accounts",
      },
    ];
  } else if (canAccessCenturion.value) {
    routes.value = [
      {
        name: "Dashboard",
        activeIcon: ActiveHomeIcon,
        inActiveIcon: InactiveHomeIcon,
        route: "/",
      },
      {
        name: "Make payment",
        activeIcon: ActivePaymentIcon,
        inActiveIcon: InactivePaymentIcon,
        route: "/payments/send-stablecoins",
      },
      {
        name: "Beneficiaries",
        activeIcon: ActiveBeneficiaryIcon,
        inActiveIcon: InactiveBeneficiaryIcon,
        route: "/beneficiaries",
      },
      {
        name: "Transactions",
        activeIcon: ActiveTransactionIcon,
        inActiveIcon: InactiveTransactionIcon,
        route: "/transactions",
      },
      {
        name: "Accounts",
        activeIcon: ActiveAccountIcon,
        inActiveIcon: InactiveAccountIcon,
        route: "/accounts",
      },
    ];
  }
});
</script>
