<template>
  <section class="w-full max-w-[700px] px-0 xl:px-10">
    <div
      class="w-full flex justify-between items-center border border-[#E8EAE2] py-3 px-4 md:px-8 rounded-[5px]"
    >
      <p>Enable Two Factor Authentication</p>
      <Switch
        v-model="enable2fa"
        as="button"
        :onclick="handle2fa"
        :class="enable2fa ? 'bg-[#35C658]' : 'bg-[#C8C8C8]'"
        class="relative inline-flex h-[22px] w-[42px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
      >
        <span class="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          :class="enable2fa ? 'translate-x-5' : 'translate-x-0'"
          class="pointer-events-none inline-block h-[18px] w-[18px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out"
        />
      </Switch>
    </div>
    <p class="text-sm mt-2">
      This enables a one time password sent to your email before you log in to
      your account
    </p>
  </section>
</template>

<script setup lang="ts">
import { useWriteResource } from "@/composables/use-resource";
import { useGetMfa } from "@/data-access/settings";
import { errorMessage } from "@/helpers/error";
import { Switch } from "@headlessui/vue";
import { watch } from "vue";
import { ref } from "vue";
import { useAppToast } from "@/composables";

const { data } = useGetMfa();
const toast = useAppToast();

const enable2fa = ref(data.value?.["2faEnabled"]);

const { execute: handleEnableMfa } = useWriteResource("/settings/mfa", "post", {
  successTitle: "Two Factor authentication enabled",
  onError: (err) => {
    toast.error(errorMessage(err), {
      position: "top-right",
    });
    enable2fa.value = !enable2fa.value;
  },
});

const { execute: handleDisableMfa } = useWriteResource(
  "/settings/mfa",
  "delete",
  {
    successTitle: "Two Factor authentication disabled",
    onError: (err) => {
      toast.error(errorMessage(err), {
        position: "top-right",
      });
      enable2fa.value = !enable2fa.value;
    },
  },
);

const handle2fa = async () => {
  if (enable2fa.value) {
    await handleDisableMfa();
  } else {
    await handleEnableMfa();
  }
};

watch(data, (val) => {
  if (val) {
    enable2fa.value = val["2faEnabled"];
  }
});
</script>
