<template>
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_5630_3351)">
      <path
        d="M8.42188 8.33545C9.213 8.33545 9.98636 8.10086 10.6442 7.66133C11.302 7.2218 11.8146 6.59709 12.1174 5.86619C12.4201 5.13528 12.4994 4.33101 12.345 3.55509C12.1907 2.77917 11.8097 2.06643 11.2503 1.50702C10.6909 0.947614 9.97816 0.566651 9.20224 0.41231C8.42631 0.257969 7.62205 0.337182 6.89114 0.639933C6.16024 0.942683 5.53552 1.45537 5.096 2.11317C4.65647 2.77097 4.42188 3.54433 4.42188 4.33545C4.42293 5.39599 4.8447 6.41279 5.59462 7.16271C6.34453 7.91263 7.36133 8.33439 8.42188 8.33545ZM8.42188 1.66878C8.94929 1.66878 9.46486 1.82518 9.9034 2.1182C10.3419 2.41122 10.6837 2.82769 10.8856 3.31496C11.0874 3.80223 11.1402 4.33841 11.0373 4.85569C10.9344 5.37297 10.6804 5.84813 10.3075 6.22107C9.93455 6.59401 9.4594 6.84799 8.94212 6.95088C8.42483 7.05377 7.88866 7.00096 7.40139 6.79913C6.91412 6.5973 6.49764 6.2555 6.20462 5.81697C5.91161 5.37844 5.75521 4.86287 5.75521 4.33545C5.75521 3.62821 6.03616 2.94993 6.53626 2.44983C7.03635 1.94974 7.71463 1.66878 8.42188 1.66878Z"
        fill="#2F2F2F"
        fill-opacity="0.8"
      />
      <path
        d="M8.42188 9.66919C6.83112 9.67095 5.30602 10.3037 4.18118 11.4285C3.05635 12.5533 2.42364 14.0784 2.42188 15.6692C2.42188 15.846 2.49211 16.0156 2.61714 16.1406C2.74216 16.2656 2.91173 16.3359 3.08854 16.3359C3.26535 16.3359 3.43492 16.2656 3.55995 16.1406C3.68497 16.0156 3.75521 15.846 3.75521 15.6692C3.75521 14.4315 4.24687 13.2445 5.12204 12.3694C5.99721 11.4942 7.1842 11.0025 8.42188 11.0025C9.65955 11.0025 10.8465 11.4942 11.7217 12.3694C12.5969 13.2445 13.0885 14.4315 13.0885 15.6692C13.0885 15.846 13.1588 16.0156 13.2838 16.1406C13.4088 16.2656 13.5784 16.3359 13.7552 16.3359C13.932 16.3359 14.1016 16.2656 14.2266 16.1406C14.3516 16.0156 14.4219 15.846 14.4219 15.6692C14.4201 14.0784 13.7874 12.5533 12.6626 11.4285C11.5377 10.3037 10.0126 9.67095 8.42188 9.66919Z"
        fill="#2F2F2F"
        fill-opacity="0.8"
      />
    </g>
    <defs>
      <clipPath id="clip0_5630_3351">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0.421875 0.335449)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
