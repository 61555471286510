<template>
  <div class="w-full">
    <button
      class="w-fit flex items-center gap-x-2 rounded-[100px] px-6 py-2 mb-12 text-sm border border-greyscale-3"
      @click="toggleCreateInvite"
    >
      <arrow-left-icon />
      Back
    </button>

    <form
      class="w-full max-w-[450px] bg-white mx-auto text-primary md:-translate-y-12"
      @submit="onSubmit"
    >
      <header class="w-full mb-5">
        <h2 class="text-xl font-[800] mb-2">Invite users</h2>
      </header>
      <div class="w-full flex flex-col gap-y-5 mb-8">
        <app-input
          label="Email"
          type="email"
          name="email"
          v-bind="formFields.email"
          :error-message="errors.email"
          placeholder="Enter email address"
        />
        <app-input
          label="Position"
          type="text"
          name="position"
          v-bind="formFields.position"
          :error-message="errors.position"
          placeholder="Enter position"
        />
        <div class="w-full relative">
          <app-input
            label="Role"
            type="select"
            name="role"
            v-bind="formFields.role"
            :error-message="errors.role"
            placeholder="Enter position"
          >
            <option value="" disabled>
              {{
                isLoading
                  ? "Fetching roles"
                  : isError
                    ? "Error fetching roles"
                    : "Select a role"
              }}
            </option>
            <option v-for="it in roles" :key="it.id" :value="it.id">
              {{ it.name }}
            </option>
          </app-input>
          <button
            v-if="values.role"
            type="button"
            class="bg-tertiary/5 text-tertiary font-medium text-sm px-3 py-1 rounded-[5px] absolute right-0 -top-1"
            @click="toggleShowPermissions"
          >
            View permissions
          </button>
        </div>
      </div>
      <app-button
        :loading="submitting"
        :disabled="submitting"
        type="submit"
        size="lg"
        variant="primary"
      >
        Invite User
      </app-button>
    </form>
  </div>
  <app-modal
    :is-open="showPermissions"
    :handle-close="toggleShowPermissions"
    size="xl"
    align-right
  >
    <roles-permissions-details
      :close-modal="toggleShowPermissions"
      :roles="selectedRolesWithPermissions"
    />
  </app-modal>
</template>

<script setup lang="ts">
import { useWriteResource } from "@/composables/use-resource";
import { errorMessage } from "@/helpers/error";
import { useForm } from "vee-validate";
import { reactive, computed, ref } from "vue";
import { useAppToast } from "@/composables";
import { useGetRoles } from "@/data-access/users";
import * as yup from "yup";

const props = defineProps<{
  toggleCreateInvite: () => void;
}>();

interface FormFields {
  email: string;
  position: string;
  role: string;
}

const showPermissions = ref(false);

const toggleShowPermissions = () => {
  showPermissions.value = !showPermissions.value;
};

const toast = useAppToast();

const { data, isLoading, isError } = useGetRoles();

const roles = computed(() => {
  return data.value?.filter((it) => it.id !== "OWNER") || [];
});

const selectedRolesWithPermissions = computed(() => {
  return roles.value.filter((it) => it.id === values.role);
});

const { submitting, execute: handleInviteUser } = useWriteResource(
  "/users/invites",
  "post",
  {
    successTitle: "Invite created successfully",
    onError: (err) => {
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  },
);

const { errors, handleSubmit, defineInputBinds, values } = useForm<FormFields>({
  validationSchema: yup.object({
    email: yup.string().required("Email is required").email("Email is invalid"),
    position: yup.string().required("Position is required"),
    role: yup.string().required("Role is required"),
  }),
});

const onSubmit = handleSubmit(async (values) => {
  await handleInviteUser({
    body: {
      email: values.email,
      position: values.position,
      role: values.role,
    },
  });

  props.toggleCreateInvite();
});

const formFields = reactive({
  email: defineInputBinds("email"),
  position: defineInputBinds("position"),
  role: defineInputBinds("role"),
});
</script>
