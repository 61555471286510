<template>
  <section class="w-full h-screen flex justify-center items-center">
    <page-loader />
  </section>
</template>

<script lang="ts" setup>
import { useWriteResource } from "@/composables/use-resource";
import { errorMessage } from "@/helpers/error";
import { onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useAppToast } from "@/composables";

const route = useRoute();
const router = useRouter();
const toast = useAppToast();

const { execute: handleEmailVerification } = useWriteResource(
  "/onboarding/verify-email",
  "post",
  {
    successTitle: "Email verification Successful",
    onError: (err) => {
      toast.error(errorMessage(err), {
        position: "top-right",
      });
      router.push("/login");
    },
  },
);

onMounted(() => {
  handleVerifyMail();
});

const handleVerifyMail = async () => {
  if (route.params.token) {
    await handleEmailVerification({
      body: {
        token: route.params.token,
      },
    });
    router.push("/login?action=verified");
  } else {
    router.push("/login");
  }
};
</script>
