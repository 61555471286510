<template>
  <section class="w-full px-4 md:px-8 lg:pl-14 lg:pr-8">
    <div v-if="showTabs" class="flex h-full flex-wrap gap-x-3 xl:flex-nowrap">
      <nav
        class="flex flex-row xl:flex-col w-full xl:w-[15%] gap-x-4 xl:max-w-[180px]>"
      >
        <router-link
          v-if="canAccessHelios"
          to="/payments/send-money"
          :class="`w-fit py-3 h-10 focus:outline-none text-sm ${
            $route.path === '/payments/send-money'
              ? 'text-tertiary font-[800]'
              : 'text-primary/60 font-medium'
          } `"
        >
          Make a payment
        </router-link>
        <router-link
          v-if="canAccessCenturion"
          to="/payments/send-stablecoins"
          :class="`w-fit py-3 h-10 focus:outline-none text-sm ${
            $route.path === '/payments/send-stablecoins'
              ? 'text-tertiary font-[800]'
              : 'text-primary/60 font-medium'
          } `"
        >
          Send USDT/USDC
        </router-link>
        <router-link
          v-if="canAccessCenturion"
          to="/payments/withdraw-usd"
          :class="`w-fit py-3 h-10 focus:outline-none text-sm ${
            $route.path === '/payments/withdraw-usd'
              ? 'text-tertiary font-[800]'
              : 'text-primary/60 font-medium'
          } `"
        >
          Withdraw USD
        </router-link>
      </nav>
      <div class="w-full xl:w-[85%] py-3 h-full xl:px-5">
        <slot />
      </div>
    </div>
    <div
      v-else-if="canAccessCenturion"
      class="flex h-full flex-wrap gap-x-3 xl:flex-nowrap"
    >
      <nav
        class="flex flex-row xl:flex-col w-full xl:w-[15%] gap-x-4 xl:max-w-[180px]>"
      >
        <router-link
          to="/payments/send-stablecoins"
          :class="`w-fit py-3 h-10 focus:outline-none text-sm ${
            $route.path === '/payments/send-stablecoins'
              ? 'text-tertiary font-[800]'
              : 'text-primary/60 font-medium'
          } `"
        >
          Send stablecoins
        </router-link>
        <router-link
          to="/payments/withdraw-usd"
          :class="`w-fit py-3 h-10 focus:outline-none text-sm ${
            $route.path === '/payments/withdraw-usd'
              ? 'text-tertiary font-[800]'
              : 'text-primary/60 font-medium'
          } `"
        >
          Withdraw USD
        </router-link>
      </nav>
      <div class="w-full xl:w-[85%] py-3 h-full xl:px-5">
        <slot />
      </div>
    </div>
    <div v-else>
      <slot />
    </div>
  </section>
</template>

<script lang="ts" setup>
import { useFeature } from "@/composables/use-feature";
import { computed } from "vue";

const { canAccessCenturion, canAccessHelios } = useFeature();

const showTabs = computed(
  () => canAccessCenturion.value && canAccessHelios.value,
);
</script>
