<template>
  <app-layout title="Transactions" description="Manage all transactions">
    <section class="w-full px-4 md:px-8 lg:pl-14 lg:pr-8">
      <approval-requests-banner
        v-if="
          approvalRequests?.items.length &&
          hasPermission(LyncPermissions.approval_requests_read)
        "
        :refetch="refetch"
        :requests="approvalRequests?.items"
      />
      <TabGroup v-if="showTabs" as="section">
        <TabList class="mt-0 px-0 flex w-full">
          <Tab v-slot="{ selected }" as="template">
            <button
              :class="`w-auto flex justify-center items-center py-3 h-10 px-8 focus:outline-none text-sm ${
                selected
                  ? 'text-primary font-[800] border-b-2 border-primary/60 '
                  : 'text-text-primary  font-medium border-b border-greyscale-1'
              } `"
            >
              Fiat
            </button>
          </Tab>
          <Tab v-slot="{ selected }" as="template">
            <button
              :class="`w-auto flex justify-center items-center py-3 h-10 px-8 focus:outline-none text-sm ${
                selected
                  ? 'text-primary font-[800] border-b-2 border-primary/60'
                  : 'text-text-primary font-medium border-b border-greyscale-1'
              } `"
            >
              Stablecoins
            </button>
          </Tab>
        </TabList>
        <TabPanels class="pt-5">
          <TabPanel class="w-full px-0">
            <banking-transactions-list />
          </TabPanel>
          <TabPanel class="w-full px-0">
            <crypto-transactions-list />
          </TabPanel>
        </TabPanels>
      </TabGroup>
      <div v-else-if="canAccessCenturion" class="w-full">
        <crypto-transactions-list />
      </div>
      <div v-else-if="canAccessHelios" class="w-full">
        <banking-transactions-list />
      </div>
      <empty-state v-else feature="transactions" />
    </section>

    <router-view></router-view>
  </app-layout>
</template>

<script lang="ts" setup>
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from "@headlessui/vue";
import { useListApprovalRequests } from "@/data-access/transactions";
import { useFeature } from "@/composables/use-feature";
import { usePermission } from "@/composables/use-permission";
import { computed, ref } from "vue";
import { LyncPermissions } from "@/security/permissions";

const currentPage = ref(1);
const perPage = ref(100);

const { canAccessCenturion, canAccessHelios } = useFeature();
const { hasPermission } = usePermission();

const { data: approvalRequests, refetch } = useListApprovalRequests(
  {
    page: currentPage,
    limit: perPage,
    filters: {
      state: ref(["PENDING"]),
    },
  },
  hasPermission(LyncPermissions.approval_requests_read),
);

const showTabs = computed(
  () => canAccessCenturion.value && canAccessHelios.value,
);
</script>
