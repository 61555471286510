<template>
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_5630_3356)">
      <path
        d="M11.0885 0.335449H5.75521C4.87148 0.336508 4.02425 0.688037 3.39935 1.31293C2.77446 1.93782 2.42293 2.78505 2.42188 3.66878V15.6688C2.42197 15.79 2.45513 15.909 2.5178 16.0128C2.58046 16.1166 2.67025 16.2014 2.7775 16.2579C2.88475 16.3145 3.0054 16.3408 3.12646 16.3339C3.24752 16.327 3.36441 16.2872 3.46454 16.2188L4.86854 15.2594L6.27254 16.2188C6.38347 16.2947 6.51477 16.3354 6.64921 16.3354C6.78365 16.3354 6.91495 16.2947 7.02588 16.2188L8.42587 15.2594L9.82588 16.2188C9.93687 16.2949 10.0683 16.3356 10.2029 16.3356C10.3375 16.3356 10.4689 16.2949 10.5799 16.2188L11.9799 15.2601L13.3799 16.2181C13.48 16.2863 13.5967 16.3259 13.7176 16.3328C13.8385 16.3396 13.959 16.3134 14.0661 16.2569C14.1732 16.2004 14.2629 16.1158 14.3256 16.0122C14.3883 15.9086 14.4216 15.7899 14.4219 15.6688V3.66878C14.4208 2.78505 14.0693 1.93782 13.4444 1.31293C12.8195 0.688037 11.9723 0.336508 11.0885 0.335449V0.335449ZM13.0885 14.4021L12.3552 13.9008C12.2441 13.8245 12.1126 13.7837 11.9779 13.7837C11.8432 13.7837 11.7116 13.8245 11.6005 13.9008L10.2005 14.8601L8.80054 13.9008C8.68955 13.8247 8.55812 13.7839 8.42354 13.7839C8.28896 13.7839 8.15754 13.8247 8.04654 13.9008L6.64654 14.8601L5.24654 13.9008C5.13568 13.825 5.00451 13.7844 4.87021 13.7844C4.73591 13.7844 4.60473 13.825 4.49388 13.9008L3.75521 14.4021V3.66878C3.75521 3.13835 3.96592 2.62964 4.34099 2.25457C4.71607 1.8795 5.22478 1.66878 5.75521 1.66878H11.0885C11.619 1.66878 12.1277 1.8795 12.5028 2.25457C12.8778 2.62964 13.0885 3.13835 13.0885 3.66878V14.4021Z"
        fill="#2F2F2F"
        fill-opacity="0.8"
      />
      <path
        d="M11.0884 5.66919H5.75505C5.38686 5.66919 5.08838 5.96767 5.08838 6.33585C5.08838 6.70404 5.38686 7.00252 5.75505 7.00252H11.0884C11.4566 7.00252 11.755 6.70404 11.755 6.33585C11.755 5.96767 11.4566 5.66919 11.0884 5.66919Z"
        fill="#2F2F2F"
        fill-opacity="0.8"
      />
      <path
        d="M9.75504 8.33545H5.75504C5.38686 8.33545 5.08838 8.63393 5.08838 9.00212C5.08838 9.37031 5.38686 9.66879 5.75504 9.66879H9.75504C10.1232 9.66879 10.4217 9.37031 10.4217 9.00212C10.4217 8.63393 10.1232 8.33545 9.75504 8.33545Z"
        fill="#2F2F2F"
        fill-opacity="0.8"
      />
    </g>
    <defs>
      <clipPath id="clip0_5630_3356">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0.421875 0.335449)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
