<template>
  <div class="relative w-full">
    <div class="w-full bg-white rounded-[10px] pb-5">
      <header
        class="w-full flex justify-between items-center border-b border-greyscale-7 px-5 py-4"
      >
        <h3 class="text-primary font-[800]">Create an account</h3>

        <button class="inline w-[16px] h-[18px]" @click="closeModal">
          <close-icon />
        </button>
      </header>

      <div v-if="canAccessHelios" class="px-5">
        <router-link
          to="/accounts"
          class="text-primary text-sm font-medium flex items-center gap-x-1 mt-5 underline underline-offset-4"
        >
          Create a bank account?
        </router-link>
      </div>

      <div v-if="canAccessCenturion" class="w-full">
        <div v-if="loading" class="w-full flex justify-center py-8">
          <spinner-icon class="fill-primary" />
        </div>

        <div v-if="assets" class="w-full flex flex-col gap-y-5 px-5 pt-5 pb-3">
          <Listbox v-model="selectedAsset">
            <div class="relative mt-1 mb-5">
              <ListboxLabel class="text-text-secondary text-sm font-medium"
                >Create stablecoin asset</ListboxLabel
              >
              <ListboxButton
                class="relative w-full h-10 border overflow-hidden border-[#e2e2e2] rounded-[5px] focus:outline-primary text-sm text-primary placeholder:text-sm mt-2 text-left px-4 py-2"
              >
                <span v-if="selectedAsset"
                  >{{ selectedAsset.currency }} -
                  {{ selectedAsset.blockchain }}</span
                >
                <span v-else>Choose an asset</span>
                <span
                  class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                >
                  <up-down-arrow-icon />
                </span>
              </ListboxButton>

              <ListboxOptions
                class="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 z-10 text-base shadow-lg ring-1 ring-primary/5 focus:outline-none sm:text-sm"
              >
                <div>
                  <ListboxOption
                    v-for="asset in assets"
                    v-slot="{ active, selected }"
                    :key="asset.currency"
                    :value="asset"
                    as="template"
                  >
                    <li
                      class="relative cursor-default select-none py-2 pl-10 pr-4"
                      :class="{
                        'bg-greyscale-2 text-primary': active,
                        'text-text-primary': !active,
                      }"
                    >
                      <span
                        :class="{
                          'font-medium': selected,
                          'font-normal': !selected,
                        }"
                        >{{ asset.currency }} - {{ asset.blockchain }}</span
                      >
                      <span
                        v-if="selected"
                        class="absolute inset-y-0 left-0 flex items-center pl-3"
                      >
                        <CheckIcon class="h-5 w-5" aria-hidden="true" />
                      </span>
                    </li>
                  </ListboxOption>
                </div>
              </ListboxOptions>
            </div>
          </Listbox>
          <p v-if="assets && !assets.length" class="text-sm text-text-primary">
            No supported assets at the moment - Contact support
          </p>

          <p v-if="error && !assets" class="text-sm text-failed">
            Error fetching supported assets - Contact support
          </p>

          <app-button
            :loading="submitting"
            :disabled="!selectedAsset"
            size="lg"
            variant="primary"
            @click="handleCreateAsset"
            >Create Asset</app-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
  ListboxLabel,
} from "@headlessui/vue";
import { useReadResource, useWriteResource } from "@/composables/use-resource";
import { onMounted } from "vue";
import { useAppToast } from "@/composables";
import { errorMessage } from "@/helpers/error";
import { useQueryClient } from "@tanstack/vue-query";
import { QueryKeys } from "@/types";
import { useFeature } from "@/composables/use-feature";

type TAsset = {
  currency: string;
  blockchain: string;
};

const props = defineProps<{
  closeModal: () => void;
}>();

const queryClient = useQueryClient();
const { canAccessCenturion, canAccessHelios } = useFeature();

const selectedAsset = ref<TAsset>();
const toast = useAppToast();

const {
  loading,
  fetch,
  error,
  data: assets,
} = useReadResource<Array<TAsset>>(`/crypto/v1/data/supported-assets`);

const { submitting, execute } = useWriteResource(
  "/crypto/v1/assets/corporate",
  "post",
  {
    successTitle: "Asset Created",
    onError: (err) => {
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  },
);

const handleCreateAsset = async () => {
  if (selectedAsset.value) {
    await execute({
      body: {
        currency: selectedAsset.value.currency,
        blockchain: selectedAsset.value.blockchain,
      },
    });
    queryClient.invalidateQueries({ queryKey: [QueryKeys.ASSETS] });
    props.closeModal();
  }
};

onMounted(() => {
  fetch();
});
</script>
