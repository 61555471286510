<template>
  <app-layout title="Accounts" description="Manage your accounts">
    <section class="w-full px-4 md:px-8 lg:pl-14 lg:pr-8">
      <page-loader v-if="isLoading" />
      <error-component
        v-else-if="isError"
        message="Error fetching account details"
      />

      <crypto-account-details
        v-else-if="type === ServiceType.CRYPTO && data"
        :asset="data as AssetsResponse"
      />

      <banking-account-details
        v-else-if="type === ServiceType.BANKING && data"
        :account="data as AccountsResponse"
      />
    </section>
  </app-layout>
</template>

<script lang="ts" setup>
import { useGetAccount } from "@/data-access/accounts";
import { AccountsResponse, AssetsResponse, ServiceType } from "@/types";

import { useRoute } from "vue-router";

const props = defineProps<{
  type: ServiceType;
}>();

const route = useRoute();
const id = route.params.id as string;

const { data, isLoading, isError } = useGetAccount(id, props.type);
</script>
