<template>
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.52546 0.830688C4.10709 0.830688 0.525391 4.41239 0.525391 8.83075C0.525391 13.2491 4.10709 16.8308 8.52546 16.8308C12.9438 16.8308 16.5255 13.2491 16.5255 8.83075C16.5255 4.41239 12.9438 0.830688 8.52546 0.830688ZM9.21626 13.1682V14.295H8.1385V13.1809C6.36994 12.9387 5.59547 11.4855 5.59547 11.4855L6.69635 10.5646C6.69635 10.5646 7.39926 11.7883 8.67077 11.7883C9.37313 11.7883 9.90595 11.4123 9.90595 10.7699C9.90595 9.26835 5.86024 9.45055 5.86024 6.66478C5.86024 5.45382 6.818 4.58137 8.13795 4.37496V3.24931H9.2157V4.37496C10.136 4.49605 11.2259 4.98044 11.2259 6.02242V6.82166H9.79697V6.43415C9.79697 6.03453 9.28781 5.76812 8.71811 5.76812C7.99153 5.76812 7.45926 6.13141 7.45926 6.64001C7.45926 8.17793 11.505 7.80254 11.505 10.7215C11.505 11.9215 10.6089 12.9618 9.21626 13.1682Z"
    />
  </svg>
</template>
