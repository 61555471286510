<template>
  <div
    class="w-fit py-2 px-4 rounded-[20px] bg-greyscale-1 text-primary text-xs flex items-center gap-x-2"
  >
    <div class="w-2 h-2 rounded-full" :class="statusClass"></div>
    <span v-if="status">
      {{
        status.charAt(0).toUpperCase() +
        status.slice(1).toLowerCase().replace("_", " ")
      }}
    </span>
    <span v-else> N/A </span>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
interface StatusProps {
  status: string;
}

const props = defineProps<StatusProps>();

const statusClass = computed(() => {
  switch (props.status) {
    case "ACTIVE":
      return "bg-success";
    case "INACTIVE":
      return "bg-gray-400";
    case "INVITATION_PENDING":
      return "bg-secondary";
    case "LOCKED":
      return "bg-failed";
    default:
      return "bg-gray-400";
  }
});
</script>
