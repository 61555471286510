<template>
  <svg
    width="22"
    height="17"
    viewBox="0 0 22 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.8942 7.212C20.3682 7.832 20.3682 8.669 19.8942 9.288C18.4012 11.237 14.8192 15.25 10.6372 15.25C6.45522 15.25 2.87322 11.237 1.38022 9.288C1.1496 8.99113 1.02441 8.62592 1.02441 8.25C1.02441 7.87408 1.1496 7.50887 1.38022 7.212C2.87322 5.263 6.45522 1.25 10.6372 1.25C14.8192 1.25 18.4012 5.263 19.8942 7.212V7.212Z"
      stroke="#2F2F2F"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.6367 11.25C12.2936 11.25 13.6367 9.90685 13.6367 8.25C13.6367 6.59315 12.2936 5.25 10.6367 5.25C8.97986 5.25 7.63672 6.59315 7.63672 8.25C7.63672 9.90685 8.97986 11.25 10.6367 11.25Z"
      stroke="#2F2F2F"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
