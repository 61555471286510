<template>
  <router-link
    :to="`/accounts/${id}`"
    class="block w-full lg:max-w-[400px] py-4 px-6 rounded-[10px] text-primary drop-shadow-[0px 12px 20px rgba(245, 245, 245, 0.25)]"
    :class="classObject"
  >
    <div
      class="flex justify-start items-center gap-x-2 text-base 2xl:text-lg leading-tight font-medium"
    >
      <span class="w-[22px] h-full">
        <component :is="assetIcon" />
      </span>
      <span>
        {{ assetName }}
        <span v-if="network" class="capitalize"
          >({{ network.toLowerCase() }})</span
        ></span
      >
    </div>
    <div class="text-2xl font-medium mt-3">
      {{ currencyOf(currency).symbol }} {{ dollarBalance }}
    </div>
    <div class="text-sm text-text-secondary mt-3">
      {{ balance }} {{ currency }}
    </div>
  </router-link>
</template>

<script lang="ts" setup>
import type { Component } from "vue";
import { currencyOf } from "@/helpers/currencies";

interface CardProps {
  id: string;
  assetIcon: Component;
  currency: string;
  assetName: string;
  network?: string;
  balance: string;
  dollarBalance: string;
  variant: "accent-blue" | "accent-green" | "usd-gradient" | string;
}

const props = defineProps<CardProps>();

const classObject = {
  "bg-accent-blue": props.variant === "accent-blue",
  "bg-accent-green": props.variant === "accent-green",
  "bg-usd-gradient": props.variant === "usd-gradient",
  "bg-[#FFF8ED]": !!props.variant,
};
</script>
