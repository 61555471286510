<template>
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.269456 12C0.820893 12.9571 2.0438 13.2859 3.00086 12.7344C3.00152 12.7341 3.00214 12.7337 3.0028 12.7333L3.29946 12.562C3.85946 13.0411 4.50314 13.4127 5.19811 13.658V14C5.19811 15.1046 6.09355 16 7.19811 16C8.30267 16 9.19811 15.1046 9.19811 14V13.658C9.89318 13.4123 10.5369 13.0403 11.0968 12.5607L11.3948 12.7327C12.352 13.2849 13.5758 12.9566 14.1281 11.9993C14.6804 11.042 14.3521 9.81825 13.3948 9.26597L13.0988 9.09531C13.232 8.37028 13.232 7.62703 13.0988 6.90197L13.3948 6.73131C14.352 6.17903 14.6804 4.95528 14.1281 3.99797C13.5758 3.04069 12.3521 2.71234 11.3948 3.26463L11.0981 3.43597C10.5375 2.95744 9.89342 2.58656 9.19811 2.342V2C9.19811 0.895437 8.30267 0 7.19811 0C6.09355 0 5.19811 0.895437 5.19811 2V2.342C4.50305 2.58772 3.85933 2.95972 3.29946 3.43934L3.00146 3.26669C2.04414 2.71438 0.820393 3.04272 0.268112 4C-0.284169 4.95728 0.0441434 6.18106 1.00146 6.73334L1.29746 6.904C1.16421 7.62903 1.16421 8.37228 1.29746 9.09734L1.00146 9.268C0.0467997 9.82175 -0.28045 11.0431 0.269456 12ZM7.19811 5.33334C8.67086 5.33334 9.86477 6.52725 9.86477 8C9.86477 9.47275 8.67086 10.6667 7.19811 10.6667C5.72536 10.6667 4.53146 9.47275 4.53146 8C4.53146 6.52725 5.72536 5.33334 7.19811 5.33334Z"
    />
  </svg>
</template>
