<template>
  <button
    class="rounded-[5px] flex items-center justify-center gap-x-2 cursor-pointer disabled:opacity-80 disabled:cursor-not-allowed py-2 align-middle"
    :class="classObject"
    :type="type"
    :disabled="disabled"
    @click="onClick"
  >
    <span v-if="loading">
      <spinner-icon
        :class="variant === 'outlined' ? 'fill-primary' : 'fill-white'"
      />
    </span>
    <template v-else-if="content">
      {{ content }}
    </template>
    <slot v-else />
  </button>
</template>

<script lang="ts" setup>
import { computed } from "vue";

interface ButtonProps {
  /**
   * The variant of the button
   */
  variant: "secondary" | "primary" | "outlined" | "gradient" | "danger";
  /**
   * Is button in a loading state?
   */
  loading?: boolean;
  /**
   * The size of the button
   */
  size: "sm" | "md" | "lg";
  /**
   * The type of the button
   */
  type?: "button" | "submit" | "reset";
  /**
   * Is the button disabled
   */
  disabled?: boolean;
  /**
   * The action to occur when button is clicked
   */
  onClick?: () => void;
  /**
   * The content of the button
   */
  content?: string;
  bold?: boolean;
}

const props = withDefaults(defineProps<ButtonProps>(), {
  size: "md",
  variant: "primary",
  type: "button",
  onClick: () => {},
  content: "",
});

const classObject = computed(() => ({
  "btn-primary": props.variant === "primary",
  "btn-secondary": props.variant === "secondary",
  "btn-outlined": props.variant === "outlined",
  "btn-danger": props.variant === "danger",
  "btn-gradient": props.variant === "gradient",
  "btn-md": props.size === "md",
  "btn-lg": props.size === "lg",
  "btn-sm": props.size === "sm",
  "font-medium": props.bold === true,
}));
</script>
