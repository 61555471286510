import { currencyOf } from "./currencies";

export const formatDate = (date: string) => {
  return Intl.DateTimeFormat("en-EN", {
    month: "short",
    year: "numeric",
    day: "2-digit",
  }).format(new Date(date));
};

export const formatDateTime = (date: string | Date) => {
  return Intl.DateTimeFormat("en-EN", {
    timeStyle: "short",
    dateStyle: "long",
  }).format(new Date(date));
};

export const copyToClipboard = (msg: string) => {
  const storage = document.createElement("textarea");
  storage.innerHTML = msg;
  document.body.appendChild(storage);
  storage.select();
  storage.setSelectionRange(0, 99999);
  document.execCommand("copy");
  document.body.removeChild(storage);
};

export const maskAddress = (val: string) => {
  return `
  ${val.substr(0, 5)}.....${val.slice(-5)}
  `;
};

export const formatAmount = (amount: string | number, precision?: number) =>
  new Intl.NumberFormat("en-US", {
    minimumFractionDigits: precision || 2,
  }).format(Number(amount));

export const formatAmountToMajor = (
  amount: number | string,
  currency: string,
) => {
  const precision = currencyOf(currency).precision;
  const calculatedAmount = Number(amount) / 10 ** precision;

  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: precision,
  }).format(calculatedAmount);
};

export const printMoney = (amount: string, currency: string) =>
  `${currency} ${amount}`;

export function abbrNumber(number: number, decPlaces: number) {
  // 2 decimal places => 100, 3 => 1000, etc
  decPlaces = Math.pow(10, decPlaces);

  // Enumerate number abbreviations
  const abbrev = ["k", "m", "b", "t"];

  // Go through the array backwards, so we do the largest first
  for (let i = abbrev.length - 1; i >= 0; i--) {
    // Convert array index to "1000", "1000000", etc
    const size = Math.pow(10, (i + 1) * 3);

    // If the number is bigger or equal do the abbreviation
    if (size <= number) {
      // Here, we multiply by decPlaces, round, and then divide by decPlaces.
      // This gives us nice rounding to a particular decimal place.
      number = Math.round((number * decPlaces) / size) / decPlaces;

      // Handle special case where we round up to the next abbreviation
      if (number == 1000 && i < abbrev.length - 1) {
        number = 1;
        i++;
      }

      // Add the letter for the abbreviation
      number += abbrev[i] as unknown as number;

      // We are done... stop
      break;
    }
  }

  return number;
}

export function camelCaseToWords(s: string) {
  const result = s.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export function convertToWords(input: string): string {
  let result = input.replace(/_/g, " ");

  result = result.replace(/([a-z])([A-Z])/g, "$1 $2");
  result = result.replace(/([A-Z])([A-Z][a-z])/g, "$1 $2");

  result = result.trim().replace(/\s+/g, " ");

  return result.replace(/\w\S*/g, (word) => {
    return word.length > 1
      ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      : word.toUpperCase();
  });
}

export const snakeCaseToWords = (s: string) =>
  s
    .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())
    .replace(/[-_]+(.)/g, (_, c) => " " + c.toUpperCase());

export function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}
