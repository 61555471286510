<template>
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_5380_11806)">
      <path
        d="M12.5831 5.30756H6.69395C6.04345 5.30756 5.51611 5.83489 5.51611 6.48539V12.3746C5.51611 13.0251 6.04345 13.5524 6.69395 13.5524H12.5831C13.2336 13.5524 13.761 13.0251 13.761 12.3746V6.48539C13.761 5.83489 13.2336 5.30756 12.5831 5.30756Z"
        stroke="#2F2F2F"
        stroke-width="1.17784"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.16026 10.0188C2.51245 10.0188 1.98242 9.48875 1.98242 8.84094V2.95176C1.98242 2.30395 2.51245 1.77393 3.16026 1.77393H9.04944C9.69725 1.77393 10.2273 2.30395 10.2273 2.95176"
        stroke="#2F2F2F"
        stroke-width="1.17784"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5380_11806">
        <rect
          width="14.134"
          height="14.134"
          fill="white"
          transform="translate(0.804688 0.596069)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
