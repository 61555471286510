<template>
  <header
    class="w-full lg:z-[50] lg:sticky lg:top-0 bg-white flex flex-col-reverse md:flex-row justify-between gap-x-8 items-start pt-10 pb-5 px-4 md:px-8 lg:pl-14 lg:pr-8 gap-y-3"
  >
    <div class="w-fit lg:min-w-max hidden lg:block">
      <h1 class="text-[28px] font-[700] text-primary">{{ title }}</h1>
      <p class="text-lg mt-1 text-text-primary">
        {{ description }}
      </p>
    </div>
    <div
      class="flex w-full lg:w-fit lg:min-w-max items-center gap-x-2 justify-between lg:justify-end"
    >
      <button class="block lg:hidden" @click="toggleSidebar">
        <hamburger-icon />
      </button>
      <div class="flex items-center gap-x-3">
        <fx-rates-slider />

        <router-link to="/settings">
          <settings-large-icon />
        </router-link>

        <Menu
          as="div"
          class="relative inline-block text-left self-end md:self-auto"
        >
          <MenuButton
            class="h-[40px] lg:h-[52px] px-5 rounded-[20px] bg-greyscale-2 flex justify-center items-center gap-x-3 text-xs lg:text-sm"
          >
            <span class="hidden md:inline">{{ business?.business.name }}</span>
            <span class="capitalize md:hidden font-bold text-base">{{
              business?.business.name[0]
            }}</span>
            <arrow-down-icon />
          </MenuButton>

          <MenuItems
            class="absolute right-0 mt-2 w-fit origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none z-[20]"
          >
            <div class="p-1 flex flex-col gap-y-1">
              <MenuItem>
                <router-link to="/settings">
                  <button
                    class="flex w-[120px] items-center text-sm gap-x-2 px-3 py-2"
                  >
                    <settings-icon />
                    Settings
                  </button>
                </router-link>
              </MenuItem>
              <MenuItem>
                <button
                  class="flex w-[120px] items-center text-sm gap-x-2 px-3 py-2"
                  @click="handleSignout"
                >
                  <signout-icon />
                  Sign out
                </button>
              </MenuItem>
            </div>
          </MenuItems>
        </Menu>
      </div>
    </div>
  </header>
</template>

<script lang="ts" setup>
import { useLoggedInBusiness } from "@/composables/states";
import { Menu, MenuItems, MenuItem, MenuButton } from "@headlessui/vue";

interface HeaderProps {
  title: string;
  description: string;
  handleSignout: () => void;
  toggleSidebar: () => void;
}

defineProps<HeaderProps>();

const { business } = useLoggedInBusiness();
</script>
