<template>
  <svg
    fill="#ffffff"
    xmlns="http://www.w3.org/2000/svg"
    width="16px"
    height="16px"
    viewBox="0 0 1.04 1.04"
    enable-background="new 0 0 52 52"
    xml:space="preserve"
  >
    <path
      d="M0.602 0.522c0 -0.046 0.028 -0.086 0.066 -0.106 -0.006 -0.078 -0.07 -0.138 -0.15 -0.138 -0.082 0 -0.15 0.068 -0.15 0.15s0.068 0.15 0.15 0.15c0.03 0 0.06 -0.01 0.082 -0.026v-0.03z"
    />
    <path
      d="M0.602 0.728v-0.038c0 -0.014 0.002 -0.026 0.006 -0.038H0.256c0 -0.058 -0.046 -0.106 -0.106 -0.106V0.322c0.058 0 0.106 -0.046 0.106 -0.106h0.526c0 0.058 0.046 0.106 0.106 0.106v0.08h0.076c0.014 0 0.026 0.002 0.038 0.006V0.194c0 -0.05 -0.04 -0.09 -0.09 -0.09H0.13C0.08 0.104 0.04 0.144 0.04 0.194v0.484c0 0.05 0.04 0.09 0.09 0.09h0.48c-0.004 -0.012 -0.008 -0.026 -0.008 -0.04"
    />
    <path
      d="M1 0.898c0 0.02 -0.02 0.038 -0.04 0.038H0.72c-0.02 0 -0.038 -0.018 -0.038 -0.038V0.86c0 -0.02 0.018 -0.038 0.038 -0.038h0.242c0.02 0 0.038 0.018 0.038 0.038z"
    />
    <path
      d="M1 0.73c0 0.02 -0.02 0.038 -0.04 0.038H0.72c-0.02 0 -0.038 -0.018 -0.038 -0.038v-0.038c0 -0.02 0.018 -0.038 0.038 -0.038h0.242c0.02 0 0.038 0.018 0.038 0.038z"
    />
    <g>
      <path
        d="M1 0.56c0 0.02 -0.02 0.038 -0.04 0.038H0.72c-0.02 0 -0.038 -0.018 -0.038 -0.038v-0.038c0 -0.02 0.018 -0.038 0.038 -0.038h0.242c0.02 0 0.038 0.018 0.038 0.038z"
      />
    </g>
  </svg>
</template>
