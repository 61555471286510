<template>
  <svg
    fill="#989796"
    width="60px"
    height="60px"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20,15 L20,5.5 C20,4.67157288 19.3284271,4 18.5,4 L5.5,4 C4.67157288,4 4,4.67157288 4,5.5 L4,15 L9.5,15 C9.77614237,15 10,15.2238576 10,15.5 C10,16.3284271 10.6715729,17 11.5,17 L12.5,17 C13.3284271,17 14,16.3284271 14,15.5 C14,15.2238576 14.2238576,15 14.5,15 L20,15 Z M20,16 L14.9499909,16 C14.7183558,17.1411202 13.709479,18 12.5,18 L11.5,18 C10.290521,18 9.28164422,17.1411202 9.05000906,16 L4,16 L4,18.5 C4,19.3284271 4.67157288,20 5.5,20 L18.5,20 C19.3284271,20 20,19.3284271 20,18.5 L20,16 Z M5.5,3 L18.5,3 C19.8807119,3 21,4.11928813 21,5.5 L21,18.5 C21,19.8807119 19.8807119,21 18.5,21 L5.5,21 C4.11928813,21 3,19.8807119 3,18.5 L3,5.5 C3,4.11928813 4.11928813,3 5.5,3 Z"
    />
  </svg>
</template>
