<template>
  <app-table
    v-if="!isError"
    :data="transactions?.data.items || []"
    :columns="columns"
    :loading="isLoading"
    header-background
    @mobile-row-click="handleMobileRowClicked"
  >
    <template #column-account="props">
      <div>
        <div class="flex gap-x-1">
          <asset-type :asset="props.row.currency" />
        </div>
      </div>
    </template>

    <template #column-status="props">
      <transaction-status :status="props.row.state" />
    </template>

    <template #column-amount="props">
      <div v-if="props.row.type === 'DEPOSIT'" class="font-bold">
        {{
          `${props.row.currency} ${formatAmountToMajor(
            props.row.destination_amount,
            props.row.currency,
          )} `
        }}
      </div>
      <div v-else class="font-bold">
        {{
          `${props.row.currency} ${formatAmountToMajor(
            props.row.sending_amount,
            props.row.currency,
          )} `
        }}
      </div>
    </template>

    <template #column-type="props">
      <div class="flex gap-x-1 items-center">
        <credit-icon v-if="props.row.type === 'DEPOSIT'" />
        <debit-icon v-else-if="props.row.type === 'TRANSFER'" />
        <exchange-icon v-else-if="props.row.type === 'EXCHANGE'" />
        <span
          class="capitalize leading-[0px]"
          :class="
            props.row.type === 'DEPOSIT'
              ? 'text-[#297FB0]'
              : props.row.type === 'TRANSFER'
                ? 'text-[#B96B6B]'
                : 'text-processing/70'
          "
          >{{ props.row.type?.toLowerCase() }}</span
        >
      </div>
    </template>

    <template #column-action="props">
      <button
        class="underline underline-offset-2"
        @click="openTransactionDetails(props.row.id)"
      >
        View
      </button>
    </template>

    <!-- mobile columns -->

    <template #column-mobile-account="props">
      <div class="flex justify-between items-start gap-x-3">
        <div class="">
          <credit-icon v-if="props.row.type === 'DEPOSIT'" />
          <debit-icon v-else-if="props.row.type === 'TRANSFER'" />
          <exchange-icon v-else-if="props.row.type === 'EXCHANGE'" />
        </div>
        <div v-if="props.row.type === 'DEPOSIT'">
          <div class="flex gap-x-1">
            <asset-type :asset="props.row.currency" />
          </div>
        </div>
        <div v-else>
          <div class="flex gap-x-1">
            <asset-type :asset="props.row.currency" />
          </div>
        </div>
      </div>
    </template>

    <template #column-mobile-amount="props">
      <div v-if="props.row.type === 'DEPOSIT'">
        <div class="font-bold">
          {{
            `${props.row.currency} ${formatAmountToMajor(
              props.row.destination_amount,
              props.row.currency,
            )}`
          }}
        </div>
        <div class="mt-1 text-xs text-right">
          {{ formatDate(props.row.created_date) }}
        </div>
      </div>
      <div v-else>
        <div class="font-bold">
          {{
            `${props.row.currency} ${formatAmountToMajor(
              props.row.sending_amount,
              props.row.currency,
            )}`
          }}
        </div>
        <div class="mt-1 text-xs text-right">
          {{ formatDate(props.row.created_date) }}
        </div>
      </div>
    </template>

    <template #empty-state>
      <div
        class="w-full max-w-[300px] mx-auto flex flex-col gap-y-3 justify-center items-center"
      >
        <empty-data />
        <h4 class="font-bold text-base lg:text-lg">No transactions</h4>
        <p class="text-base text-center text-text-primary">
          Make your first payment to a beneficiary
        </p>
      </div>
    </template>
  </app-table>
  <error-component v-else message="Error fetching transactions" />

  <transaction-details
    v-if="showTransactionDetails && activeTransactionId"
    :id="activeTransactionId"
    :type="ServiceType.BANKING"
    :ext-close-modal="closeTransactionDetails"
  />
</template>

<script setup lang="ts">
import { TableColumn } from "@/components/shared/table/table.props";
import { formatAmountToMajor, formatDate } from "@/helpers";
import { useGetTransactions } from "@/data-access/transactions";
import { ServiceType, BankingTransactionResponse } from "@/types";
import { ref } from "vue";

const currentPage = ref(1);
const perPage = ref(5);

const showTransactionDetails = ref(false);
const activeTransactionId = ref<string | null>(null);

const openTransactionDetails = (transactionId: string) => {
  activeTransactionId.value = transactionId;
  showTransactionDetails.value = true;
};

const closeTransactionDetails = () => {
  showTransactionDetails.value = false;
  activeTransactionId.value = null;
};

const {
  isLoading,
  data: transactions,
  isError,
} = useGetTransactions(
  {
    page: currentPage,
    limit: perPage,
  },
  ServiceType.BANKING,
);

const columns: TableColumn<
  BankingTransactionResponse & {
    action: string;
  }
>[] = [
  {
    label: "Date",
    selector: (row) => formatDate(row.created_date),
    dataIndex: "date",
    showOnMobile: false,
  },
  {
    label: "Amount",
    selector: () => {},
    dataIndex: "amount",
    showOnMobile: false,
  },
  {
    label: "Account",
    selector: () => {},
    dataIndex: "account",
    showOnMobile: false,
  },
  {
    label: "Type",
    selector: (row) => row.type,
    dataIndex: "type",
    showOnMobile: false,
  },
  {
    label: "Status",
    selector: (row) => row.state,
    dataIndex: "status",
    showOnMobile: false,
  },
  {
    label: "",
    selector: () => {},
    dataIndex: "action",
    showOnMobile: false,
  },
  {
    label: "",
    selector: () => {},
    dataIndex: "mobile-account",
    showOnMobile: true,
  },
  {
    label: "",
    selector: () => {},
    dataIndex: "mobile-amount",
    showOnMobile: true,
  },
];

const handleMobileRowClicked = (row: BankingTransactionResponse) => {
  openTransactionDetails(row.id);
};
</script>
