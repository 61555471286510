import { RequestOptions } from "@/types";
import axios, { AxiosError, AxiosResponse } from "axios";
import * as Sentry from "@sentry/vue";

let token: string | null;

const devmode = import.meta.env.DEV;

const apiClient = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  withCredentials: !devmode,
});

export const withoutAuthClient = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
});

const publicPaths = [
  "/login",
  "/create-account",
  "/forgot-password",
  "/email-verification",
  "/reset-password",
  "/invitation",
];

if (devmode) {
  apiClient.interceptors.response.use((response) => {
    if (response.data?.data?.token) {
      localStorage.setItem("token", response.data?.data?.token);
    }
    return response;
  });

  apiClient.interceptors.request.use((config) => {
    token = localStorage.getItem("token");
    if (token) {
      config.headers.set("Authorization", `Bearer ${token}`);
    }
    return config;
  });
}

const responseHandler = (response: AxiosResponse) => {
  if (response.status === 401) {
    localStorage.removeItem("token");
    if (
      !publicPaths.filter((path) => window.location.pathname.includes(path))
        .length
    ) {
      setTimeout(() => (window.location.href = "/login"), 10);
    }
  }
  return response;
};

const errorHandler = (error: AxiosError) => {
  if (error.response?.status === 401) {
    localStorage.removeItem("token");
    if (
      !publicPaths.filter((path) => window.location.pathname.includes(path))
        .length
    ) {
      setTimeout(() => (window.location.href = "/login"), 10);
    }
    if (!devmode) {
      Sentry.captureException(error);
    }
    return Promise.reject(error);
  } else {
    return Promise.reject(error);
  }
};

apiClient.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error),
);

export const cryptoUrl = (url: string) => `crypto/v1/` + url;
export const bankingUrl = (url: string) => `banking/v1/` + url;

export const useRequestOptions = (options: RequestOptions) => {
  const params = new URLSearchParams();
  params.append("page", options.page.value.toString());
  params.append("limit", options.limit.value.toString());
  if (options.filters) {
    const filters = options.filters;
    Object.keys(filters).forEach((key) => {
      if (filters[key].value && Array.isArray(filters[key].value)) {
        filters[key].value.forEach((val: string) => {
          params.append(`${key}[]`, val);
        });
      } else if (filters[key].value) {
        params.append(key, filters[key].value);
      }
    });
  }

  return params.toString();
};

export default apiClient;
