<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.4172 5.79736H6.93875C6.22316 5.79736 5.64307 6.37746 5.64307 7.09305V13.5715C5.64307 14.287 6.22316 14.8671 6.93875 14.8671H13.4172C14.1327 14.8671 14.7128 14.287 14.7128 13.5715V7.09305C14.7128 6.37746 14.1327 5.79736 13.4172 5.79736Z"
      stroke="#2F2F2F"
      stroke-width="1.29568"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.05154 10.9802C2.33892 10.9802 1.75586 10.3971 1.75586 9.6845V3.20608C1.75586 2.49346 2.33892 1.9104 3.05154 1.9104H9.52996C10.2426 1.9104 10.8256 2.49346 10.8256 3.20608"
      stroke="#2F2F2F"
      stroke-width="1.29568"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
