<template>
  <div class="w-full">
    <header
      class="flex flex-wrap md:flex-nowrap gap-x-8 gap-y-2 justify-between items-center mb-8"
    >
      <h2 class="flex gap-x-2 items-center min-w-fit">
        <span class="text-primary font-bold">Total Users</span>
        <span
          v-if="users?.total_items"
          class="text-primary w-[26px] h-[26px] rounded-full flex justify-center items-center bg-greyscale-2 text-xs font-medium"
          >{{ users?.total_items }}</span
        >
      </h2>

      <div
        class="flex flex-wrap md:flex-nowrap w-full max-w-[700px] justify-start md:justify-end items-center gap-x-5 gap-y-3"
      >
        <input
          v-model="searchQuery"
          class="w-full md:max-w-[350px] xl:max-w-[500px] h-10 border border-greyscale-7 text-sm text-primary rounded-[5px] focus:outline-primary placeholder:text-sm px-4 py-1 placeholder:text-primary-primary bg-gray-50"
          placeholder="Search users by email"
          type="text"
        />
        <app-button
          v-if="hasPermission(LyncPermissions.users_write)"
          variant="primary"
          size="md"
          @click="toggleCreateInvite"
        >
          <span class="w-[13px] h-[14px] inline-block"><plus-icon /></span>
          <span>Invite User</span>
        </app-button>
      </div>
    </header>

    <app-table
      v-if="!isError"
      :columns="columns"
      :data="users?.items || []"
      :loading="isLoading"
      :pagination="{
        currentPage,
        perPage,
        totalItems: users?.total_items || 0,
      }"
      @change="handleTableChange"
    >
      <template #column-name="props">
        <span v-if="props.row.user">
          {{ props.row.user.first_name }} {{ props.row.user.last_name }}
        </span>
        <span v-else> N/A </span>
      </template>

      <template #column-email="props">
        <div class="normal-case">
          {{ props.row.email }}
        </div>
      </template>

      <template #column-role="props">
        <div v-if="props.row.is_owner">Owner</div>
        <div v-else class="flex flex-col gap-y-1">
          <span v-for="role in props.row.roles" :key="String(role)">
            {{ role.name }}
          </span>
        </div>
      </template>

      <template #column-state="props">
        <user-status :status="props.row.state" />
      </template>

      <template #column-action="props">
        <div class="flex gap-x-2 items-center">
          <button
            class="underline underline-offset-2"
            @click="openEditModal(props.row)"
          >
            View
          </button>
        </div>
      </template>
      <template #empty-state>
        <div
          class="w-full max-w-[300px] mx-auto flex flex-col gap-y-3 justify-center items-center"
        >
          <empty-data />

          <p class="text-base text-center text-text-primary">
            No users have been invited to this account yet. Invite users to
            collaborate on your account.
          </p>
        </div>
      </template>
    </app-table>
    <error-component v-else-if="isError" message="Error fetching users" />
  </div>
  <app-modal
    v-if="selectedUser"
    :is-open="isUserDetailsModalOpen"
    :handle-close="closeUserDetailsModal"
    size="xl"
    align-right
  >
    <user-details
      :close-modal="closeUserDetailsModal"
      :refetch="refetch"
      :user="selectedUser"
    />
  </app-modal>
</template>

<script lang="ts" setup>
import {
  TableChangeParams,
  TableColumn,
} from "@/components/shared/table/table.props";
import { BusinessUsersResponse } from "@/types";
import { useListUsers } from "@/data-access/users";
import { debounce } from "lodash";
import { LyncPermissions } from "@/security/permissions";
import { usePermission } from "@/composables/use-permission";

import { ref, watch } from "vue";

defineProps<{
  toggleCreateInvite: () => void;
}>();

const searchQuery = ref("");
const currentPage = ref(1);
const perPage = ref(10);
const { hasPermission } = usePermission();

const {
  isLoading,
  data: users,
  isError,
  refetch,
} = useListUsers({
  page: currentPage,
  limit: perPage,
  filters: {
    email: searchQuery,
  },
});

const isUserDetailsModalOpen = ref(false);
const selectedUser = ref<BusinessUsersResponse | null>(null);

const openEditModal = (user: BusinessUsersResponse) => {
  selectedUser.value = user;
  isUserDetailsModalOpen.value = true;
};

const closeUserDetailsModal = () => {
  isUserDetailsModalOpen.value = false;
  selectedUser.value = null;
};

watch(
  searchQuery,
  debounce((val) => {
    searchQuery.value = val;
  }, 1000),
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const columns: TableColumn<
  BusinessUsersResponse & {
    action: string;
  }
>[] = [
  {
    label: "Name",
    selector: () => {},
    dataIndex: "name",
    showOnMobile: false,
  },
  {
    label: "Email",
    selector: () => {},
    dataIndex: "email",
    showOnMobile: false,
  },
  {
    label: "Role",
    selector: () => {},
    dataIndex: "role",
    showOnMobile: false,
  },
  {
    label: "Status",
    selector: () => {},
    dataIndex: "state",
    showOnMobile: false,
  },
  {
    label: "Action",
    selector: () => {},
    dataIndex: "action",
    showOnMobile: false,
  },

  {
    label: "Name",
    selector: () => {},
    dataIndex: "name",
    showOnMobile: true,
  },
  {
    label: "Email",
    selector: () => {},
    dataIndex: "email",
    showOnMobile: true,
  },

  {
    label: "Action",
    selector: () => {},
    dataIndex: "action",
    showOnMobile: true,
  },
];

const handleTableChange = (params: TableChangeParams) => {
  (perPage.value = params.perPage), (currentPage.value = params.currentPage);
};
</script>
