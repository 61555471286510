<template>
  <div class="relative w-full h-full bg-white overflow-y-auto">
    <page-loader v-if="isLoading" />

    <div v-else-if="data" class="w-full px-5 py-8">
      <button
        class="border w-10 h-10 rounded-full flex items-center justify-center absolute right-5 top-5"
        @click="closeModal"
      >
        <close-icon />
      </button>

      <h2 class="text-lg font-bold capitalize mb-5">
        {{ data.currency }} Account Request
      </h2>

      <single-account-request :request="data" :close-modal="closeModal" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useGetAccountRequest } from "@/data-access/accounts";
const props = defineProps<{
  closeModal: () => void;
  requestId: string;
}>();

const { data, isLoading } = useGetAccountRequest(props.requestId);
</script>
