import { createApp } from "vue";
import "./style.css";
import App from "./App.vue";
import router from "./router";
import * as Sentry from "@sentry/vue";
import ToastPlugin from "vue-toast-notification";
import VueApexCharts from "vue3-apexcharts";
import "@glidejs/glide/dist/css/glide.core.min.css";
import "vue-toast-notification/dist/theme-sugar.css";
import { VueQueryPlugin, VueQueryPluginOptions } from "@tanstack/vue-query";

const app = createApp(App);

const vueQueryPluginOptions: VueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        refetchOnMount: true,
        retry: false,
        staleTime: 10000,
      },
    },
  },
};

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    "app.lync.global",
    "lync.waza.app",
    "lync.sandbox.waza.co",
    /^\//,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: import.meta.env.VITE_APP_ENVIRONMENT,
});

app.use(VueQueryPlugin, vueQueryPluginOptions);
app.use(VueApexCharts);
app.use(ToastPlugin);
app.use(router);
app.mount("#app");
