<template>
  <svg
    fill="#2f2f2f"
    xmlns="http://www.w3.org/2000/svg"
    width="22px"
    height="22px"
    viewBox="0 0 1.43 1.43"
    enable-background="new 0 0 52 52"
    xml:space="preserve"
  >
    <g>
      <path
        d="M1.24 0.3H0.19c-0.066 0 -0.121 0.055 -0.121 0.121v0.586c0 0.066 0.055 0.121 0.121 0.121h1.05c0.066 0 0.121 -0.055 0.121 -0.121V0.423c0 -0.069 -0.055 -0.124 -0.121 -0.124M0.33 1.006c0 -0.08 -0.063 -0.14 -0.14 -0.14v-0.302c0.08 0 0.14 -0.063 0.14 -0.14h0.77c0 0.08 0.063 0.14 0.14 0.14v0.302c-0.08 0 -0.14 0.063 -0.14 0.14z"
      />
      <path
        cx="26"
        cy="25.6"
        r="7.3"
        d="M0.916 0.704A0.201 0.201 0 0 1 0.715 0.905A0.201 0.201 0 0 1 0.514 0.704A0.201 0.201 0 0 1 0.916 0.704z"
      />
    </g>
  </svg>
</template>
