<template>
  <Combobox v-model="selectedValue">
    <div class="relative">
      <span
        class="text-sm text-primary font-medium w-fit min-w-fit inline-block mb-[9px]"
        >{{ label }}</span
      >
      <div class="relative w-full overflow-hidden text-sm text-primary">
        <ComboboxInput
          class="w-full border border-[#E2E2E2] py-2 pl-4 pr-10 text-sm text-primary-primary rounded-[5px] focus:ring-0 focus:outline-primary placeholder:text-sm"
          :display-value="(it: any) => getDisplayValue(it)"
          :placeholder="`Enter ${label}  name`"
          @change="query = $event.target.value"
        />
        <ComboboxButton
          class="absolute inset-y-0 right-0 flex items-center pr-2"
        >
          <arrow-down-icon />
        </ComboboxButton>
      </div>

      <ComboboxOptions
        class="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-primary/5 focus:outline-none sm:text-sm"
      >
        <div
          v-if="searchList.length === 0 && query !== ''"
          class="relative cursor-default select-none px-4 py-2 text-text-primary"
        >
          Nothing found.
        </div>
        <div
          v-else-if="!searchList.length"
          class="relative cursor-default select-none px-4 py-2 text-text-primary"
        >
          No data
        </div>
        <ComboboxOption
          v-for="item in searchList"
          :key="String(item)"
          v-slot="{ selected, active }"
          as="template"
          :value="item"
        >
          <li
            class="relative cursor-default select-none py-2 pl-3 pr-3 md:pl-10 md:pr-4"
            :class="{
              'bg-greyscale-2 text-primary': active,
              'text-text-primary': !active,
            }"
          >
            <span
              class="block truncate"
              :class="{
                'font-medium': selected,
                'font-normal': !selected,
              }"
            >
              {{ item.label }}
            </span>
            <span
              v-if="selected"
              class="absolute inset-y-0 left-0 flex items-center pl-3"
            >
              <check-icon aria-hidden="true" />
            </span>
          </li>
        </ComboboxOption>
      </ComboboxOptions>
    </div>
    <span
      v-if="errorMessage"
      class="text-failed text-sm flex items-center gap-x-[2px] -mt-[10px]"
      ><caution-icon class="fill-failed min-w-[24px]" />
      {{ errorMessage && errorMessage }}</span
    >
  </Combobox>
</template>

<script setup lang="ts">
import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
} from "@headlessui/vue";
import { ref, watch, computed } from "vue";
import { SelectInputData } from "./type";

const props = defineProps<{
  data: Array<SelectInputData>;
  label: string;
  defaultValue?: string;
  errorMessage?: string;
}>();

const emit = defineEmits<{
  (e: "select", value: SelectInputData): void;
}>();

const defaultValue = computed(() => {
  if (props.defaultValue) {
    return props.data.find((it) => it.value === props.defaultValue);
  }
  return undefined;
});

const selectedValue = ref(defaultValue.value);
const query = ref("");
const searchList = ref(props.data);

watch(selectedValue, (it) => {
  if (it) {
    emit("select", it);
  }
});

const getDisplayValue = (it: SelectInputData) => {
  return `${it.label}`;
};

const filterList = () => {
  searchList.value = props.data.filter((it) =>
    it.label.toLowerCase().includes(query.value.toLowerCase()),
  );
};

watch(query, filterList);
</script>
