import { BeneficiaryResponse, BusinessResponse } from "@/types";
import { useReadResource } from "./use-resource";
import { readonly, ref } from "vue";

const business = ref<BusinessResponse | null>(null);

export const useLoggedInBusiness = () => {
  const { fetch, data, error } =
    useReadResource<BusinessResponse>(`/businesses/me`);

  const refreshBusiness = async () => {
    await fetch();
    if (error.value) {
      business.value = null;
    } else if (data.value) {
      business.value = data.value;
    }
  };

  return {
    business: readonly(business),
    refreshBusiness: refreshBusiness,
  };
};

const defaultAsset = ref<{
  currency: string;
  network: string;
} | null>(null);

export const useDefaultAsset = () => {
  const updateDefaultAsset = (
    Asset: {
      currency: string;
      network: string;
    } | null,
  ) => {
    defaultAsset.value = Asset;
  };

  return {
    defaultAsset: readonly(defaultAsset),
    updateDefaultAsset,
  };
};

const defaultAccount = ref<{
  id: string;
  currency: string;
} | null>(null);

export const useDefaultAccount = () => {
  const updateDefaultAccount = (
    Account: {
      id: string;
      currency: string;
    } | null,
  ) => {
    defaultAccount.value = Account;
  };

  return {
    defaultAccount: readonly(defaultAccount),
    updateDefaultAccount,
  };
};

const defaultBeneficiary = ref<BeneficiaryResponse | null>(null);

export const useDefaultBeneficiary = () => {
  const updateDefaultBeneficiary = (
    beneficiary: BeneficiaryResponse | null,
  ) => {
    defaultBeneficiary.value = beneficiary;
  };

  return {
    defaultBeneficiary: readonly(defaultBeneficiary),
    updateDefaultBeneficiary,
  };
};
