<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="var(--ci-primary-color, currentColor)"
      d="M247.759,14.358,16,125.946V184H496V125.638ZM464,152H48v-5.946L248.241,49.642,464,146.362Z"
      class="ci-primary"
    />
    <path
      fill="var(--ci-primary-color, currentColor)"
      d="M16,496H496V392H16Zm32-72H464v40H48Z"
      class="ci-primary"
    />
    <rect
      width="32"
      height="160"
      x="72"
      y="208"
      fill="var(--ci-primary-color, currentColor)"
      class="ci-primary"
    />
    <rect
      width="32"
      height="160"
      x="408"
      y="208"
      fill="var(--ci-primary-color, currentColor)"
      class="ci-primary"
    />
    <rect
      width="32"
      height="160"
      x="184"
      y="208"
      fill="var(--ci-primary-color, currentColor)"
      class="ci-primary"
    />
    <rect
      width="32"
      height="160"
      x="296"
      y="208"
      fill="var(--ci-primary-color, currentColor)"
      class="ci-primary"
    />
  </svg>
</template>
