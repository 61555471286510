<template>
  <svg
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.12549 1.41992C9.48336 2.19448 10.3755 3.6823 10.3755 5.35742C10.3755 6.24744 10.1116 7.11747 9.6171 7.85749C9.12264 8.59751 8.41983 9.17428 7.59756 9.51488C6.7753 9.85547 5.8705 9.94459 4.99758 9.77095C4.12467 9.59732 3.32285 9.16874 2.69351 8.5394C2.06417 7.91006 1.63559 7.10824 1.46196 6.23533C1.28832 5.36241 1.37744 4.45761 1.71803 3.63535C2.05863 2.81308 2.6354 2.11027 3.37542 1.61581C4.11544 1.12134 4.98547 0.857422 5.87549 0.857422"
      stroke="#2F2F2F"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.12549 3.66992V1.41992H10.3755"
      stroke="#2F2F2F"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
