<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="9"
    viewBox="0 0 11 9"
    fill="none"
  >
    <path
      d="M0.595306 5.0602L3.29755 7.76243L10.0531 1.00684"
      stroke="#0BB34B"
      stroke-width="1.01334"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
