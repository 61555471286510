<template>
  <div class="w-full pb-5">
    <div
      class="flex gap-x-2 items-center w-full text-xs text-[#B47E30] bg-[#FFF8ED] rounded-[5px] py-2 px-3"
    >
      <span>
        <caution-icon class="w-6 h-6 fill-[#B47E30]" />
      </span>
      <span
        >Only deposit an asset from the network specified below. Deposits from
        other networks will be lost.</span
      >
    </div>

    <div class="w-full flex flex-col justify-center items-center gap-y-3 mt-2">
      <div class="w-[170px]">
        <div v-if="loading" class="w-full mt-3">
          <skeleton-loader :count="5" />
        </div>
        <img
          v-else-if="!loading && imgData"
          :src="imgData.src"
          alt="asset address"
        />
        <div
          v-else-if="error"
          class="text-text-primary my-4 text-sm text-center"
        >
          Error fetching deposit address qr code
        </div>
      </div>

      <div class="w-full text-center">
        <h4 class="font-[800] text-primary mb-1">Wallet address</h4>
        <p class="text-sm text-text-secondary break-all">
          {{ asset.deposit_address?.address || "N/A" }}
        </p>
      </div>

      <div class="w-full text-center">
        <h4 class="font-[800] text-primary mb-1">Blockchain</h4>
        <p class="text-sm text-text-secondary capitalize">
          {{ asset.asset.blockchain }}
        </p>
      </div>

      <button
        class="text-sm bg-greyscale-2 px-3 py-2 rounded-[20px] flex items-center gap-x-2 cursor-pointer"
        @click="copyAddress(asset.deposit_address?.address || '')"
      >
        <copy-icon /> Copy Address
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { copyToClipboard } from "@/helpers";
import { AssetsResponse } from "@/types";
import { watch } from "vue";
import { onMounted } from "vue";
import { ref } from "vue";
import { useAppToast } from "@/composables";

const props = defineProps<{
  asset: AssetsResponse;
}>();

const toast = useAppToast();
const loading = ref(false);
const error = ref(false);

const imgData = ref<HTMLImageElement | null>(null);

const copyAddress = (address: string) => {
  copyToClipboard(address);
  toast.success("Copied to clipboard", {
    position: "top-right",
  });
};

const handleImageLoading = () => {
  loading.value = true;
  imgData.value = null;
  const img = new Image();
  img.src = props.asset.deposit_address?.qr_code_link;
  img.onload = () => {
    imgData.value = img;
    loading.value = false;
  };
  img.onerror = () => {
    loading.value = false;
    error.value = true;
  };
};

onMounted(() => {
  handleImageLoading();
});

watch(
  () => props.asset,
  () => {
    handleImageLoading();
  },
);
</script>
