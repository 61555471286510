<template>
  <svg
    width="130"
    height="157"
    viewBox="0 0 130 157"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g style="mix-blend-mode: luminosity">
      <ellipse
        cx="64.8079"
        cy="74.113"
        rx="64.3558"
        ry="65.3735"
        fill="#E8EAE2"
      />
      <g filter="url(#filter0_d_6782_40383)">
        <rect
          x="24.2393"
          y="14.7156"
          width="81.944"
          height="36.2555"
          rx="5.13464"
          fill="white"
        />
      </g>
      <rect
        opacity="0.3"
        x="31.334"
        y="23.3479"
        width="56.0483"
        height="3.10761"
        rx="1.55381"
        fill="#1B3554"
      />
      <rect
        opacity="0.3"
        x="31.334"
        y="29.563"
        width="40.0852"
        height="3.10761"
        rx="1.55381"
        fill="#1B3554"
      />
      <rect
        opacity="0.15"
        x="31.334"
        y="39.2312"
        width="13.1252"
        height="3.10761"
        rx="1.55381"
        fill="#1B3554"
      />
      <ellipse
        opacity="0.3"
        cx="91.6379"
        cy="36.8141"
        rx="7.44945"
        ry="7.25109"
        fill="#1B3554"
      />
      <g filter="url(#filter1_d_6782_40383)">
        <rect
          x="24.2393"
          y="56.1506"
          width="81.944"
          height="36.2555"
          rx="5.13464"
          fill="white"
        />
      </g>
      <rect
        opacity="0.3"
        x="31.334"
        y="64.783"
        width="56.0483"
        height="3.10761"
        rx="1.55381"
        fill="#1B3554"
      />
      <rect
        opacity="0.3"
        x="31.334"
        y="70.998"
        width="40.0852"
        height="3.10761"
        rx="1.55381"
        fill="#1B3554"
      />
      <rect
        opacity="0.15"
        x="31.334"
        y="80.6663"
        width="13.1252"
        height="3.10761"
        rx="1.55381"
        fill="#1B3554"
      />
      <ellipse
        opacity="0.3"
        cx="91.6379"
        cy="78.2491"
        rx="7.44945"
        ry="7.25109"
        fill="#1B3554"
      />
      <g filter="url(#filter2_d_6782_40383)">
        <rect
          x="24.2393"
          y="97.5854"
          width="81.944"
          height="36.2555"
          rx="5.13464"
          fill="white"
        />
      </g>
      <rect
        opacity="0.3"
        x="31.334"
        y="106.218"
        width="56.0483"
        height="3.10761"
        rx="1.55381"
        fill="#1B3554"
      />
      <rect
        opacity="0.3"
        x="31.334"
        y="112.433"
        width="40.0852"
        height="3.10761"
        rx="1.55381"
        fill="#1B3554"
      />
      <rect
        opacity="0.15"
        x="31.334"
        y="122.101"
        width="13.1252"
        height="3.10761"
        rx="1.55381"
        fill="#1B3554"
      />
      <ellipse
        opacity="0.3"
        cx="91.6379"
        cy="119.684"
        rx="7.44945"
        ry="7.25109"
        fill="#1B3554"
      />
      <g filter="url(#filter3_d_6782_40383)">
        <ellipse
          cx="106.185"
          cy="14.7156"
          rx="10.0251"
          ry="9.75812"
          fill="#FFA41B"
        />
      </g>
      <path
        d="M103.973 12.3144C103.973 13.5059 104.966 14.4755 106.186 14.4755C107.406 14.4755 108.399 13.5059 108.399 12.3144C108.399 11.1229 107.406 10.1533 106.186 10.1533C104.966 10.1533 103.973 11.1229 103.973 12.3144ZM110.12 19.278H110.612V18.7977C110.612 16.9445 109.067 15.436 107.169 15.436H105.202C103.304 15.436 101.76 16.9445 101.76 18.7977V19.278H110.12Z"
        fill="white"
      />
      <g filter="url(#filter4_d_6782_40383)">
        <ellipse
          cx="24.2399"
          cy="133.51"
          rx="10.0251"
          ry="9.75812"
          fill="#EA63FF"
        />
      </g>
      <g clip-path="url(#clip0_6782_40383)">
        <path
          d="M18.594 132.408C18.5376 132.422 18.4863 132.451 18.4461 132.492C18.4058 132.534 18.3781 132.585 18.3662 132.641C18.3544 132.696 18.3587 132.754 18.3788 132.807C18.3989 132.861 18.434 132.908 18.48 132.943L21.1155 134.614C21.1647 134.645 21.2218 134.662 21.2804 134.663C21.3389 134.664 21.3965 134.648 21.4466 134.619L24.5127 132.794C24.6605 132.711 24.8169 132.888 24.7123 133.019L22.4224 135.72C22.3851 135.764 22.3613 135.817 22.3538 135.874C22.3462 135.931 22.3552 135.988 22.3797 136.04L23.6947 138.828C23.7234 138.877 23.7658 138.918 23.817 138.945C23.8682 138.972 23.9262 138.984 23.9844 138.981C24.0425 138.977 24.0985 138.958 24.1459 138.925C24.1933 138.892 24.2303 138.847 24.2527 138.794L27.7239 130.636C27.746 130.584 27.7529 130.527 27.7437 130.471C27.7344 130.416 27.7096 130.364 27.6718 130.321C27.634 130.278 27.5848 130.247 27.5297 130.23C27.4746 130.213 27.4158 130.211 27.3598 130.225L18.594 132.408Z"
          fill="white"
        />
      </g>
      <g filter="url(#filter5_d_6782_40383)">
        <ellipse
          cx="24.2399"
          cy="53.3238"
          rx="10.0251"
          ry="9.75812"
          fill="#B47E30"
        />
      </g>
      <path
        d="M22.026 50.9226C22.026 52.1141 23.0189 53.0837 24.239 53.0837C25.4591 53.0837 26.452 52.1141 26.452 50.9226C26.452 49.7311 25.4591 48.7615 24.239 48.7615C23.0189 48.7615 22.026 49.7311 22.026 50.9226ZM28.1732 57.8861H28.665V57.4059C28.665 55.5526 27.1203 54.0442 25.2225 54.0442H23.2554C21.3572 54.0442 19.813 55.5526 19.813 57.4059V57.8861H28.1732Z"
        fill="white"
      />
      <g filter="url(#filter6_d_6782_40383)">
        <ellipse
          cx="106.185"
          cy="94.9019"
          rx="10.0251"
          ry="9.75812"
          fill="#0BB34B"
        />
      </g>
      <path
        d="M104.053 91.0759C104.053 92.3566 105.12 93.3987 106.431 93.3987C107.743 93.3987 108.81 92.3566 108.81 91.0759C108.81 89.7953 107.743 88.7532 106.431 88.7532C105.12 88.7532 104.053 89.7953 104.053 91.0759ZM110.66 98.5604H111.188V98.0442C111.188 96.0523 109.528 94.4311 107.489 94.4311H105.374C103.334 94.4311 101.674 96.0523 101.674 98.0442V98.5604H110.66Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_6782_40383"
        x="15.4913"
        y="10.5318"
        width="99.4397"
        height="53.7512"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4.56413" />
        <feGaussianBlur stdDeviation="4.37396" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.226735 0 0 0 0 0.437054 0 0 0 0 0.687819 0 0 0 0.0769928 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_6782_40383"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_6782_40383"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_6782_40383"
        x="15.4913"
        y="51.9669"
        width="99.4397"
        height="53.7512"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4.56413" />
        <feGaussianBlur stdDeviation="4.37396" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.226735 0 0 0 0 0.437054 0 0 0 0 0.687819 0 0 0 0.0769928 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_6782_40383"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_6782_40383"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d_6782_40383"
        x="15.4913"
        y="93.4017"
        width="99.4397"
        height="53.7512"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4.56413" />
        <feGaussianBlur stdDeviation="4.37396" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.226735 0 0 0 0 0.437054 0 0 0 0 0.687819 0 0 0 0.0769928 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_6782_40383"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_6782_40383"
          result="shape"
        />
      </filter>
      <filter
        id="filter3_d_6782_40383"
        x="87.4122"
        y="0.773735"
        width="37.5461"
        height="37.0122"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4.56413" />
        <feGaussianBlur stdDeviation="4.37396" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 0.386285 0 0 0 0 0.386285 0 0 0 0.328663 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_6782_40383"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_6782_40383"
          result="shape"
        />
      </filter>
      <filter
        id="filter4_d_6782_40383"
        x="5.46693"
        y="119.568"
        width="37.5461"
        height="37.0122"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4.56413" />
        <feGaussianBlur stdDeviation="4.37396" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.917647 0 0 0 0 0.388235 0 0 0 0 1 0 0 0 0.331012 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_6782_40383"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_6782_40383"
          result="shape"
        />
      </filter>
      <filter
        id="filter5_d_6782_40383"
        x="5.46693"
        y="39.3819"
        width="37.5461"
        height="37.0122"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4.56413" />
        <feGaussianBlur stdDeviation="4.37396" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.705882 0 0 0 0 0.494118 0 0 0 0 0.188235 0 0 0 0.330276 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_6782_40383"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_6782_40383"
          result="shape"
        />
      </filter>
      <filter
        id="filter6_d_6782_40383"
        x="87.4122"
        y="80.96"
        width="37.5461"
        height="37.0122"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4.56413" />
        <feGaussianBlur stdDeviation="4.37396" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.458824 0 0 0 0 1 0 0 0 0 0.388235 0 0 0 0.331012 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_6782_40383"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_6782_40383"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_6782_40383">
        <rect
          width="9.85917"
          height="9.82563"
          fill="white"
          transform="matrix(0.763807 -0.645444 0.663198 0.748444 17.2153 133.015)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
