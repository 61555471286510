<template>
  <svg
    width="17px"
    height="17px"
    viewBox="0 0 0.51 0.51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.255 0.494a0.239 0.239 0 1 0 0 -0.478 0.239 0.239 0 0 0 0 0.478Z"
      stroke="#004fcf"
      stroke-width="0.031874999999999994"
      stroke-miterlimit="10"
    />
    <path
      d="M0.192 0.18h0.17"
      stroke="#004fcf"
      stroke-width="0.031874999999999994"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M0.34 0.33H0.17"
      stroke="#004fcf"
      stroke-width="0.031874999999999994"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="m0.318 0.127 0.042 0.053 -0.042 0.053"
      stroke="#004fcf"
      stroke-width="0.031874999999999994"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M0.213 0.382 0.171 0.329 0.213 0.275"
      stroke="#004fcf"
      stroke-width="0.031874999999999994"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
