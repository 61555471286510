export enum AssetType {
  USDC = "USDC",
  USDT = "USDT",
  USD = "USD",
}

export enum TransactionStatus {
  COMPLETED = "COMPLETED",
  PROCESSING = "PROCESSING",
  FAILED = "FAILED",
  SUBMITTED = "SUBMITTED",
  PENDING = "PENDING",
  AWAITING_PROCESSING = "AWAITING_PROCESSING",
}

export type FilterMenuOptions = { label: string; value: string }[];

export interface SelectInputData {
  label: string;
  value: string;
}
