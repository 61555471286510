<template>
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.685 8.74358C11.0271 8.74358 11.31 8.63173 11.5337 8.40805C11.7574 8.18437 11.8692 7.90147 11.8692 7.55936C11.8692 7.21726 11.7574 6.93437 11.5337 6.71068C11.31 6.487 11.0271 6.37515 10.685 6.37515C10.3429 6.37515 10.06 6.487 9.83635 6.71068C9.61267 6.93437 9.50082 7.21726 9.50082 7.55936C9.50082 7.90147 9.61267 8.18437 9.83635 8.40805C10.06 8.63173 10.3429 8.74358 10.685 8.74358ZM2.00082 14.6646C1.56661 14.6646 1.19477 14.5102 0.885296 14.2012C0.576349 13.8917 0.421875 13.5199 0.421875 13.0857V2.03305C0.421875 1.59884 0.576349 1.227 0.885296 0.917523C1.19477 0.608575 1.56661 0.454102 2.00082 0.454102H13.0535C13.4877 0.454102 13.8595 0.608575 14.169 0.917523C14.4779 1.227 14.6324 1.59884 14.6324 2.03305V4.00673H13.0535V2.03305H2.00082V13.0857H13.0535V11.112H14.6324V13.0857C14.6324 13.5199 14.4779 13.8917 14.169 14.2012C13.8595 14.5102 13.4877 14.6646 13.0535 14.6646H2.00082ZM8.31661 11.5067C7.8824 11.5067 7.51082 11.3523 7.20188 11.0433C6.8924 10.7338 6.73767 10.362 6.73767 9.92779V5.19094C6.73767 4.75673 6.8924 4.38489 7.20188 4.07542C7.51082 3.76647 7.8824 3.612 8.31661 3.612H13.8429C14.2771 3.612 14.649 3.76647 14.9585 4.07542C15.2674 4.38489 15.4219 4.75673 15.4219 5.19094V9.92779C15.4219 10.362 15.2674 10.7338 14.9585 11.0433C14.649 11.3523 14.2771 11.5067 13.8429 11.5067H8.31661ZM13.8429 9.92779V5.19094H8.31661V9.92779H13.8429Z"
      fill="#2F2F2F"
      fill-opacity="0.8"
    />
  </svg>
</template>
