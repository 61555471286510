<template>
  <div class="w-full px-5 py-6">
    <form class="w-full flex flex-col gap-y-4" @submit="onSubmit">
      <app-input
        v-for="it in selectedPaymentMethodRequirement"
        :key="it.key"
        v-bind="formFields[it.key]"
        :error-message="errors[it.key]"
        :type="it.allowed_values ? 'select' : 'text'"
        :label="it.description"
        :name="it.key"
        :required="it.required"
        :placeholder="`Enter ${it.description}`"
      >
        <option v-if="it.allowed_values" value="" selected disabled>
          Select {{ it.description }}
        </option>
        <option v-for="val in it.allowed_values || []" :key="val" :value="val">
          {{ val }}
        </option>
      </app-input>

      <div class="flex justify-between gap-x-3">
        <app-button
          type="button"
          size="lg"
          variant="outlined"
          @click="switchForm('beneficiary-address')"
          >Back</app-button
        >
        <app-button
          :loading="submitting"
          :disabled="submitting"
          type="submit"
          size="lg"
          variant="primary"
          >Create</app-button
        >
      </div>
    </form>
  </div>
</template>

<script lang="ts" setup>
import { useForm } from "vee-validate";
import { reactive, watch } from "vue";
import * as yup from "yup";
import { CreateFiatBeneficiary, FiatBeneficiaryView } from "./type";
import { SupportedPaymentMethodsData } from "@/types";
import { computed } from "vue";

const props = defineProps<{
  switchForm: (view: FiatBeneficiaryView) => void;
  handleCreate: (data: CreateFiatBeneficiary) => void;
  submitting: boolean;
  formData: CreateFiatBeneficiary;
  updateFormData: (data: CreateFiatBeneficiary) => void;
  supportedPaymentMethods: SupportedPaymentMethodsData[];
}>();

type FormFields = Record<string, string>;

const selectedPaymentMethodRequirement = computed(() => {
  const method = props.supportedPaymentMethods.find(
    (it) => it.type === props.formData.method.type,
  );
  if (method) {
    return method.requirements;
  }
  return [];
});

const { errors, handleSubmit, defineInputBinds, values } = useForm<FormFields>({
  validationSchema: yup.object(
    selectedPaymentMethodRequirement.value.reduce(
      (obj, it) => {
        return {
          ...obj,
          [it.key]: it.required
            ? yup.string().required().label(it.description)
            : yup.string().label(it.description),
        };
      },
      {} as Record<string, yup.StringSchema>,
    ),
  ),
  initialValues: props.formData.method.details,
});

watch(values, (newValues) => {
  props.updateFormData({
    ...props.formData,
    method: {
      type: props.formData.method.type,
      details: newValues,
    },
  });
});

const onSubmit = handleSubmit((values) => {
  props.handleCreate({
    ...props.formData,
    method: {
      type: props.formData.method.type,
      details: values,
    },
  });
});

const formFieldsInputBinds = computed(() => {
  return selectedPaymentMethodRequirement.value.reduce(
    (obj, it) => {
      return {
        ...obj,
        [it.key]: defineInputBinds(it.key),
      };
    },
    {} as Record<keyof FormFields, ReturnType<typeof defineInputBinds>>,
  );
});

const formFields = reactive(formFieldsInputBinds.value);
</script>
