<template>
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 0.3 0.3"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.299 0.014a0.01 0.01 0 0 1 -0.002 0.003L0.108 0.206l0.053 0.089a0.01 0.01 0 0 0 0.018 -0.001z"
      fill="#ffffff"
    />
    <path
      d="M0.094 0.192 0.005 0.139a0.01 0.01 0 0 1 0.001 -0.018L0.286 0.001a0.01 0.01 0 0 0 -0.003 0.002z"
      fill="#ffffff"
    />
  </svg>
</template>
