<template>
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="10.3496"
      cy="10.7529"
      r="10"
      transform="rotate(-90 10.3496 10.7529)"
      fill="#EAEAEA"
    />
    <path
      d="M12.5971 11.0123L8.59346 14.8269C8.52665 14.8906 8.4379 14.9261 8.34561 14.9261C8.25332 14.9261 8.16457 14.8906 8.09776 14.8269L8.09345 14.8226C8.06096 14.7918 8.03508 14.7546 8.0174 14.7134C7.99972 14.6722 7.9906 14.6279 7.9906 14.583C7.9906 14.5382 7.99972 14.4939 8.0174 14.4527C8.03508 14.4115 8.06096 14.3743 8.09345 14.3435L11.8636 10.7515L8.09345 7.16094C8.06096 7.13007 8.03508 7.09291 8.0174 7.05173C7.99972 7.01054 7.9906 6.96618 7.9906 6.92136C7.9906 6.87654 7.99972 6.83218 8.0174 6.791C8.03508 6.74981 8.06096 6.71265 8.09345 6.68178L8.09776 6.67746C8.16457 6.61378 8.25332 6.57826 8.34561 6.57826C8.4379 6.57826 8.52665 6.61378 8.59346 6.67746L12.5971 10.4921C12.6323 10.5257 12.6603 10.566 12.6795 10.6107C12.6986 10.6554 12.7085 10.7036 12.7085 10.7522C12.7085 10.8008 12.6986 10.849 12.6795 10.8937C12.6603 10.9384 12.6323 10.9787 12.5971 11.0123Z"
      fill="#2F2F2F"
    />
  </svg>
</template>
