<template>
  <label class="w-full">
    <div
      class="w-full flex gap-y-[9px]"
      :class="alignRow ? 'flex-col md:flex-row gap-x-3' : 'flex-col'"
    >
      <div
        class="flex justify-between items-center"
        :class="alignRow ? 'w-fit min-w-max' : 'w-full'"
      >
        <span
          class="text-sm font-medium w-fit min-w-fit"
          :class="greyText ? 'text-primary/70' : 'text-primary'"
          >{{ label }}</span
        >
        <span
          v-if="showRequiredLabel && !alignRow"
          class="text-sm text-[#BFC0BA] w-fit min-w-fit"
          >{{ required ? "Required" : "Optional" }}</span
        >
      </div>
      <select
        v-if="type === 'select'"
        :class="`w-full min-w-max h-10 border ${
          errorMessage ? 'border-[#F41414]' : 'border-[#E2E2E2]'
        } px-4 py-2 rounded-[5px] focus:outline-primary appearance-none bg-select-chevron bg-chevron-position bg-no-repeat bg-auto text-sm`"
        :required="required"
        v-bind="$attrs"
      >
        <slot />
      </select>
      <textarea
        v-else-if="type === 'textarea'"
        :class="`w-full h-[80px] border ${
          errorMessage ? 'border-[#F41414]' : 'border-[#E2E2E2]'
        }  px-4 py-2 rounded-[5px] focus:outline-primary text-sm text-primary placeholder:text-sm`"
        :required="required"
        v-bind="$attrs"
      />
      <div v-else-if="type === 'password'" class="w-full relative h-10">
        <input
          :class="`border ${
            errorMessage ? 'border-[#F41414]' : 'border-[#E2E2E2]'
          } px-4 py-2 rounded-[5px] focus:outline-primary absolute top-0 right-0 w-full h-full text-sm text-primary placeholder:text-sm`"
          :type="showPassword ? 'text' : 'password'"
          :required="required"
          v-bind="$attrs"
        />
        <div
          class="w-[19.23px] absolute right-4 bottom-0 mb-[12px]"
          @click="togglePassword"
        >
          <eyes-icon v-if="!showPassword" />
          <eyes-close-icon v-if="showPassword" />
        </div>
      </div>
      <input
        v-else
        :class="`w-full h-10 border ${
          errorMessage ? 'border-[#F41414]' : 'border-[#E2E2E2]'
        }  px-4 py-2 rounded-[5px] focus:outline-primary text-sm text-primary placeholder:text-sm`"
        :type="type"
        :required="required"
        v-bind="$attrs"
      />
    </div>
    <span class="text-failed text-sm flex items-center gap-x-[2px] mt-1"
      ><caution-icon v-if="errorMessage" class="fill-failed min-w-[24px]" />
      {{ errorMessage && errorMessage }}</span
    >
  </label>
</template>

<script lang="ts">
export default {
  name: "AppInput",
  inheritAttrs: false,
};
</script>
<script lang="ts" setup>
import { ref } from "vue";

type InputType =
  | "text"
  | "email"
  | "password"
  | "select"
  | "date"
  | "number"
  | "textarea";

interface InputProps {
  type: InputType;
  label: string;
  required?: boolean;
  showRequiredLabel?: boolean;
  errorMessage?: string;
  alignRow?: boolean;
  greyText?: boolean;
}

defineProps<InputProps>();

const showPassword = ref(false);

const togglePassword = () => {
  showPassword.value = !showPassword.value;
};
</script>
