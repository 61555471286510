<template>
  <section class="w-full">
    <create-invite
      v-if="showCreateInvite"
      :toggle-create-invite="toggleCreateInvite"
    />
    <business-users v-else :toggle-create-invite="toggleCreateInvite" />
  </section>
</template>

<script lang="ts" setup>
import { ref } from "vue";

const showCreateInvite = ref(false);

const toggleCreateInvite = () => {
  showCreateInvite.value = !showCreateInvite.value;
};
</script>
