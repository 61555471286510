<template>
  <label class="w-full">
    <div class="w-full flex flex-col gap-y-[9px]">
      <span class="text-sm font-medium text-primary w-fit min-w-fit">{{
        label
      }}</span>
      <div class="flex gap-x-1 items-end">
        <div class="min-w-[100px] w-[100px]">
          <select
            v-model="phoneCode"
            class="w-full min-w-max h-10 border border-[#E2E2E2] px-2 py-2 rounded-[5px] focus:outline-primary appearance-none bg-select-chevron bg-chevron-position bg-no-repeat bg-auto text-sm"
            required
          >
            <option
              v-for="country in countriesList"
              :key="country.iso3"
              :value="country.dial_code"
              :selected="country.dial_code === phoneCode"
            >
              {{ country.emoji }} {{ country.dial_code }}
            </option>
          </select>
        </div>
        <input
          v-model="phoneNumber"
          class="w-full h-10 border border-[#E2E2E2] px-4 py-2 rounded-[5px] focus:outline-primary text-sm text-primary placeholder:text-sm"
          type="text"
          placeholder="Enter your phone number"
          required
        />
      </div>
    </div>
    <span class="text-failed text-sm flex items-center gap-x-[2px] mt-1"
      ><caution-icon v-if="error" class="fill-failed min-w-[24px]" />
      {{ error && error }}</span
    >
  </label>
</template>

<script lang="ts" setup>
import { watch, ref } from "vue";
import { countriesList } from "@/helpers/countries";

interface Props {
  label: string;
  errorMessage?: string;
  defaultPhoneCode: string;
}

const props = defineProps<Props>();
const error = ref(props.errorMessage);

const phoneCode = ref(props.defaultPhoneCode);
const phoneNumber = ref("");

const emit = defineEmits<{
  (e: "change", value: string): void;
}>();

watch([phoneCode, phoneNumber], () => {
  if (phoneCode.value && !phoneNumber.value) {
    error.value = "Phone number is required";
  }

  if (phoneNumber.value && !phoneCode.value) {
    error.value = "Phone code is required";
  }

  if (phoneCode.value && phoneNumber.value) {
    error.value = "";
    emit("change", `${phoneCode.value}${parseInt(phoneNumber.value)}`);
  }
});

watch(
  () => props.defaultPhoneCode,
  (newVal) => {
    phoneCode.value = newVal;
  },
);
</script>
