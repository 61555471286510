<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.3485 0.4153C15.2611 0.32829 15.1507 0.268048 15.0302 0.241656C14.9097 0.215265 14.7842 0.223821 14.6684 0.266318L0.418024 5.44828C0.295126 5.49489 0.189318 5.5778 0.114653 5.68597C0.0399882 5.79415 0 5.92248 0 6.05392C0 6.18536 0.0399882 6.31369 0.114653 6.42187C0.189318 6.53004 0.295126 6.61295 0.418024 6.65956L5.98215 8.88133L10.0889 4.76167L11.0022 5.67499L6.87604 9.80113L9.10429 15.3653C9.15229 15.4858 9.23537 15.5891 9.34278 15.6619C9.45018 15.7346 9.57696 15.7735 9.70669 15.7733C9.83759 15.7707 9.9646 15.7284 10.071 15.652C10.1774 15.5757 10.2581 15.469 10.3026 15.3458L15.4846 1.09543C15.5287 0.980831 15.5394 0.856045 15.5153 0.735625C15.4912 0.615205 15.4334 0.504112 15.3485 0.4153Z"
    />
  </svg>
</template>
