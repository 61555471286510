<template>
  <auth-layout :title="formHeader.title" :description="formHeader.description">
    <form
      v-if="!show2fa"
      class="w-full flex flex-col gap-y-4"
      @submit="onSubmit"
    >
      <auth-banner
        v-if="route.query.action"
        :type="route.query.action as any"
      />
      <app-input
        type="email"
        name="email"
        v-bind="formFields.email"
        :error-message="errors.email"
        placeholder="Enter your email address"
        label="Email"
        required
      />
      <div class="w-full">
        <app-input
          type="password"
          name="password"
          :error-message="errors.password"
          v-bind="formFields.password"
          placeholder="Enter your password"
          label="Password"
          required
        />
        <router-link
          to="/forgot-password"
          class="text-tertiary text-sm font-medium inline-block mt-3"
          >Forgot password?</router-link
        >
      </div>
      <app-button
        :loading="submitting || sendingOtp || loggingIn"
        :disabled="submitting || sendingOtp || loggingIn"
        type="submit"
        variant="primary"
        size="lg"
        >Continue</app-button
      >
      <p class="text-sm text-text-primary text-center">
        Create a free account
        <router-link
          class="text-primary font-medium underline underline-offset-2"
          to="/create-account"
          >Sign up</router-link
        >
      </p>
    </form>
    <otp-login
      v-else
      :resending="sendingOtp"
      :handle-otp-login="handleOtpLogin"
      :resend-otp="handleResendOtp"
      :loading="loggingIn"
    />
  </auth-layout>
</template>

<script lang="ts" setup>
import { useForm } from "vee-validate";
import { reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import * as yup from "yup";
import { useWriteResource } from "@/composables/use-resource";
import { useAppToast } from "@/composables";
import { errorMessage } from "@/helpers/error";
import { ref } from "vue";
import { computed } from "vue";

interface LoginFields {
  email: string;
  password: string;
}
const route = useRoute();
const router = useRouter();

const toast = useAppToast();
const show2fa = ref(false);

const loginValues = ref({
  email: "",
  password: "",
});

const formHeader = computed(() =>
  show2fa.value
    ? {
        title: "Enter verification code",
        description: "A verification code has been sent to your email",
      }
    : {
        title: "Welcome!",
        description: "Log in to your Lync account to continue",
      },
);

const { submitting: loggingIn, execute: handleLogin } = useWriteResource(
  "/onboarding/login",
  "post",
  {
    successTitle: "Login Successful",
    onError: (err) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((err as any).response?.data?.error?.code === "016") {
        toast.error("Expired OTP, Please try signing in again", {
          position: "top-right",
        });
        show2fa.value = false;
        return;
      }

      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  },
);

const { submitting, execute: handleCheck2fa } = useWriteResource(
  "/onboarding/check-2fa",
  "post",
  {
    onError: (err) => {
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  },
);

const { submitting: sendingOtp, execute: sendOtp } = useWriteResource(
  "/onboarding/otp-code",
  "post",
  {
    onError: (err) => {
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  },
);

const { errors, handleSubmit, defineInputBinds } = useForm<LoginFields>({
  validationSchema: yup.object({
    email: yup.string().email().required().label("Email"),
    password: yup.string().min(8).required().label("Password"),
  }),
});

const onSubmit = handleSubmit(async (values) => {
  const res = await handleCheck2fa({
    body: values,
  });
  if (res["2faEnabled"]) {
    await sendOtp({
      body: {
        email: values.email,
        token: res.token,
      },
    });
    loginValues.value = {
      email: values.email,
      password: values.password,
    };
    show2fa.value = true;
    return;
  }
  await handleLogin({
    body: {
      email: values.email,
      password: values.password,
    },
  });
  setTimeout(() => {
    router.push("/");
  }, 100);
});

const handleOtpLogin = async (otp: string) => {
  await handleLogin({
    body: {
      email: loginValues.value.email,
      password: loginValues.value.password,
      otp: otp,
    },
  });

  setTimeout(() => {
    router.push("/");
  }, 100);
};

const handleResendOtp = async () => {
  await sendOtp({
    body: {
      email: loginValues.value.email,
    },
  });
};

const formFields = reactive({
  email: defineInputBinds("email"),
  password: defineInputBinds("password"),
});
</script>
