<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 18"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    enable-background="new 0 0 512 512"
    width="18"
    height="18"
  >
    <g>
      <g>
        <path
          d="M9 0.404C5.892 0.404 2.746 1.174 2.746 2.637v2.777c0 1.463 3.146 2.232 6.254 2.232s6.254 -0.766 6.254 -2.232V2.637c0 -1.463 -3.146 -2.232 -6.254 -2.232m0 0.731c3.364 0 5.523 0.879 5.523 1.501s-2.159 1.501 -5.523 1.501S3.477 3.259 3.477 2.637 5.636 1.136 9 1.136m0 5.78c-3.364 0 -5.523 -0.879 -5.523 -1.501v-1.673c1.111 0.742 3.326 1.125 5.523 1.125s4.412 -0.383 5.523 -1.125V5.414c0 0.622 -2.159 1.501 -5.523 1.501"
          stroke="#a5a5a5"
        />
        <path
          d="M9 9.109c-3.364 0 -5.523 -0.879 -5.523 -1.501H2.746v2.781c0 1.463 3.146 2.232 6.254 2.232s6.254 -0.766 6.254 -2.232v-2.781h-0.731c0 0.626 -2.159 1.501 -5.523 1.501m5.523 1.28c0 0.622 -2.159 1.501 -5.523 1.501s-5.523 -0.879 -5.523 -1.501v-1.684c2.194 1.501 8.852 1.501 11.046 0z"
          stroke="#a5a5a5"
        />
        <path
          d="M9 14.084c-3.364 0 -5.523 -0.879 -5.523 -1.501H2.746V15.363c0 1.463 3.146 2.232 6.254 2.232s6.254 -0.766 6.254 -2.232v-2.777h-0.731c0 0.619 -2.159 1.498 -5.523 1.498M14.523 15.363c0 0.622 -2.159 1.501 -5.523 1.501S3.477 15.986 3.477 15.363v-1.684c2.194 1.501 8.852 1.501 11.046 0z"
          stroke="#a5a5a5"
        />
      </g>
    </g>
  </svg>
</template>
