<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_5550_27064)">
      <path
        d="M5.33333 0.692383C4.4496 0.693441 3.60237 1.04497 2.97748 1.66986C2.35259 2.29475 2.00106 3.14199 2 4.02572V16.0257C2.00009 16.147 2.03326 16.2659 2.09592 16.3697C2.15858 16.4735 2.24837 16.5583 2.35563 16.6149C2.46288 16.6714 2.58353 16.6977 2.70459 16.6908C2.82565 16.6839 2.94254 16.6441 3.04267 16.5757L4.44667 15.6164L5.85067 16.5757C5.96159 16.6517 6.09289 16.6923 6.22733 16.6923C6.36177 16.6923 6.49307 16.6517 6.604 16.5757L8.004 15.6164L9.404 16.5757C9.51499 16.6518 9.64642 16.6926 9.781 16.6926C9.91558 16.6926 10.047 16.6518 10.158 16.5757L11.558 15.617L12.958 16.575C13.0581 16.6432 13.1748 16.6829 13.2957 16.6897C13.4166 16.6965 13.5371 16.6703 13.6442 16.6138C13.7514 16.5573 13.8411 16.4728 13.9038 16.3691C13.9664 16.2655 13.9997 16.1468 14 16.0257V4.02572C13.9989 3.14199 13.6474 2.29475 13.0225 1.66986C12.3976 1.04497 11.5504 0.693441 10.6667 0.692383L5.33333 0.692383ZM9.33333 10.0257H5.33333C5.15652 10.0257 4.98695 9.95548 4.86193 9.83045C4.7369 9.70543 4.66667 9.53586 4.66667 9.35905C4.66667 9.18224 4.7369 9.01267 4.86193 8.88765C4.98695 8.76262 5.15652 8.69238 5.33333 8.69238H9.33333C9.51014 8.69238 9.67971 8.76262 9.80474 8.88765C9.92976 9.01267 10 9.18224 10 9.35905C10 9.53586 9.92976 9.70543 9.80474 9.83045C9.67971 9.95548 9.51014 10.0257 9.33333 10.0257ZM11.3333 6.69238C11.3333 6.86919 11.2631 7.03876 11.1381 7.16379C11.013 7.28881 10.8435 7.35905 10.6667 7.35905H5.33333C5.15652 7.35905 4.98695 7.28881 4.86193 7.16379C4.7369 7.03876 4.66667 6.86919 4.66667 6.69238C4.66667 6.51557 4.7369 6.346 4.86193 6.22098C4.98695 6.09595 5.15652 6.02572 5.33333 6.02572H10.6667C10.8435 6.02572 11.013 6.09595 11.1381 6.22098C11.2631 6.346 11.3333 6.51557 11.3333 6.69238Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_5550_27064">
        <rect width="16" height="16" transform="translate(0 0.692383)" />
      </clipPath>
    </defs>
  </svg>
</template>
