<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
  >
    <path
      d="M9.14763 1.16397L8.4039 0.40918L4.8977 3.96748L1.3915 0.40918L0.647766 1.16397L4.15396 4.72227L0.647766 8.28056L1.3915 9.03535L4.8977 5.47706L8.4039 9.03535L9.14763 8.28056L5.64144 4.72227L9.14763 1.16397Z"
      fill="#F41414"
    />
  </svg>
</template>
