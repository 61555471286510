<template>
  <svg
    width="13"
    height="14"
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.47852 6.42871V0.741211H5.97852V6.42871H0.728516V8.05371H5.97852V13.7412H7.47852V8.05371H12.7285V6.42871H7.47852Z"
      fill="#FBFBFB"
    />
  </svg>
</template>
