/* eslint-disable @typescript-eslint/no-explicit-any */
import apiClient from "@/helpers/apiClient";
import { errorMessage } from "@/helpers/error";
import { ref } from "vue";
import { useAppToast } from "@/composables";

const toast = useAppToast();
const devmode = import.meta.env.DEV;

export const useReadResource = <T>(
  resourceUrl: string,
  defaults: { data?: T } = {},
) => {
  const loading = ref(false);
  const data = ref(defaults.data);
  const error = ref<string | null>(null);

  const fetch = async (id?: string) => {
    loading.value = true;
    const url = resourceUrl + (id ? "/" + id : "");
    try {
      const result = await apiClient.get(url);
      data.value = result.data.data;
    } catch (err: any) {
      error.value = errorMessage(err);
      if (devmode) {
        console.error(err);
      }
    } finally {
      loading.value = false;
    }
  };

  const refresh = async () => {
    await fetch();
  };

  return {
    loading,
    fetch,
    data,
    error,
    refresh,
  };
};

export const useWriteResource = <T>(
  resourceUrl: string,
  method: "put" | "patch" | "post" | "delete",
  config: {
    successTitle?: string;
    errorTitle?: string;
    onSuccess?: (value: T) => void;
    onError?: (error: Error) => void;
  } = {},
) => {
  const submitting = ref(false);

  const $$execute = async (
    r: { resource?: string; body?: FormData | any } = {},
  ) => {
    submitting.value = true;
    try {
      let result;
      const url = r.resource ? resourceUrl + "" + r.resource : resourceUrl;
      if (method === "put") {
        result = await apiClient.put(url, r.body);
      } else if (method === "patch") {
        result = await apiClient.patch(url, r.body);
      } else if (method === "post") {
        result = await apiClient.post(url, r.body);
      } else if (method === "delete") {
        result = await apiClient.delete(url);
      } else {
        throw new Error("Method not supported");
      }

      if (config.successTitle) {
        toast.success(config.successTitle, {
          position: "top-right",
        });
      }
      if (config.onSuccess) {
        config.onSuccess(result.data.data);
      }
      return result.data.data;
    } catch (err) {
      if (config.errorTitle) {
        toast.error(config.errorTitle, {
          position: "top-right",
        });
      }
      if (config.onError) {
        config.onError(err as any);
      }
      if (devmode) {
        console.error(err);
      }
      throw err;
    } finally {
      submitting.value = false;
    }
  };

  const execute = async (v?: { resource?: string; body?: FormData | any }) => {
    const r = Object.assign({}, v);
    return await $$execute(r);
  };

  return {
    submitting,
    execute,
  };
};
