<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="24" cy="23.9365" r="23.207" fill="#0BB34B" fill-opacity="0.1" />
    <path
      d="M34.7865 17.9778L21.0474 31.7169C20.9278 31.8369 20.7856 31.9322 20.629 31.9972C20.4725 32.0622 20.3046 32.0957 20.1351 32.0957C19.9656 32.0957 19.7977 32.0622 19.6411 31.9972C19.4846 31.9322 19.3424 31.8369 19.2227 31.7169L13.2119 25.706C13.0921 25.5862 12.997 25.444 12.9322 25.2874C12.8674 25.1309 12.834 24.9631 12.834 24.7937C12.834 24.6242 12.8674 24.4565 12.9322 24.2999C12.997 24.1434 13.0921 24.0011 13.2119 23.8813C13.3317 23.7615 13.4739 23.6665 13.6305 23.6016C13.787 23.5368 13.9548 23.5034 14.1243 23.5034C14.2937 23.5034 14.4615 23.5368 14.618 23.6016C14.7746 23.6665 14.9168 23.7615 15.0366 23.8813L20.1362 28.9809L32.9639 16.1553C33.2059 15.9133 33.5341 15.7773 33.8763 15.7773C34.2185 15.7773 34.5467 15.9133 34.7886 16.1553C35.0306 16.3972 35.1665 16.7254 35.1665 17.0676C35.1665 17.4098 35.0306 17.738 34.7886 17.98L34.7865 17.9778Z"
      fill="#0BB34B"
    />
  </svg>
</template>
