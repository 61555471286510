<template>
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.12251 10.7173V4.40147C6.12251 3.98271 6.28886 3.5811 6.58497 3.28499C6.88108 2.98888 7.28269 2.82252 7.70145 2.82252H14.8067V2.03305C14.8067 1.16463 14.0962 0.454102 13.2278 0.454102H2.17514C1.75638 0.454102 1.35476 0.620454 1.05865 0.916564C0.762544 1.21267 0.596191 1.61429 0.596191 2.03305V13.0857C0.596191 13.5044 0.762544 13.9061 1.05865 14.2022C1.35476 14.4983 1.75638 14.6646 2.17514 14.6646H13.2278C14.0962 14.6646 14.8067 13.9541 14.8067 13.0857V12.2962H7.70145C7.28269 12.2962 6.88108 12.1299 6.58497 11.8337C6.28886 11.5376 6.12251 11.136 6.12251 10.7173ZM8.49093 4.40147C8.05672 4.40147 7.70145 4.75673 7.70145 5.19094V9.92779C7.70145 10.362 8.05672 10.7173 8.49093 10.7173H15.5962V4.40147H8.49093ZM10.8593 8.74358C10.2041 8.74358 9.67514 8.21463 9.67514 7.55936C9.67514 6.9041 10.2041 6.37515 10.8593 6.37515C11.5146 6.37515 12.0436 6.9041 12.0436 7.55936C12.0436 8.21463 11.5146 8.74358 10.8593 8.74358Z"
    />
  </svg>
</template>
