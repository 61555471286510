<template>
  <div v-if="!isLoading && currentRates.length">
    <div class="w-full overflow-hidden">
      <transition name="fade">
        <div
          v-show="show"
          class="w-full flex gap-x-2 justify-between items-center font-medium rounded-sm cursor-pointer"
        >
          <div class="flex gap-x-1 items-center">
            <span> 1 </span>
            <asset-type :asset="activeRate.base_currency" no-title />
          </div>
          ≈
          <div class="flex gap-x-1 items-center">
            <span>
              {{ getRateValue(activeRate.rate) }}
            </span>
            <asset-type :asset="activeRate.quote_currency" no-title />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useGetCurrentFxRates } from "@/data-access/fx-rates";
import { computed, ref, onMounted, onUnmounted } from "vue";

const { data, isLoading } = useGetCurrentFxRates();

const currentRates = computed(() => {
  return data.value || [];
});

const activeIndex = ref(0);
const show = ref(true);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let intervalId: any;

const activeRate = computed(() => {
  return currentRates.value[activeIndex.value];
});

const getRateValue = (rate: number) => {
  if (rate * 100 >= 1) {
    return rate;
  } else {
    return (1 / rate).toFixed(0);
  }
};

const slideRates = () => {
  show.value = false;
  if (activeIndex.value < currentRates.value.length - 1) {
    activeIndex.value = activeIndex.value + 1;
  } else {
    activeIndex.value = 0;
  }
  setTimeout(() => {
    show.value = true;
  }, 2000);
};

onMounted(() => {
  intervalId = setInterval(slideRates, 5000);
});

onUnmounted(() => {
  clearInterval(intervalId);
});
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 2s;
  opacity: 1;
  transform: translateX(0%);
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateX(100%);
}
</style>
