<template>
  <div class="relative w-full">
    <div class="w-full bg-white rounded-[10px]">
      <header
        class="w-full flex justify-between items-center border-b border-greyscale-7 px-5 py-4"
      >
        <h3 class="text-primary font-[800]">Create a bank account</h3>

        <button class="inline w-[16px] h-[18px]" @click="closeModal">
          <close-icon />
        </button>
      </header>

      <form
        class="w-full flex flex-col gap-y-5 px-5 pt-5 pb-8"
        @submit="onSubmit"
      >
        <Listbox v-model="selectedAccount">
          <div class="relative mt-1">
            <ListboxLabel class="text-text-secondary text-sm font-medium"
              >Select currency</ListboxLabel
            >
            <ListboxButton
              class="relative w-full h-10 border overflow-hidden border-[#e2e2e2] rounded-[5px] focus:outline-primary text-sm text-primary placeholder:text-sm mt-2 text-left px-4 py-2"
            >
              <span v-if="selectedAccount">{{ selectedAccount.name }}</span>
              <span v-else>Choose a currency </span>
              <span
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
              >
                <up-down-arrow-icon />
              </span>
            </ListboxButton>

            <ListboxOptions
              class="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 z-10 text-base shadow-lg ring-1 ring-primary/5 focus:outline-none sm:text-sm"
            >
              <div>
                <ListboxOption
                  v-for="currency in supportedCurrencies"
                  :key="currency.code"
                  v-slot="{ active, selected }"
                  :value="currency"
                  as="template"
                >
                  <li
                    class="relative cursor-default select-none py-2 pl-10 pr-4"
                    :class="{
                      'bg-greyscale-2 text-primary': active,
                      'text-text-primary': !active,
                    }"
                  >
                    <span
                      :class="{
                        'font-medium': selected,
                        'font-normal': !selected,
                      }"
                      >{{ currency.name }}</span
                    >
                    <span
                      v-if="selected"
                      class="absolute inset-y-0 left-0 flex items-center pl-3"
                    >
                      <CheckIcon class="h-5 w-5" aria-hidden="true" />
                    </span>
                  </li>
                </ListboxOption>
              </div>
            </ListboxOptions>
          </div>
        </Listbox>

        <div
          v-if="selectedAccount && selectedAccount.code === 'NGN'"
          class="w-full flex flex-col gap-y-5"
        >
          <app-input
            type="date"
            name="businessRegistrationDate"
            :error-message="errors.businessRegistrationDate"
            v-bind="formFields.businessRegistrationDate"
            placeholder="Enter your business registration date"
            label="Business registration date"
            required
          />
        </div>

        <app-button
          :loading="submitting"
          :disabled="!selectedAccount || submitting"
          size="lg"
          variant="primary"
          type="submit"
          >Create Account</app-button
        >
      </form>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, reactive } from "vue";
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
  ListboxLabel,
} from "@headlessui/vue";
import { SupportedBankingCurrency } from "@/types";
import * as yup from "yup";
import { useForm } from "vee-validate";

const props = defineProps<{
  closeModal: () => void;
  supportedCurrencies: SupportedBankingCurrency[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createAccount: (request: Record<string, any>) => void;
  submitting: boolean;
}>();

const selectedAccount = ref<SupportedBankingCurrency>();

interface FormFields {
  businessRegistrationDate: string;
}

const { errors, handleSubmit, defineInputBinds } = useForm<FormFields>({
  validationSchema: yup.object({
    businessRegistrationDate:
      selectedAccount.value?.code === "NGN"
        ? yup.string().required("Business registration date is required")
        : yup.string().optional(),
  }),
});

const onSubmit = handleSubmit(async (values) => {
  if (selectedAccount.value) {
    await props.createAccount({
      currency: selectedAccount.value.code,
      account_owner_info:
        selectedAccount.value.code === "NGN"
          ? {
              business_registration_date: values.businessRegistrationDate,
            }
          : undefined,
    });
  }
});

const formFields = reactive({
  businessRegistrationDate: defineInputBinds("businessRegistrationDate"),
});
</script>
