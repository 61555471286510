import apiClient, {
  bankingUrl,
  cryptoUrl,
  useRequestOptions,
} from "@/helpers/apiClient";
import {
  PaginatedResponse,
  ApprovalRequestResponse,
  QueryKeys,
  RequestOptions,
  ResponseData,
  ServiceType,
  CryptoTransactionResponse,
  BankingTransactionResponse,
  StatementsResponse,
} from "@/types";
import { useQuery, keepPreviousData } from "@tanstack/vue-query";

export const useGetTransactions = (
  options: RequestOptions,
  type: ServiceType,
  enabled: boolean = true,
) => {
  if (type === ServiceType.CRYPTO) {
    return useQuery<ResponseData<CryptoTransactionResponse>>({
      queryKey: [
        QueryKeys.CRYPTO_TRANSACTIONS,
        options.page,
        options.limit,
        ...Object.values(options.filters || {}),
      ],
      queryFn: () =>
        apiClient
          .get(cryptoUrl(`transactions?${useRequestOptions(options)}`))
          .then((res) => res.data),
      placeholderData: keepPreviousData,
      staleTime: 500,
      enabled,
    });
  }
  return useQuery<ResponseData<BankingTransactionResponse>>({
    queryKey: [
      QueryKeys.BANKING_TRANSACTIONS,
      options.page,
      options.limit,
      ...Object.values(options.filters || {}),
    ],
    queryFn: () =>
      apiClient
        .get(bankingUrl(`payments?${useRequestOptions(options)}`))
        .then((res) => res.data),
    placeholderData: keepPreviousData,
    staleTime: 500,
    enabled,
  });
};

export const useGetTransaction = (
  id: string,
  type: ServiceType,
  enabled: boolean = true,
) => {
  if (type === ServiceType.CRYPTO) {
    return useQuery<CryptoTransactionResponse>({
      queryKey: [QueryKeys.SINGLE_CRYPTO_TRANSACTION, id],
      queryFn: () =>
        apiClient
          .get(cryptoUrl(`transactions/${id}`))
          .then((res) => res.data.data),
      enabled,
    });
  }
  return useQuery<BankingTransactionResponse>({
    queryKey: [QueryKeys.SINGLE_BANKING_TRANSACTION, id],
    queryFn: () =>
      apiClient.get(bankingUrl(`payments/${id}`)).then((res) => res.data.data),
    enabled,
  });
};

export const useGetTransactionsStatement = (
  options: RequestOptions,
  type: ServiceType,
  enabled: boolean = true,
) => {
  if (type === ServiceType.CRYPTO) {
    return useQuery<PaginatedResponse<StatementsResponse>>({
      queryKey: [
        QueryKeys.CRYPTO_STATEMENTS,
        options.page,
        options.limit,
        ...Object.values(options.filters || {}),
      ],
      queryFn: () =>
        apiClient
          .get(cryptoUrl(`statements?${useRequestOptions(options)}`))
          .then((res) => res.data.data),
      placeholderData: keepPreviousData,
      staleTime: 500,
      enabled,
    });
  }

  return useQuery<PaginatedResponse<StatementsResponse>>({
    queryKey: [
      QueryKeys.BANKING_STATEMENTS,
      options.page,
      options.limit,
      ...Object.values(options.filters || {}),
    ],
    queryFn: () =>
      apiClient
        .get(bankingUrl(`statements?${useRequestOptions(options)}`))
        .then((res) => res.data.data),
    placeholderData: keepPreviousData,
    staleTime: 500,
    enabled,
  });
};

export const useListApprovalRequests = (
  options: RequestOptions,
  enabled: boolean = true,
) => {
  return useQuery<PaginatedResponse<ApprovalRequestResponse>>({
    queryKey: [
      QueryKeys.APPROVAL_REQUESTS,
      options.page,
      options.limit,
      ...Object.values(options.filters || {}),
    ],
    queryFn: () =>
      apiClient
        .get(`approval-requests?${useRequestOptions(options)}`)
        .then((res) => res.data.data),
    enabled,
  });
};
