<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6349 31.2202L11.0242 26.3344C10.6934 26.2072 10.3814 26.0545 10.0883 25.8764C9.79515 25.6983 9.50912 25.5074 9.23023 25.3038L4.68798 27.2123L0.489258 19.96L4.42078 16.9827C4.39534 16.8046 4.38261 16.6326 4.38261 16.4667V15.4376C4.38261 15.2717 4.39534 15.0997 4.42078 14.9216L0.489258 11.9443L4.68798 4.69195L9.23023 6.60046C9.51014 6.39688 9.80278 6.20603 10.1081 6.0279C10.4135 5.84978 10.7189 5.6971 11.0242 5.56986L11.6349 0.684082H20.0324L20.6431 5.56986C20.9739 5.6971 21.2859 5.84978 21.579 6.0279C21.8722 6.20603 22.1582 6.39688 22.4371 6.60046L26.9793 4.69195L31.1781 11.9443L27.2465 14.9216C27.272 15.0997 27.2847 15.2717 27.2847 15.4376V16.4667C27.2847 16.6326 27.2593 16.8046 27.2084 16.9827L31.1399 19.96L26.9412 27.2123L22.4371 25.3038C22.1572 25.5074 21.8645 25.6983 21.5592 25.8764C21.2538 26.0545 20.9485 26.2072 20.6431 26.3344L20.0324 31.2202H11.6349ZM15.91 21.296C17.3859 21.296 18.6455 20.7743 19.6889 19.731C20.7322 18.6877 21.2538 17.4281 21.2538 15.9521C21.2538 14.4762 20.7322 13.2166 19.6889 12.1733C18.6455 11.13 17.3859 10.6083 15.91 10.6083C14.4086 10.6083 13.1424 11.13 12.1113 12.1733C11.0802 13.2166 10.5652 14.4762 10.5662 15.9521C10.5662 17.4281 11.0812 18.6877 12.1113 19.731C13.1414 20.7743 14.4076 21.296 15.91 21.296Z"
      fill="#2F2F2F"
    />
  </svg>
</template>
