<template>
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 0.3 0.3"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m0.29 0.01 0.009 0.004a0.01 0.01 0 0 0 -0.013 -0.013zm-0.28 0.12 -0.004 -0.009a0.01 0.01 0 0 0 -0.001 0.018zm0.16 0.16 -0.009 0.005a0.01 0.01 0 0 0 0.018 -0.001zM0.286 0.001l-0.28 0.12 0.008 0.018 0.28 -0.12zM0.005 0.139l0.1 0.06 0.01 -0.017 -0.1 -0.06zM0.101 0.195l0.06 0.1 0.017 -0.01 -0.06 -0.1zm0.078 0.099 0.12 -0.28 -0.018 -0.008 -0.12 0.28zM0.283 0.003l-0.18 0.18 0.014 0.014 0.18 -0.18z"
      fill="#2f2f2f"
    />
  </svg>
</template>
